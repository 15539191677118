import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { ManagerReportService } from 'projects/gifcome/src/app/services/managers/manager-report/manager-report.service';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@Component({
  selector: 'app-modal-report',
  standalone: true,
  templateUrl: './modal-report.component.html',
  styleUrl: './modal-report.component.scss',
  imports: [ButtonCloseModalComponent, FormsModule, ReactiveFormsModule]
})
export class ModalReportComponent {

  /******************************
   * Properties
   ******************************/

  @Input() uidTarget?: string;
  @Input() idsGifcome?: string[];

  /*** Forms ***/

  private _formReport: FormGroup
  get formReport(): FormGroup {
    return this._formReport;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private managerReport: ManagerReportService
  ) {
    this._formReport = this.fb.group({
      report: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    const modal = this.elementRef.nativeElement.querySelector('#modalReportCreate');
    if (modal === null) return;

    modal.addEventListener('hidden.bs.modal', () => {
      this.formReport.reset();
    });
  }

  /******************************
   * Methods
   ******************************/

  createReport(): void {
    this.managerReport.createReport(this.uidTarget!, this.formReport.value.report, this.idsGifcome).then(() => {
      this.formReport.reset();
    });
  }

}
