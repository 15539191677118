@if(isLoaded) {
<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <div>
                <h3>ページが見つかりませんでした</h3>
                <p>URLが間違っていたり、変更されていたりする可能性があります。</p>
            </div>

            <div>
                <a class="btn btn-main rounded-pill" role="button" routerLink="/">ホームに戻る</a>
            </div>

        </div>
    </div>
</section>
}