import { Component, Input } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { ElementRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { ManagerRegistrationService } from '../../services/managers/manager-registration/manager-registration.service';
import { Registration } from '../../interfaces/registration';

@Component({
    selector: 'app-modal-registration-update',
    standalone: true,
    templateUrl: './modal-registration-update.component.html',
    styleUrl: './modal-registration-update.component.scss',
    imports: [ButtonCloseModalComponent, FormsModule, ReactiveFormsModule]
})
export class ModalRegistrationUpdateComponent {

    /******************************
     * Properties
     ******************************/

    @Input() registration: Registration | undefined;

    /*** Forms ***/

    private _formRegistration: FormGroup
    get formRegistration(): FormGroup {
        return this._formRegistration;
    }

    /******************************
     * Lifecyle hooks
     ******************************/

    constructor(
        private fb: FormBuilder,
        private elementRef: ElementRef,
        private modelRegistration: ManagerRegistrationService
    ) {
        this._formRegistration = this.fb.group({
            businessType: ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^(individual|company)$/)
            ])],

            nameCompany: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20)
            ])],
            nameCompanyKana: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20),
                Validators.pattern(/^[\u3040-\u309F]+$/)
            ])],
            nameCompanyRome: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20),
                Validators.pattern(/^[a-zA-Z\s]+$/)
            ])],

            name: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20)
            ])],
            nameKana: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20),
                Validators.pattern(/^[\u3040-\u309F]+$/)
            ])],
            nameRome: ['', Validators.compose([
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(20),
                Validators.pattern(/^[a-zA-Z\s]+$/)
            ])],

            tel: ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^\d{10,11}$/)
            ])],

            zip: ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^\d{7}$/)
            ])],
            state: ['', Validators.compose([
                Validators.required
            ])],
            city: ['', Validators.compose([
                Validators.required
            ])],
            street: ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^[0-9-\s]+$/)
            ])],
            building: ['', Validators.compose([
                //Validators.required, // Not required
                Validators.pattern(/^[0-9-\s]+$/)
            ])],
        });
    }

    ngOnInit(): void {
        const modal = this.elementRef.nativeElement.querySelector('#modalRegistrationUpdate');
        if (modal === null) return;

        modal.addEventListener('show.bs.modal', () => {
            if (this.registration === undefined) return;

            this.formRegistration.patchValue(this.registration);
        });
        modal.addEventListener('hidden.bs.modal', () => {
            this.formRegistration.reset();
        });
    }

    /******************************
     * Methods
     ******************************/

    updateRegistration(): void {
        this.modelRegistration.updateRegistration(this.formRegistration.value);
    }
    getClassListValidation(nameControl: string): string[] {
        const control = this.formRegistration.get(nameControl);
        if (control === null || control.value === '') return [];

        return control.invalid ? ['is-invalid'] : ['is-valid'];
    }
}