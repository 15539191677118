import { Injectable } from '@angular/core';
import { collection, doc, Firestore, getDoc, getDocs, setDoc } from '@angular/fire/firestore';
import { ManagerUserService } from '../manager-user/manager-user.service';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { UtilTimestampService } from '../../utils/util-timestamp/util-timestamp.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerBotService {

  constructor(
    private db: Firestore,
    private mgrUser: ManagerUserService,

    private utilNotification: UtilNotificationService,
    private utilTimestamp: UtilTimestampService
  ) { }

  //Whitelist

  async loadWhitelist(): Promise<string[]> {
    const ref = collection(this.db, 'whitelist-bot');
    const snapshot = await getDocs(ref);
    const data = snapshot.docs.map(doc => doc.id);

    return data;
  }

  setBotAvailable(id: string): Promise<void> {
    console.log(id);
    const ref = doc(this.db, 'whitelist-bot', id);
    const response = setDoc(ref, {});

    return response;
  }

  //Bot mode

  async loadBotMode(): Promise<boolean> {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    const snapshot = await getDoc(ref);
    const data = snapshot.data() as any;
    if (data === undefined) return false;

    return data.mode;
  }

  toggleBotMode(mode: boolean): void {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    setDoc(ref, { mode: mode, expiredAt: null }, { merge: true });
  }

  saveSettingsChara(text: string): void {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    setDoc(ref, { settingsChara: text }, { merge: true }).then(() => {
      this.utilNotification.notify('保存しました', 'SUCCESS');
    });
  }

  getSettingsChara(): Promise<string> {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    return getDoc(ref).then((snapshot) => {
      const data = snapshot.data() as any;
      return data.settingsChara;
    });
  }

  async checkBotMode(uid: string): Promise<boolean> {
    const ref = doc(this.db, 'mode-bot', uid);
    const snapshot = await getDoc(ref);
    const data = snapshot.data() as any;
    return data && data.mode ? true : false;
  }

  async setTimmer(n: number): Promise<void> {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    const expiredAt = this.utilTimestamp.generateTimestampAddHours(n);
    return setDoc(ref, { expiredAt: expiredAt }, { merge: true }).then(() => {
      this.utilNotification.notify('タイマーを設定しました', 'SUCCESS');
    });
  }

  async loadTimmer(): Promise<string> {
    const uid = this.mgrUser.user?.uid!;
    const ref = doc(this.db, 'mode-bot', uid);
    const snapshot = await getDoc(ref);
    const data = snapshot.data() as any;
    return data.expiredAt;
  }
}
