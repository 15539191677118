import { AfterViewChecked, AfterViewInit, Component, Host, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ElementRef, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { CONSTANT } from '../../../../../../settings/constant';
import { PlanGifcome } from '../../interfaces/plan-gifcome';
import { Account } from '../../interfaces/account';
import { DecimalPipe } from '@angular/common';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { User } from '@angular/fire/auth';
import { ManagerGifcomeService } from '../../services/managers/manager-gifcome/manager-gifcome.service';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { ModalPointPurchaseComponent } from "../../modals/modal-point-purchase/modal-point-purchase.component";
import { SpinnerComponent } from "../spinner/spinner.component";
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';
import { ManagerRegistrationService } from '../../services/managers/manager-registration/manager-registration.service';
import { Registration } from '../../interfaces/registration';
import { TooltipQuestionComponent } from "../tooltip-question/tooltip-question.component";

@Component({
  selector: 'app-catalog-gifcome',
  standalone: true,
  templateUrl: './catalog-gifcome.component.html',
  styleUrl: './catalog-gifcome.component.scss',
  imports: [FormsModule, ReactiveFormsModule, DecimalPipe, ModalPointPurchaseComponent, SpinnerComponent, TooltipQuestionComponent]
})
export class CatalogGifcomeComponent implements OnInit, OnDestroy {

  /******************************
   * Properties
   ******************************/

  @Input() accountTarget: Account | undefined;
  @Input() uidFor: string | undefined;

  //実際にはreset()で初期化
  idPlanGifcome: number = 0;
  @Input() isGifcomePrivate: boolean = false;
  isGifcomeRankuppable: boolean = true;
  isCommentSavable: boolean = false;
  isCommentSendavleByShiftEnter: boolean = true;

  private _attachment?: any;
  private get attachment(): any {
    return this._attachment;
  }

  private _inputAttach?: any;
  private get inputAttach(): any {
    return this._inputAttach;
  }

  /*** Data ***/

  get user(): User | null {
    return this.managerUser.user;
  }

  get accountMine(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  get planGifcomeCurrent(): PlanGifcome {
    return this.plansGifcome.find((planGifcome: PlanGifcome) => {
      return planGifcome.id === this.idPlanGifcome;
    })!;
  }

  get plansGifcome(): PlanGifcome[] {
    if (this.isModeResponse) {
      return this.managerGifcome.planGifcomes.filter((planGifcome: PlanGifcome) => {
        return (planGifcome.point === 0);
      });
    }
    else {
      return this.managerGifcome.planGifcomes.filter((planGifcome: PlanGifcome) => {
        return (planGifcome.point !== 0);
      });
    }
  }

  get isInvalidAccess(): boolean {
    return this.isModeResponse && this.accountTarget?.role !== 'USER';
  }

  get ratePointGifcomePrivate(): number {
    if (!this.isGifcomePrivate) return 1;

    return CONSTANT.RATE_POINT_GIFCOME_PRIVATE;
  }

  get isMyProfile(): boolean {
    return location.href.indexOf('/p/' + this.accountMine?.id) !== -1;
  }

  /*** Status ***/

  get isAuthenticated(): boolean {
    return this.managerAuth.isAuthenticated;
  }

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  @Input() isModeChat: boolean = false;

  get isModeResponse(): boolean {
    return this.uidFor === this.user?.uid;
  }

  get isGifcomeAttachable(): boolean {
    return this.isGifcomePrivate && this.planGifcomeCurrent.isAttachable
  }

  /*** Events ***/
  private onKeydown: any;

  /*** Forms ***/

  private _formGifcome: FormGroup
  get formGifcome(): FormGroup {
    return this._formGifcome;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private managerUser: ManagerUserService,
    private managerAuth: ManagerAuthService,
    private managerAccount: ManagerAccountService,
    private managerGifcome: ManagerGifcomeService,
    private managerRegistration: ManagerRegistrationService,
    private stateProcessing: StateProcessingService
  ) {
    this._formGifcome = this.fb.group({
      comment: ['', Validators.required]
    });
  }

  reset(): void {
    this.idPlanGifcome = 0;
    if (this.uidFor === this.user?.uid) {
      this.idPlanGifcome = 6;
    }

    this.isGifcomeRankuppable = true;
    this.isCommentSavable = false;

    this._attachment = undefined;
    this._inputAttach = undefined;
  }

  ngOnInit(): void {
    this.reset();

    this.onKeydown = this.renderer.listen('document', 'keydown', (event: KeyboardEvent) => {
      if (!this.isCommentSendavleByShiftEnter) return;

      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();

        const button = this.elementRef.nativeElement.querySelector('#buttonSendGifcome');
        if (button === null) return;
        if (this.stateProcessing.isProcessing) return;

        button.click();
      }
    });

    const modal = this.elementRef.nativeElement.querySelector('#modalGifcomeCreate');
    if (modal === null) return;

    modal.addEventListener('show.bs.modal', () => {
      console.log('show.bs.modal');
      const comment = localStorage.getItem('commentGifcome');
      console.log('comment:', comment);
      if (comment) {
        this.formGifcome.setValue({ comment: comment });
        this.isCommentSavable = true;
      }
    });
    modal.addEventListener('hide.bs.modal', () => {
      this.formGifcome.reset();
      this.reset();
    });
  }

  ngOnDestroy(): void {
    this.onKeydown();
  }

  /******************************
   * Events
   ******************************/

  /******************************
   * Methods
   ******************************/

  async createGifcome(): Promise<void> {
    const pid = this.stateProcessing.start();

    if (this.isCommentSavable) {
      localStorage.setItem('commentGifcome', this.formGifcome.value.comment);
    }
    else {
      localStorage.removeItem('commentGifcome');
    }

    const uidUser = this.accountTarget!.role === 'CREATOR' ? this.user!.uid : this.accountTarget!.id;
    const uidCreator = this.accountTarget!.role === 'CREATOR' ? this.accountTarget!.id : this.user!.uid;
    let idStripeConnectedAdmin: string | undefined = undefined;
    let idStripeConnectedParent: string | undefined = undefined;
    let idStripeConnectedChild: string | undefined = undefined;
    let idStripeConnectedCreator: string | undefined = undefined;
    let isIndividualAgentParent: boolean = false;
    let isIndividualAgentChild: boolean | undefined = undefined;
    let isIndividualCreator: boolean = false;

    const accountCreator = this.accountTarget!.role === 'CREATOR' ? this.accountTarget : this.accountMine;
    const registrationCreator = await this.managerRegistration.getRegistration(accountCreator!.id) as Registration;
    idStripeConnectedCreator = accountCreator!.idStripeConnected;
    isIndividualCreator = (registrationCreator!.businessType === 'individual');
    let accountParent: Account;
    accountParent = await this.managerAccount.getAccount(accountCreator!.uidParent!) as Account;
    let registrationParent: Registration;
    registrationParent = await this.managerRegistration.getRegistration(accountParent.id) as Registration;
    if (accountParent.role === 'AGENT_CHILD') {
      idStripeConnectedChild = accountParent.idStripeConnected;
      isIndividualAgentChild = (registrationParent.businessType === 'individual');

      accountParent = await this.managerAccount.getAccount(accountParent.uidParent!) as Account;
    }
    if (accountParent.role === 'AGENT_PARENT') {
      idStripeConnectedParent = accountParent.idStripeConnected;
      isIndividualAgentParent = (registrationParent.businessType === 'individual');

      accountParent = await this.managerAccount.getAccount(accountParent.uidParent!) as Account;
    }
    idStripeConnectedAdmin = accountParent.idStripeConnected;

    let urlImage: string | undefined = undefined;
    if (this.attachment && this.planGifcomeCurrent!.isAttachable) {
      urlImage = await this.managerGifcome.uploadImage(this.attachment);
    }



    this.managerGifcome.createGifcome(
      this.accountMine!.name!,
      this.accountTarget!.name!,

      this.accountMine!.id,
      this.accountTarget!.id,
      uidCreator,
      uidUser,
      uidCreator,

      this.isGifcomePrivate,
      this.idPlanGifcome,
      this.planGifcomeCurrent!.point * this.ratePointGifcomePrivate,
      this.formGifcome.value.comment,
      urlImage,

      idStripeConnectedAdmin as string,
      idStripeConnectedParent as string,
      idStripeConnectedChild,
      idStripeConnectedCreator as string,

      isIndividualAgentParent,
      isIndividualAgentChild,
      isIndividualCreator
    ).then(() => {
      this.formGifcome.reset();
      if (this.isCommentSavable) {
        const comment = localStorage.getItem('commentGifcome');
        this.formGifcome.setValue({ comment: comment });
      }

      const modal = this.elementRef.nativeElement.querySelector('#modalGifcomeCreate');
      console.log('modal:', modal);
      if (modal === null) return;
      modal.hide();
    }).finally(() => {
      this.stateProcessing.end(pid);
    });
  }

  getLengthLeft(): number {
    if (this.idPlanGifcome < 0 || this.planGifcomeCurrent === undefined) return 0;

    const length = this.planGifcomeCurrent.capacity;
    const size = this.getTextSizeByte(this.formGifcome.value.comment);

    let res = length - size;
    if (res < 0) {
      const b = this.rankup();
      if (!b) return res;

      res = this.getLengthLeft();
    }

    return res;
  }

  getTextSizeByte(text: string | null): number {
    if (text === null) return 0;

    let size = 0;
    for (let i = 0; i < text.length; i++) {
      size += (text[i].match(/[ -~]/)) ? 1 : 2;
    }

    return size;
  }

  rankup(): boolean {
    if (!this.isGifcomeRankuppable) return false;

    const index = this.plansGifcome.findIndex((gifcome) => gifcome.id === this.idPlanGifcome);
    let n = 0;

    if (index + 1 === this.plansGifcome.length) return false;

    this.idPlanGifcome = this.plansGifcome[index + 1].id;
    return true;
  }

  isPointEnough(): boolean {
    if (this.accountMine === undefined) return false;

    return this.accountMine.point >= this.planGifcomeCurrent.point * this.ratePointGifcomePrivate;
  }

  getPointEnoughNot(): number {
    if (this.accountMine === undefined) return 0;

    return this.planGifcomeCurrent!.point * this.ratePointGifcomePrivate - this.accountMine.point;
  }

  selectFile(event: any, inputAttach: any): void {
    if (event.target.files.length === 0) return;

    //50MBチェック
    const file = event.target.files[0]; // 最初に選択されたファイルを取得
    const maxSizeInBytes = 50 * 1024 * 1024; // 50MBをバイトに変換
    if (file) {
      const fileType = file.type;
      // 動画の場合、50MBのサイズ上限をチェック
      if (fileType === 'video/mp4' || fileType === 'video/quicktime') {
        if (file.size > maxSizeInBytes) {
          alert('動画のサイズは50MBまでです');
          inputAttach.value = ''; // ファイル入力をリセット
          return;
        }
      }
    }

    this._attachment = event.target.files[0];
    this._inputAttach = inputAttach;
  }

  signIn(): void {
    if (this.isExtension()) {
      window.open('https://for-extension.gifcome.com/login', '_blank');
    }
    else {
      this.managerAuth.signIn();
    }
  }

  isExtension(): boolean {
    return location.href.indexOf('extension') !== -1;
  }

}
