import { Component } from '@angular/core';
import { ElementRef } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { Account } from "../../interfaces/account";
import { ManagerAccountService } from "../../services/managers/manager-account/manager-account.service";

@Component({
  selector: 'app-modal-account-update',
  standalone: true,
  templateUrl: './modal-account-update.component.html',
  styleUrl: './modal-account-update.component.scss',
  imports: [ButtonCloseModalComponent, FormsModule, ReactiveFormsModule]
})
export class ModalAccountUpdateComponent {

  /******************************
   * Properties
   ******************************/

  readonly icons: any[] = [
    {
      src: '/assets/images/icons/profile/default.png',
      label: 'なし',
    },
    {
      src: '/assets/images/icons/profile/bear.png',
      label: 'くま',
    },
    {
      src: '/assets/images/icons/profile/cat_blue.png',
      label: 'ねこ(青)',
    },
    {
      src: '/assets/images/icons/profile/cat_red.png',
      label: 'ねこ(赤)',
    },
    {
      src: '/assets/images/icons/profile/cow.png',
      label: 'うし',
    },
    {
      src: '/assets/images/icons/profile/deer.png',
      label: 'しか',
    },
    {
      src: '/assets/images/icons/profile/ganesha.png',
      label: 'ガネーシャ',
    },
    {
      src: '/assets/images/icons/profile/jaguar.png',
      label: 'ジャガー',
    },
    {
      src: '/assets/images/icons/profile/koi.png',
      label: '鯉',
    },
    {
      src: '/assets/images/icons/profile/lemur.png',
      label: 'レムール',
    },
    {
      src: '/assets/images/icons/profile/octopus.png',
      label: 'たこ',
    },
    {
      src: '/assets/images/icons/profile/pand-bear.png',
      label: 'パンダ',
    },
    {
      src: '/assets/images/icons/profile/pork.png',
      label: '焼豚',
    },
    {
      src: '/assets/images/icons/profile/turtle.png',
      label: 'かめ',
    },
    {
      src: '/assets/images/icons/profile/weasel.png',
      label: 'いたち',
    }
  ];

  get account(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  /*** Forms ***/

  private _formAccount: FormGroup
  get formAccount(): FormGroup {
    return this._formAccount;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private managerAccount: ManagerAccountService
  ) {
    this._formAccount = this.fb.group({
      name: ['', Validators.required],

      announce: [''],
      description: [''],
      url: [''],
      icon: [''],

      isCreatorAllowedToHideAgent: [],
      isAgentHidden: []
    });
  }

  ngOnInit(): void {
    const modal = this.elementRef.nativeElement.querySelector('#modalAccountUpdate');
    if (modal === null) return;

    modal.addEventListener('show.bs.modal', () => {
      if (this.account === undefined) return;

      this.formAccount.patchValue(this.account);
    });
    modal.addEventListener('hidden.bs.modal', () => {
      this.formAccount.reset();
    });
  }

  /******************************
   * Methods
   ******************************/

  updateAccount(): void {
    this.managerAccount.updateAccount(this.formAccount.value);
  }

}
