import { Component, OnInit } from '@angular/core';
import { Gifcome } from '../../interfaces/gifcome';
import { ManagerChatService } from '../../services/managers/manager-chat/manager-chat.service';
import { RouterLink } from '@angular/router';
import { Account } from '../../interfaces/account';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Chat } from '../../interfaces/chat';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { BadgeNumberComponent } from "../badge-number/badge-number.component";

@Component({
  selector: 'app-widget-gifcha-list-unread',
  standalone: true,
  templateUrl: './widget-gifcha-list-unread.component.html',
  styleUrl: './widget-gifcha-list-unread.component.scss',
  imports: [
    RouterLink,
    BadgeNumberComponent
  ]
})
export class WidgetGifchaListUnreadComponent implements OnInit {

  private _chatsUnread?: Chat[];
  get chatsUnread(): Chat[] | undefined {
    return this._chatsUnread;
  }

  private uids: string[] = [];

  private _accounts: Account[] = [];
  get accounts(): Account[] {
    return this._accounts;
  }

  constructor(
    private mgrUsr: ManagerUserService,
    private mgrAccount: ManagerAccountService,
    private mgrChat: ManagerChatService
  ) { }

  async ngOnInit(): Promise<void> {
    this._chatsUnread = await this.mgrChat.getChatsUnread();
  }

  getUidTarget(chat: any): string {
    return this.mgrChat.getUidTarget(chat);
  }

  getNameTarget(chat: Chat): string {
    const gifcome = chat.gifcomes[0];
    return gifcome.uidTo === this.mgrUsr.user!.uid ? gifcome.nameFrom : gifcome.nameTo;
  }

  getUrlIcon(chat: any): string {
    const account = this.getAccount(this.getUidTarget(chat));
    if (account === undefined) return '/assets/images/icons/profile/default.png';

    return account.icon === undefined || account.icon === '' ? '/assets/images/icons/profile/default.png' : account.icon;
  }

  getLengthUnread(chat: Chat): number {
    return chat.gifcomes.filter(gifcome => !gifcome.isRead).length;
  }

  getAccount(uid: string): Account | undefined {
    const account = this.accounts.find(account => account.id === uid);
    if (account === undefined) {
      if (this.uids.indexOf(uid) === -1) {
        this.uids.push(uid);

        this.mgrAccount.getAccount(uid).then(account => {
          if (account === undefined) return;

          this._accounts.push(account);
        });
      }
    }
    return account;
  }

}
