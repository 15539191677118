<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div>
                    <h5 class="mb-0 d-flex align-items-center gap-25">
                        <!-- <i class="fa-solid fa-user-plus fa-sm"></i> -->
                        フォロー中
                    </h5>
                </div>
            </section>

            @if(accountFollowings.length === 0) {
            <section>
                <p>まだ誰もフォローしていません。</p>
            </section>
            }

            @if(accountFollowings.length > 0) {
            <section>
                <div class="card border-0" style="border-radius: 0;">

                    <div class="list-group list-group-flush">
                        @for(accountFollowing of accountFollowings; track $index) {
                        <a class="list-group-item list-group-item-action" role="button"
                            [routerLink]="['/', 'p', accountFollowing.id]">
                            <div class="d-flex align-items-center">
                                <img [src]="accountFollowing.icon? accountFollowing.icon: '/assets/images/icons/profile/default.png'"
                                    style="width: 2.0rem" class="rounded-circle">

                                <button class="btn btn-link" type="button">{{ accountFollowing.name }}</button>
                            </div>
                        </a>
                        }
                    </div>

                </div>
            </section>
            }

        </div>
    </div>
</section>