<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <div>
                <div class="card card-body border-0 bg-perl-1 py-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <img src="/assets/images/icons/coin.svg"
                                style="width: 2.0rem; margin-top: 4px; margin-right: 0.25rem;">
                            <div>
                                <i class="fs-1">{{ account?.point | number: '.0-0' }}</i>{{
                                getLabelUnitPoint(account?.point || 0) }}
                            </div>
                        </div>

                        <div>
                            <app-modal-point-purchase></app-modal-point-purchase>
                            <button class="btn btn-main rounded-pill" type="button" data-bs-toggle="modal"
                                data-bs-target="#modalPointPurchase">
                                <i class="fa-solid fa-plus"></i>
                                ポイントを追加
                            </button>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <a class="btn btn-link" role="button" routerLink="/charges">
                        追加履歴を表示
                    </a>
                </div>
            </div>

            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>カード設定</h5>
                            <p>
                                カードの設定を確認、追加変更するには下記のリンクをクリックし、Stripeのカスタマーポータルに移動します。
                            </p>
                        </div>

                        <div>
                            <a class="btn btn-link" [href]="getUrlPortalAccountStripeCustomer()" target="_blank">
                                カスタマーポータルを表示
                                <i class="fa-solid fa-arrow-up-right-from-square icon-suffix"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>