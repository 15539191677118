import { Component } from '@angular/core';
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  constructor(
    private mgrAuth: ManagerAuthService
  ) { }

  signIn(): void {
    const url = this.isExtension() ? '/chats' : '/';
    console.log('url', url);
    this.mgrAuth.signIn(url);
  }

  isExtension(): boolean {
    return (window.location.href.indexOf('extension') !== -1);
  }

}
