import { Injectable } from '@angular/core';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';

@Injectable({
  providedIn: 'root'
})
export class UtilClipboardService {

  constructor(
    private utilNotification: UtilNotificationService
  ) { }

  copy(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.utilNotification.notify('コピーしました', 'SUCCESS');
    }).catch((err) => {
      this.utilNotification.notify('コピーに失敗しました', 'ERROR');
      console.error('Could not copy text: ', err);
    });
  }
}
