import { Component, OnInit } from '@angular/core';
import { ManagerIconService } from '../../services/managers/manager-icon/manager-icon.service';
import { ManagerFollowingService } from '../../services/managers/manager-following/manager-following.service';
import { Account } from '../../interfaces/account';
import { RouterLink } from '@angular/router';
import { LinkFollowing } from '../../interfaces/link-following';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';

@Component({
  selector: 'app-following-list',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './following-list.component.html',
  styleUrl: './following-list.component.scss'
})
export class FollowingListComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  private _linkFollowings?: LinkFollowing[];
  get linkFollowings(): LinkFollowing[] | undefined {
    return this._linkFollowings;
  }

  private _accountFollowings: Account[] = [];
  get accountFollowings(): Account[] {
    return this._accountFollowings;
  }

  get icons(): string[] {
    return this.managerIcon.icons;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private managerFollowing: ManagerFollowingService,
    private managerIcon: ManagerIconService,

    private stateProcessing: StateProcessingService
  ) { }

  async ngOnInit(): Promise<void> {
    const pid = this.stateProcessing.start();

    this._linkFollowings = await this.managerFollowing.getLinkFollowings(this.managerUser.user!.uid);
    if (this.linkFollowings === undefined) return;

    for (let linkFollowing of this.linkFollowings) {
      const accountFollowing = await this.managerAccount.getAccount(linkFollowing.uidFollowing);
      if (accountFollowing === undefined) continue;

      this._accountFollowings.push(accountFollowing);
    }

    this.stateProcessing.end(pid);
  }

}
