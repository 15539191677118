import { Injectable } from '@angular/core';
import { DocumentReference, Firestore, doc, docData, query, setDoc, where, collection, collectionData } from '@angular/fire/firestore';
import { Observable, first, firstValueFrom } from 'rxjs';
import { Registration } from '../../../interfaces/registration';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerRegistrationService {

  /******************************
   * Properties
   ******************************/

  private _registration: Registration | undefined;
  get registration(): Registration | undefined {
    if (!this._registration$ && this.managerUser.user !== null) {
      const ref = doc(this.db, 'registration', this.managerUser.user.uid) as DocumentReference<Registration>;
      const obs = docData(ref, { idField: 'id' });
      this._registration$ = obs;
      this._registration$.subscribe((registration: Registration | undefined) => {
        this._registration = registration;
      });
    }

    return this._registration;
  }

  /*** Observable Data ***/
  private _registration$?: Observable<Registration | undefined>;

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private db: Firestore,
    private managerUser: ManagerUserService
  ) { }

  /******************************
   * Methods
   ******************************/

  updateRegistration(registration: Registration): Promise<void> {
    const ref = doc(this.db, 'registration', this.managerUser.user!.uid) as DocumentReference<Registration>;
    const res = setDoc(ref, registration, { merge: true });

    return res;
  }

  getRegistration(uid: string): Promise<Registration | undefined> {
    const ref = doc(this.db, 'registration', uid);
    const data$ = docData(ref, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Registration | undefined>;

    return data;
  }

  getRegistrationIndividuals(): Promise<Registration[]> {
    const ref = collection(this.db, 'registration');
    const q = query(ref, where('businessType', '==', 'individual'));
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Registration[]>;

    return data;
  }


}
