import { Component } from '@angular/core';

@Component({
  selector: 'app-button-close-offcanvas',
  standalone: true,
  imports: [],
  templateUrl: './button-close-offcanvas.component.html',
  styleUrl: './button-close-offcanvas.component.scss'
})
export class ButtonCloseOffcanvasComponent {

}
