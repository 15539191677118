@if(accountMine?.role === 'ADMIN') {
<section class="py-5">
    <div class="container">
        <div class="content">
            <div class="row gy-2">

                <!-- <div class="card card-body">
                    <p>
                        全ユーザーの未使用ポイント残高: 合計{{ points | async | number }}円
                    </p>
                    <small>合計額が3月31日と9月30日の時点で1,000万円を超えている場合、届出と供託金が必要になります。</small>
                </div> -->

                <div class="card card-body">
                    <p>
                        各アカウントの審査状況は<a href="https://dashboard.stripe.com/connect/accounts/overview"
                            target="_blank">こちら</a>
                    </p>

                    @for(agentParent of agentParents; track agentParent.id) {
                    <ul>
                        <li>
                            {{ agentParent.name }} ({{ agentParent.id }})

                            @for(creator of creators; track creator.id) {
                            @if(creator.uidParent === agentParent.id) {
                            <ul>
                                <li><a [routerLink]="'/p/' + creator.id">{{ creator.name }}</a> ({{
                                    creator.id }})
                                </li>
                            </ul>
                            }
                            }

                            @for(agentChild of agentChilds; track agentChild.id) {
                            @if(agentChild.uidParent === agentParent.id) {
                            {{ agentParent.name }} ({{ agentParent.id }})

                            @for(creator of creators; track creator.id) {
                            @if(creator.uidParent === agentParent.id) {
                            <ul>
                                <li><a [routerLink]="'/p/' + creator.id">{{ creator.name }}</a> ({{
                                    creator.id }})
                                </li>
                            </ul>
                            }
                            }
                            }
                            }
                        </li>
                    </ul>
                    }
                </div>

                <!-- <div class="card card-body">
                    <h6>クリエイターに新しいエージェントを設定</h6>
                    <p></p>

                    <div class="mb-2">
                        <label for="exampleFormControlInput1" class="form-label">クリエイターのID</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" #creatorId>
                    </div>

                    <div class="mb-2">
                        <label for="exampleFormControlInput1" class="form-label">新しいエージェントのID</label>
                        <input type="text" class="form-control" id="exampleFormControlInput1" #agentId>
                    </div>

                    <div class="d-flex justify-content-end">
                        <button type="button" class="btn btn-primary"
                            (click)="update(creatorId.value, agentId.value); creatorId.value == ''; agentId.value == ''"
                            [disabled]="!creatorId || !agentId">確定</button>
                    </div>
                </div> -->

            </div>
        </div>
    </div>
</section>
}