import { Component, OnDestroy, OnInit } from '@angular/core';
import { Gifcome } from '../../interfaces/gifcome';
import { ManagerGifcomeService } from '../../services/managers/manager-gifcome/manager-gifcome.service';
import { UtilTimestampService } from '../../services/utils/util-timestamp/util-timestamp.service';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-widget-gifcome-list-latest',
  standalone: true,
  imports: [],
  templateUrl: './widget-gifcome-list-latest.component.html',
  styleUrl: './widget-gifcome-list-latest.component.scss'
})
export class WidgetGifcomeListLatestComponent implements OnInit, OnDestroy {

  private _gifcomesRecievedLatestPrecurrent: Gifcome[] = [];
  private get gifcomesRecievedLatestPrecurrent(): Gifcome[] {
    return this._gifcomesRecievedLatestPrecurrent;
  }

  private _gifcomesRecievedLatestPostcurrent: Gifcome[] = [];
  private get gifcomesRecievedLatestPostcurrent(): Gifcome[] {
    return this._gifcomesRecievedLatestPostcurrent;
  }
  private _gifcomesRecievedLatestPostcurrent$?: Observable<Gifcome[]>;
  private _gifcomesRecievedLatestPostCurrentSubscription?: Subscription;

  get gifcomesRecievedLatest(): Gifcome[] {
    return this.gifcomesRecievedLatestPrecurrent.concat(this.gifcomesRecievedLatestPostcurrent);
  }

  constructor(
    private mgrGifcome: ManagerGifcomeService,

    private utilTimestamp: UtilTimestampService
  ) { }

  async ngOnInit(): Promise<void> {
    const generatedAt = this.utilTimestamp.toTimestamp(new Date().toISOString());

    this._gifcomesRecievedLatestPrecurrent = await this.mgrGifcome.getGifcomesRecievedLatestPrecurrent(generatedAt);
    this._gifcomesRecievedLatestPostcurrent$ = this.mgrGifcome.getGifcomesRecievedLatestPostcurrent(generatedAt);
    this._gifcomesRecievedLatestPostCurrentSubscription = this._gifcomesRecievedLatestPostcurrent$.subscribe((gifcomes) => {
      for (let gifcome of gifcomes) {
        this._gifcomesRecievedLatestPostcurrent.push(gifcome);
      }
    });
  }

  ngOnDestroy(): void {
    this._gifcomesRecievedLatestPostCurrentSubscription?.unsubscribe();
  }

}
