import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';
import { Charge } from '../../../interfaces/charge';
import { ManagerAccountService } from '../../managers/manager-account/manager-account.service';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { StateProcessingService } from '../../states/state-processing/state-processing.service';
import { HttpClient } from '@angular/common/http';
import { Firestore, collection, query, where, CollectionReference, collectionData, orderBy, getDocsFromServer, getDocs } from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { Account } from '../../../interfaces/account';


@Injectable({
  providedIn: 'root'
})
export class ManagerPointService {
  /******************************
     * Properties
     ******************************/

  //tbf
  // private _charges?: Charge[];
  // get charges(): Charge[] | undefined {
  //   if (this._charges === undefined && this._charges$ === undefined && this.managerUser.user !== null) {
  //     const ref = collection(this.db, 'charge') as CollectionReference<Charge>;
  //     const q = query(ref, where('generatedBy', '==', this.managerUser.user.uid), orderBy('generatedAt', 'desc'));
  //     const data$ = collectionData(q, { idField: 'id' }) as Observable<Charge[]>;
  //     const data = firstValueFrom(data$);
  //     data.then((charges: Charge[]) => {
  //       this._charges = charges;
  //     });
  //   }

  //   return this._charges;
  // }
  // private _charges$?: Observable<any[]>;

  private _paymentMethods?: any[];
  get paymentMethods(): any[] | undefined {
    return this._paymentMethods;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private http: HttpClient,

    private db: Firestore,

    private managerAccount: ManagerAccountService,
    private managerUser: ManagerUserService,
    private utilNotification: UtilNotificationService,
    private stateProcessing: StateProcessingService
  ) { }

  purchasePoint(price: number, point: number): Promise<any> {
    const pid = this.stateProcessing.start();

    //ここでもう一回カードが登録されているか確認したほうがいいかも

    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/purchasePoint';
    const params = {
      uid: this.managerUser.user!.uid,

      customer: this.managerAccount.accountMine!.idStripeCustomer,
      amount: price,
      point: point
    };
    const response$ = this.http.post(url, JSON.stringify(params));
    const response = firstValueFrom(response$);

    response.then(() => {
      this.utilNotification.notify('ポイントを追加しました。', 'SUCCESS');
    }).catch((error: any) => {
      const code = error.error.error.code;
      this.utilNotification.notify(`ポイントの追加に失敗しました。(${code})`, 'ERROR');
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return response;
  }

  getPaymentMethods(): Promise<any> {
    const pid = this.stateProcessing.start();

    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/getPaymentMethods';
    const params = {
      customer: this.managerAccount.accountMine!.idStripeCustomer
    };
    const response$ = this.http.post(url, JSON.stringify(params));
    const response = firstValueFrom(response$) as Promise<any>;
    response.finally(() => {
      this.stateProcessing.end(pid);
    });

    return response;
  }

  async getCharges(): Promise<Charge[]> {
    const ref = collection(this.db, 'charge') as CollectionReference<Charge>;
    const q = query(ref,
      where('generatedBy', '==', this.managerUser.user!.uid),
      orderBy('generatedAt', 'desc')
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<Charge[]>;
    const data = firstValueFrom(data$) as Promise<Charge[]>;

    return data;
  }

  getBalancePoint(): Observable<Account[]> {
    const ref = collection(this.db, 'account');
    const data$ = collectionData(ref, { idField: 'id' }) as Observable<Account[]>;
    return data$;
  }

  async getBalancePoint2(): Promise<Account[]> {
    const ref = collection(this.db, 'account');
    console.log(ref);
    //const data = getDocsFromServer(ref);
    const data = getDocs(ref);
    const snapshot = await data;
    const accounts = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Account[];

    return accounts;

    // let balancePoint = 0;
    // for (let account of accounts) {
    //   balancePoint += (account.point ? account.point : 0);
    // }
    // return balancePoint;

  }
}