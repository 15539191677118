import { Injectable } from '@angular/core';
import { collection, doc, Firestore, getDoc, getDocs, query, setDoc, where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ManagerCounterService {

  constructor(
    private db: Firestore
  ) { }

  /**
   * リダイレクト用
   */
  async getUidFromUrlShortened(idUrlShortened: string): Promise<string | null> {
    console.log('idUrlShortened', idUrlShortened);
    const ref = doc(this.db, 'url-shortened', idUrlShortened)
    const snapshot = await getDoc(ref);
    const data = snapshot.data() as any;

    if (!data) return null;

    return data.uid;
  }

  /**
   *  URL取得用
   */
  async getIndexUrlShortened(uid: string): Promise<string> {
    const ref = collection(this.db, 'url-shortened');
    const q = query(ref, where('uid', '==', uid));

    const snapshot = await getDocs(q);
    if (snapshot.size !== 0) {
      const index = parseInt(snapshot.docs[0].id);
      return this.toUrlShortened(index);
    }

    const index = await this.getIndex();
    this.createUrlShortened(index, uid);
    return this.toUrlShortened(index);
  }

  createUrlShortened(index: number, uid: string): void {
    const ref = doc(this.db, 'url-shortened', index.toString());
    setDoc(ref, { uid: uid }, { merge: true });
  }

  async getIndex(): Promise<number> {
    const ref = doc(this.db, 'counter', 'url-shortened');
    const snapshot = await getDoc(ref);
    const data = snapshot.data() as any;
    const indexLatest = data.indexLatest + 1;
    setDoc(ref, { indexLatest: indexLatest }, { merge: true });

    return indexLatest;
  }

  toUrlShortened(index: number): string {
    return 'https://gifcome.com/@' + index;
  }

  toUrlIndex(index: number): string {
    return 'gf:' + index + ':cm';
  }
}
