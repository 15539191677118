import { Component, OnInit } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { ManagerPointService } from '../../services/managers/manager-point/manager-point.service';
import { DatePipe, DecimalPipe } from '@angular/common';
import { UtilTimestampService } from '../../services/utils/util-timestamp/util-timestamp.service';

@Component({
  selector: 'app-charge-list',
  standalone: true,
  imports: [DatePipe, DecimalPipe],
  templateUrl: './charge-list.component.html',
  styleUrl: './charge-list.component.scss'
})
export class ChargeListComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  get user(): User | null {
    return this.managerUser.user;
  }

  private _charges?: any[];
  get charges(): any[] | undefined {
    return this._charges;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private managerUser: ManagerUserService,
    private managerPoint: ManagerPointService,
    private utilTimestamp: UtilTimestampService
  ) { }

  async ngOnInit(): Promise<void> {
    this._charges = await this.managerPoint.getCharges();
  }

  convert(date: string): string {
    return this.utilTimestamp.convert(date);
  }

}
