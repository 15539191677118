import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";

@Component({
  selector: 'app-modal-preview-image',
  standalone: true,
  templateUrl: './modal-preview-image.component.html',
  styleUrl: './modal-preview-image.component.scss',
  imports: [ButtonCloseModalComponent]
})
export class ModalPreviewImageComponent implements OnInit {

  @Input() urlImage?: string;

  constructor(
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    const modal = this.elementRef.nativeElement.querySelector('#modalPreviewImage');
    if (modal === null) return;

    let original = '';
    modal.addEventListener('show.bs.modal', () => {
      const meta = document.querySelector('meta[name="viewport"]');
      if (meta === null) return;

      original = meta.getAttribute('content') as string;
      meta.setAttribute('content', 'width=device-width, initial-scale=1.0');
    });
    modal.addEventListener('hidden.bs.modal', () => {
      const meta = document.querySelector('meta[name="viewport"]');
      if (meta === null) return;

      meta.setAttribute('content', original);
    });
  }

}
