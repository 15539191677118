import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManagerIconService {

  /********************************
   * Properties
   *******************************/

  readonly icons: string[] = [
    'bear.png',
    'cat_blue.png',
    'cat_red.png',
    'cow.png',
    'deer.png',
    'ganesha.png',
    'jaguar.png',
    'koi.png',
    'lemur.png',
    'octopus.png',
    'panda-bear.png',
    'pork.png',
    'turtle.png',
    'weasel.png'
  ]

}
