@if(isInvalidAccess) {
<section>
    @if(isMyProfile) {
    <p>
        これはあなたのプロフィールです。
    </p>
    }
    @else {
    <p>
        不正アクセスは禁止、記録されています。
    </p>
    }
</section>
}
@else if(accountTarget !== undefined) {
<form class="row gy-2" [formGroup]="formGifcome">
    <section class="catalog-body">
        <div class="row gy-1">

            @if(!isModeResponse) {
            <section class="d-flex align-items-center gap-25">
                <div class=" form-check form-switch mb-0">
                    <input class="form-check-input" type="checkbox" role="switch" id="switchGifcomePrivate"
                        [value]="isGifcomePrivate" [checked]="isGifcomePrivate"
                        (change)="isGifcomePrivate = !isGifcomePrivate">
                    <label class="form-check-label" for="switchGifcomePrivate">
                        ギフチャにする
                    </label>
                </div>

                <app-tooltip-question text="ギフコメが他人から見えなくなり、配信者にチャット形式で直接届きます。"></app-tooltip-question>
            </section>
            }

            @if(!isModeResponse || uidFor !== user?.uid) {
            <section>
                <div class="row gy-50">
                    <section>
                        <div class="row g-50">
                            @for(planGifcome of plansGifcome; track $index) {
                            <section class="col-3">
                                <div class="card card-body card-button-1 py-1" role="button"
                                    (click)="idPlanGifcome = planGifcome.id"
                                    [class.card-button-active]="idPlanGifcome === planGifcome.id">
                                    <div class="d-flex align-items-center justify-content-center">
                                        <!-- <img [src]="planGifcome.urlIcon" class="transform-for-ios"> -->
                                        <!-- transform-for-ios: SVGの陰影のバグを取り除く魔法 -->
                                        <img [src]="planGifcome.urlIcon">
                                    </div>

                                    <div class="d-flex align-items-center justify-content-center"
                                        style="padding-top: 0.5rem;">
                                        <!-- <img src="/assets/images/icons/coin.svg" style="width: 1.0rem"> -->
                                        <p class="d-inline" style="margin-left: 0.25rem;">
                                            {{ planGifcome.point * ratePointGifcomePrivate | number: '.0-0' }}
                                        </p>
                                    </div>
                                </div>
                            </section>
                            }
                        </div>
                    </section>

                    <section>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="switchGifcomeRankuppable"
                                [value]="isGifcomeRankuppable" [checked]="isGifcomeRankuppable"
                                (change)="isGifcomeRankuppable = !isGifcomeRankuppable">
                            <label class="form-check-label" for="switchGifcomeRankuppable">
                                文字数に合わせてランクアップ
                            </label>
                        </div>
                    </section>
                </div>
            </section>
            }

            <section>
                <div class="row gy-50">
                    <section>
                        <label for="comment" class="form-label">コメント</label>
                        <textarea class="form-control" id="comment" formControlName="comment"></textarea>
                    </section>

                    <section>
                        <div class="d-flex justify-content-between">
                            <section>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="switchCommentSavable" [value]="isCommentSavable"
                                        [checked]="isCommentSavable" (change)="isCommentSavable = !isCommentSavable">
                                    <label class="form-check-label" for="switchCommentSavable">
                                        贈ったコメントを記憶
                                    </label>
                                </div>
                            </section>

                            <section>
                                <div [class.text-alert]="getLengthLeft() < 0">
                                    @if(getLengthLeft() >= 0) {
                                    <label>
                                        あと{{ getLengthLeft() }}/{{ planGifcomeCurrent.capacity }}文字
                                    </label>
                                    }
                                    @else {
                                    <label>
                                        {{ getLengthLeft() * -1 }}文字オーバー
                                    </label>
                                    }
                                </div>
                            </section>
                        </div>
                    </section>
                </div>
            </section>

            @if(isGifcomeAttachable) {
            <section>
                <div>
                    <label for="attach" class="form-label">添付</label>
                    <input type="file" class="form-control" id="attach" #inputAttach
                        accept="image/jpg,image/jpeg,image/png,video/mp4,video/quicktime"
                        (change)="selectFile($event, inputAttach)">
                </div>
            </section>
            }
        </div>
    </section>

    @if(!isAuthenticated) {
    <section class="catalog-footer">
        <div class="d-flex justify-content-end">
            <button class="btn btn-main rounded-pill" type="button" (click)="signIn()">
                ログインして贈る
            </button>
        </div>
    </section>
    }
    @else if(isPointEnough()) {
    <section>
        <div class="d-flex align-items-center justify-content-between">
            @if(!isProcessing) {
            <section>
                <div>
                    <button class="btn btn-main btn-lg rounded-pill" type="button" (click)="createGifcome()"
                        id="buttonSendGifcome"
                        [disabled]="formGifcome.invalid || getLengthLeft() < 0 || accountTarget.id === user?.uid || isProcessing">
                        <i class="fa-solid fa-gift"></i>
                        おくる
                    </button>
                </div>
            </section>
            }
            @else {
            <section>
                <app-spinner></app-spinner>
            </section>
            }

            <section>
                <div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch"
                            id="switchCommentSendableByShiftEnter" [value]="isCommentSendavleByShiftEnter"
                            [checked]="isCommentSendavleByShiftEnter"
                            (change)="isCommentSendavleByShiftEnter = !isCommentSendavleByShiftEnter">
                        <label class="form-check-label" for="switchCommentSendableByShiftEnter">
                            Shift + Enterで贈る
                        </label>
                    </div>
                </div>
            </section>
        </div>
    </section>
    }
    @else {
    <section>
        <div class="d-flex align-items-center">
            <section>
                <div>
                    <button class="btn btn-main rounded-pill" type="button" data-bs-toggle="modal"
                        data-bs-target="#modalPointPurchase">
                        <i class="fa-solid fa-sack-dollar"></i>
                        ポイントを追加
                    </button>
                </div>
            </section>

            <section>
                <label class="text-alert" style="margin-left: 0.5rem;">
                    もう{{ getPointEnoughNot() | number: '.0-0' }}pts必要です
                </label>
            </section>
        </div>
    </section>
    }
</form>
}
@else {
@if(accountMine?.role !== 'USER') {
<p>
    一般ユーザー以外(収益アカウント)は、ギフチャを送ることができません。
</p>
}
}

<app-modal-point-purchase></app-modal-point-purchase>