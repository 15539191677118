import { Injectable } from '@angular/core';
import { Firestore, collection, CollectionReference, query, where, collectionData, doc, DocumentReference, addDoc, deleteDoc, docData } from '@angular/fire/firestore';
import { firstValueFrom } from 'rxjs';
import { Observable } from 'rxjs';
import { LinkFollowing } from '../../../interfaces/link-following';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { ManagerUserService } from '../manager-user/manager-user.service';

@Injectable({
  providedIn: 'root'
})
export class ManagerFollowingService {


  /******************************
   * Properties
   ******************************/

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private db: Firestore,
    private managerUser: ManagerUserService,
    private utilNotification: UtilNotificationService,
  ) { }

  /******************************
   * Methods
   ******************************/

  getLinkFollowings(uid: string): Promise<LinkFollowing[]> {
    const ref = collection(this.db, 'link-following') as CollectionReference<LinkFollowing>;
    const q = query(ref,
      where('generatedBy', '==', uid)
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<LinkFollowing[]>;
    const data = firstValueFrom(data$) as Promise<LinkFollowing[]>;

    return data;
  }

  getLinkFollowers(uid: string): Promise<LinkFollowing[]> {
    const ref = collection(this.db, 'link-following') as CollectionReference<LinkFollowing>;
    const q = query(ref,
      where('uidFollowing', '==', uid)
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<LinkFollowing[]>;
    const data = firstValueFrom(data$) as Promise<LinkFollowing[]>;

    return data;
  }

  toUidsFollowing(arrayLinkFollowing: LinkFollowing[]): string[] {
    return arrayLinkFollowing.map(
      (linkFollowing: LinkFollowing) => linkFollowing.uidFollowing
    );
  }

  // getAccountsFollowingByUid(arrayUid: string[]): Account[] {

  // }

  follow(uid: string, linkFollowings: LinkFollowing[] | undefined, linkFollowers: LinkFollowing[] | undefined): Promise<DocumentReference<LinkFollowing>> {
    const following = {
      generatedBy: this.managerUser.user!.uid,
      uidFollowing: uid,
    } as LinkFollowing;
    const ref = collection(this.db, 'link-following') as CollectionReference<LinkFollowing>;
    const promise = addDoc(ref, following) as Promise<DocumentReference<LinkFollowing>>;

    promise.then((ref: DocumentReference<LinkFollowing>) => {
      const data$ = docData(ref);
      const data = firstValueFrom(data$) as Promise<LinkFollowing>;
      data.then((linkFollowing: LinkFollowing) => {
        if (linkFollowings === undefined) {
          linkFollowings = [];
        }
        if (linkFollowers === undefined) {
          linkFollowers = [];
        }

        linkFollowing.id = ref.id;
        linkFollowings.push(linkFollowing);
        linkFollowers.push(linkFollowing);
      });

      this.utilNotification.notify('フォローしました', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('フォローに失敗しました', 'ERROR');
    });

    return promise;
  }

  unfollow(uid: string, linkFollowings: LinkFollowing[], linkFollowers: LinkFollowing[]): void {
    const idFollowing = this.findIdFollowing(uid, linkFollowings);
    if (!idFollowing) return;

    const ref = doc(this.db, 'link-following', idFollowing);
    const res = deleteDoc(ref);
    res.then(() => {
      const index = linkFollowings.findIndex((linkFollowing: LinkFollowing) => linkFollowing.id === idFollowing);
      linkFollowings.splice(index, 1);
      linkFollowers.splice(index, 1);

      this.utilNotification.notify('フォローを解除しました', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('フォロー解除に失敗しました', 'ERROR');
    });
  }

  findIdFollowing(uid: string, linkFollowings: LinkFollowing[] | undefined): string | undefined {
    if (linkFollowings === undefined) return undefined;

    const linkFollowing = linkFollowings.find((linkFollowing: LinkFollowing) => linkFollowing.uidFollowing === uid);
    if (linkFollowing === undefined) return undefined;

    return linkFollowing.id;
  }

  checkFollowing(uid: string, linkFollowings: LinkFollowing[] | undefined): boolean {
    return this.findIdFollowing(uid, linkFollowings) !== undefined;
  }

}
