import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-pp',
  standalone: true,
  imports: [],
  templateUrl: './terms-pp.component.html',
  styleUrl: './terms-pp.component.scss'
})
export class TermsPpComponent {

}
