import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent implements OnInit {

  private _isLoaded: boolean = false;
  get isLoaded(): boolean {
    return this._isLoaded;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this._isLoaded = true;
    }, 5 * 1000);
  }

}
