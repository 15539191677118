import { Injectable } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { AccountRole } from '../../../interfaces/account';
import { ManagerCounterService } from '../../managers/manager-counter/manager-counter.service';

@Injectable({
  providedIn: 'root'
})
export class UtilUrlService {

  get user(): User | null {
    return this.managerUser.user;
  }

  constructor(
    private managerUser: ManagerUserService,
    private mgrCounter: ManagerCounterService
  ) { }

  getUrlRoot(): string {
    return window.location.origin;
  }

  getUrlInvitation(role: AccountRole): string {
    return `${this.getUrlRoot()}/onboarding/?src=${this.user?.uid}&tgt=${role}`;
  }

  async getUrlCatalog(uid: string): Promise<string> {
    const url = await this.mgrCounter.getIndexUrlShortened(uid);

    return url;
  }

  getUrlCatalogNormal(uid: string): string {
    return `${this.getUrlRoot()}/catalog/${uid}`;
  }
}
