export const ENVIRONMENT = {
    MODE: 'PRODUCTION',
    PARAMS: {
        FIREBASE: {
            apiKey: "AIzaSyAU9SBj4OQGBL59rcQBWniIs57vLOIIRkc",
            authDomain: "gifcome.com",
            projectId: "mdk-gifcome-production",
            storageBucket: "mdk-gifcome-production.appspot.com",
            messagingSenderId: "946383722853",
            appId: "1:946383722853:web:691ae878ca859aeddf698c",
            measurementId: "G-NKN8FMDNF0"
        },
        FUNCTIONS: {
            URL_BASE: 'https://asia-northeast1-mdk-gifcome-production.cloudfunctions.net',
            PUSH_NOTIF: {
                VAPID_KEY_PUBLIC: 'BOyfYsCvaT9pe56q8UzGWJR9IxSM6iSSayX3wFXEh3oaX7T__xecc4rMmEFojleDIdBk6jC44ZUT8zbBGGcqRyU',
                VAPID_KEY_PRIVATE: 'xf7pB9rQxsgkRbx2b7cGaGr4VTQh0Q6_Bs4ei4fH2As'
            }
        },
        NETWORK: {
            PROTOCOL: 'https',
            HOST_ADDRESS: 'gifcome.com'
        },
        STRIPE: {
            CLIENT_ID: '3cs2cfbB78AxeKQ000',
            CUSTOMER: {
                URL_RETURN: 'http://gifcome.com/wallet'
            },
            CONNECTED: {
                URL_REFRESH: 'https://gifcome.com/revenue',
                URL_RETURN: 'https://gifcome.com/revenue'
            }
        }
    }
};