import { Injectable } from '@angular/core';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
export class UtilTimestampService {

  generateTimestamp(): string {
    return moment().tz('Asia/Tokyo').format();
  }

  generateTimestampAddHours(hours: number): string {
    return moment().add(hours, 'hours').tz('Asia/Tokyo').format();
  }

  toTimestamp(date: string | Timestamp): string {
    date = this.convert(date);

    return moment(date).tz('Asia/Tokyo').format();
  }

  getDate(date: string | Timestamp): string {
    date = this.convert(date);

    return moment(date).tz('Asia/Tokyo').format('YYYY-MM-DD');
  }

  convert(date: string | Timestamp): string {
    if (date instanceof Timestamp) {
      date = date.toDate().toISOString();
    }

    return date;
  }

  //Showing date and time
  getLabelDate(generatedAt: string): string {
    return this.toTimestamp(generatedAt).slice(0, 10);
  }

  getLabelTime(generatedAt: string): string {
    return this.toTimestamp(generatedAt).slice(11, 16);
  }

  getFormatDate(generatedAt: string): string {
    const today = this.generateTimestamp();
    const timestamp = this.toTimestamp(generatedAt);

    return (today.slice(0, 4) === timestamp.slice(0, 4)) ? 'MM月dd日' : 'yyyy年MM月dd日';
  }
}
