<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            @if(isProcessing) { <!-- 処理中の表示 -->
            <div>
                <div class="card card-body">
                    <app-spinner></app-spinner>
                </div>
            </div>
            }

            @else {
            @if(!isAuthenticated) { <!-- ログインの確認 -->
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                ギフコメにログインするには、下記のボタンをクリックします。
                            </p>
                        </div>

                        <div>
                            <button class="btn btn-main rounded-pill" type="button" (click)="signIn()">
                                ログイン
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            @else if(!registration?.nameCompany) { <!-- 申請内容の確認 -->
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                収益化を開始するには申請情報を入力します。情報は審査に使われるため正確に入力してください。
                            </p>
                        </div>

                        <div>
                            <app-modal-registration-update></app-modal-registration-update>
                            <button class="btn btn-main rounded-pill" type="button" data-bs-toggle="modal"
                                data-bs-target="#modalRegistrationUpdate">
                                <i class="fa-solid fa-pen-to-square"></i>
                                申請情報を編集
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            @else if(account?.uidParent === undefined && !accountInviter) {
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                招待リンクが間違っています。招待リンクを再度確認してください。
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            }

            @else if(!account?.idStripeConnected) { <!-- Stripe Connectedアカウントの確認 -->
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                申請の準備が整いました。「{{ accountInviter?.name }}」さんの招待で収益化を開始するには下記のボタンをクリックします。
                            </p>
                            <p>
                                ※収益化を開始すると、クリエイターにギフコメを贈ることはできなくなります。もしクリエイターにギフコメを贈りたい場合は、もう一つアカウントを作成してください。
                            </p>
                        </div>

                        <div>
                            <button class="btn btn-main rounded-pill" type="button"
                                (click)="createAccountStripeConnected()">
                                <i class="fa-solid fa-rocket"></i>
                                収益化を開始
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            @else if(!isStripeConnectedSubmitted) { <!-- Stripe Connectedアカウントのプロフィールを入力 -->
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                収益を受け取るためにStripe Connectedアカウントのプロフィールを入力します。この入力が完了するとギフコメの収益化が開始されます。
                            </p>
                        </div>

                        <div>
                            <button class="btn btn-link" role="button"
                                (click)="redirectToSettingsAccountStripeConnected()">
                                Stripeを開く
                                <i class="fa-solid fa-arrow-up-right-from-square icon-suffix"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            @else { <!-- 収益化の開始 -->
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>おめでとうございます！</h5>
                            <p>設定が完了しました。ギフコメをご活用ください。</p>
                        </div>

                        <div>
                            <a class="btn btn-link" role="button" routerLink="/revenue">
                                収益を表示
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            }
            }

        </div>
    </div>
</section>