import { Component, OnInit } from '@angular/core';
import { User } from '@angular/fire/auth';
import { ManagerIconService } from '../../services/managers/manager-icon/manager-icon.service';
import { ModalAccountUpdateComponent } from "../../modals/modal-account-update/modal-account-update.component";
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';
import { RouterLink } from '@angular/router';
import { ModalRegistrationUpdateComponent } from "../../modals/modal-registration-update/modal-registration-update.component";
import { Registration } from '../../interfaces/registration';
import { ManagerRegistrationService } from '../../services/managers/manager-registration/manager-registration.service';
import { ManagerBotService } from '../../services/managers/manager-bot/manager-bot.service';
import { ENVIRONMENT } from 'settings/environments/environment.development';

@Component({
  selector: 'app-profile',
  standalone: true,
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  imports: [ModalAccountUpdateComponent, RouterLink, ModalRegistrationUpdateComponent]
})
export class ProfileComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  get user(): User | null {
    return this.managerUser.user;
  }

  get account(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  get registration(): Registration | undefined {
    return this.modelRegistration.registration;
  }

  get icons(): string[] {
    return this.managerIcon.icons;
  }

  /******************************
   * Status
   ******************************/

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private managerAuth: ManagerAuthService,
    private managerAccount: ManagerAccountService,
    private managerUser: ManagerUserService,
    private managerIcon: ManagerIconService,
    private modelRegistration: ManagerRegistrationService
  ) {
  }

  ngOnInit(): void {

  }

  /******************************
   * Methods
   ******************************/

  getDeauthenticated(): void {
    this.managerAuth.signOut();
  }

  getLabelRegistrationType(type: string | undefined): string {
    switch (type) {
      case 'INDIVIDUAL':
        return '個人';
      case 'COMPANY':
        return '法人';

      default:
        return '';
    }
  }

}
