<section class="modal fade" id="modalPointPurchase" tabindex="-1"
    aria-labelledby="labelModalPointPurchase" aria-hidden="true">
    <div class="modal-dialog">
        <section class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalPointPurchase">ポイントの追加</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            @if(!isProcessing) {
            <div class="modal-body">
                <form class="row gy-2">

                    @if(paymentMethods !== undefined && paymentMethods.length > 0) {
                    <section>
                        <p>
                            下記のボタンを押すと即時にポイントが追加されます。確認は出ませんのでご注意ください。
                        </p>
                    </section>

                    <section>
                        <div class="row g-50">
                            @for(planPoint of plansPoint; track $index) {
                            <div class="col-4">
                                <div class="card card-body card-button-2" role="button"
                                    (click)="indexPlanPoint = $index; purchasePoint()">
                                    <!--
                                    [class.card-button-active]="$index === indexPlanPoint"> -->
                                    <div class="d-flex align-items-baseline justify-content-center">
                                        <img src="/assets/images/icons/coin.svg" style="width: 1.0rem">
                                        <h5 class="d-inline" style="margin-left: 0.25rem; margin-right: 0.25rem;">{{
                                            planPoint.point |
                                            number: '.0-0' }}</h5>
                                    </div>
                                    <p class="d-flex justify-content-center">{{ planPoint.price |
                                        currency: 'JPY' }}</p>
                                </div>
                            </div>
                            }
                        </div>
                    </section>
                    }

                    @else {
                    <section>
                        <p>
                            ポイントの追加にはカード設定を行います。
                        </p>
                    </section>

                    <section>
                        <button class="btn btn-link" type="button" (click)="redirectToPortalAccountStripeCustomer()">
                            カード設定のポータルに移動
                            <i class="fa-solid fa-arrow-up-right-from-square icon-suffix"></i>
                        </button>
                    </section>
                    }

                </form>
            </div>
            }

            @else {
            <div class="modal-body">
                <div class="row gy-2">
                    <app-spinner></app-spinner>
                </div>
            </div>
            }
        </section>
    </div>
</section>