export const CONSTANT = {
    RATE_TAX_CONSUMPTION: 10 / 100, //消費税
    RATE_TAX_WITHHOLDING: 10.21 / 100, //源泉徴収税

    RATE_FEE_STRIPE_PAYMENT: 3.6 / 100, //Stripeの決済手数料
    RATE_FEE_GIFCOME: 3.0 / 100, //Stripe Expressの出金手数料などを含むギフコメ手数料

    RATE_ALLOCATION_ADMIN: 30 / 100,
    RATE_ALLOCATION_AGENT: 15 / 100,
    RATE_ALLOCATION_AGENT_PARENT: 5 / 100, //=アフィリエイター。仲介者なので。
    RATE_ALLOCATION_AGENT_CHILD: 10 / 100,
    RATE_ALLOCATION_CREATOR: 55 / 100,

    RATE_POINT_GIFCOME_PRIVATE: 1.5,

    FIREBASE: {
        REGION: 'asia-northeast1'
    },

    STRIPE: {
        ACCOUNTS_STANDARD: [
            'acct_1NJQfrGgcyyUb3mm',
            'acct_1NIsqRGf8npIqToj',
            'acct_1NHU3UGhn8GJLEaJ',
            'acct_1NHS942cAT7KH71C',
            'acct_1NH7ivGgGvrbkEdB',
            'acct_1NH4HAGdcK0pU3cp',
            'acct_1NGfV82f23yDpnBw',
            'acct_1NGOKB2fYNkMtJZ0',
            'acct_1NGODxGhe34QVWED',
            'acct_1NGNmH2fgjOJRel1',
            'acct_1NGJ8X2f8mi6tgjw',
            'acct_1NGHqSGhvsBw4gFQ',
            'acct_1NFXxY2cyepwyAIz',
            'acct_1NFD4SGgc04E4jFe',
            'acct_1NF8fLGgZN9fU9ny',
            'acct_1NEsxDGdI1OZ5g1R',
            'acct_1NEsYQGdR0pVGMM8',
            'acct_1NEn2fGfRNtAZX7a',
            'acct_1NEd1h2cJoQ1Awtr',
            'acct_1NEDbcGaEuOZ3ltG',
            'acct_1N0bnVGgkm3E32CE',
            'acct_1N0bUPGdhC2WlwvH',
            'acct_1N0Z3w2fJ5lFfzZu',
            'acct_1N0YyrGdMwMC1cGT',
        ]
    }
};