import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ModalPointPurchaseComponent } from "../../modals/modal-point-purchase/modal-point-purchase.component";
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { DecimalPipe } from '@angular/common';
import { ManagerStripeCustomerService } from '../../services/managers/manager-stripe-customer/manager-stripe-customer.service';

@Component({
    selector: 'app-wallet',
    standalone: true,
    templateUrl: './wallet.component.html',
    styleUrl: './wallet.component.scss',
    imports: [RouterLink, ModalPointPurchaseComponent, DecimalPipe]
})
export class WalletComponent {

    /******************************
     * Properties
     ******************************/

    get account(): Account | undefined {
        return this.managerAccount.accountMine;
    }

    /******************************
     * Lifecyle hooks
     ******************************/

    constructor(
        private managerUser: ManagerUserService,
        private managerAccount: ManagerAccountService,
        private managerStripeCustomer: ManagerStripeCustomerService
    ) { }

    /******************************
     * Methods
     ******************************/

    getUrlPortalAccountStripeCustomer(): string {
        return this.managerStripeCustomer.getUrlStripeCustomerPortal(this.managerUser.user!.email!);
    }

    getLabelUnitPoint(point: number): string {
        return point > 0 ? 'pts' : 'pt';
    }

}
