import { Component } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";

@Component({
  selector: 'app-get-started',
  standalone: true,
  templateUrl: './get-started.component.html',
  styleUrl: './get-started.component.scss',
  imports: [RouterLink, ButtonCloseModalComponent]
})
export class GetStartedComponent {

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private router: Router,
    private managerAuth: ManagerAuthService,
    private managerUser: ManagerUserService
  ) { }

  /******************************
   * Methods
   ******************************/

  // getStarted(): void {
  //   if (this.managerAuth.isAuthenticated) {
  //     this.router.navigate(['/followings']);
  //   }
  //   else {
  //     this.managerAuth.signIn();
  //   }
  // }

  signIn(): void {
    this.managerAuth.signIn();
  }

}
