import { AfterViewChecked, AfterViewInit, Component, ElementRef, Host, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ManagerChatService } from '../../services/managers/manager-chat/manager-chat.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { User } from '@angular/fire/auth';
import { ModalGifcomeCreateComponent } from "../../modals/modal-gifcome-create/modal-gifcome-create.component";
import { Account } from '../../interfaces/account';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { UtilTimestampService } from '../../services/utils/util-timestamp/util-timestamp.service';
import { ManagerGifcomeService } from '../../services/managers/manager-gifcome/manager-gifcome.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { SpinnerComponent } from "../../components/spinner/spinner.component";
import { Gifcome } from '../../interfaces/gifcome';
import { PlanGifcome } from '../../interfaces/plan-gifcome';
import { ModalPreviewImageComponent } from "../../modals/modal-preview-image/modal-preview-image.component";
import { UtilTranslationService } from '../../services/utils/util-translation/util-translation.service';
import { UtilPushNotificationService } from '../../services/utils/util-push-notification/util-push-notification.service';
import { DatePipe } from '@angular/common';
import { ModalReportComponent } from "../../modals/modal-report/modal-report.component";
import { SwPush } from '@angular/service-worker';
import { ManagerBotService } from '../../services/managers/manager-bot/manager-bot.service';
import { ManagerPushNotifService } from '../../services/managers/manager-push-notif/manager-push-notif.service';

declare const bootstrap: any;

@Component({
  selector: 'app-chat',
  standalone: true,
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  imports: [
    ModalGifcomeCreateComponent,
    RouterLink,
    SpinnerComponent,
    ModalPreviewImageComponent,
    DatePipe,
    ModalReportComponent
  ]
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private _timeCurrent: string;
  get timeCurrent(): string {
    return this._timeCurrent;
  }

  private _gifcomeOldest?: Gifcome;
  get gifcomeOldest(): Gifcome | undefined {
    return this._gifcomeOldest;
  }

  private _gifcomesCurrentPre: Gifcome[] = [];
  get gifcomesCurrentPre(): Gifcome[] {
    return this._gifcomesCurrentPre;
  }

  private _gifcomesCurrentPost: Gifcome[] = [];
  get gifcomesCurrentPost(): Gifcome[] {
    return this._gifcomesCurrentPost;
  }
  private _gifcomesCurrentPost$?: Observable<Gifcome[]>;

  get user(): User | null {
    return this.managerUser.user;
  }

  private _accountTarget: Account | undefined;
  get accountTarget(): Account | undefined {
    return this._accountTarget;
  }

  private _idChat: string;
  get idChat(): string {
    return this._idChat;
  }

  get gifcomess(): Gifcome[][] {
    return [this.gifcomesCurrentPre, this.gifcomesCurrentPost];
  }
  private _gifcomes$?: Observable<Gifcome[]>;

  get uidCreator(): string {
    return this.idChat.split('-')[0];
  }

  get uidUser(): string {
    return this.idChat.split('-')[1];
  }

  get uidTarget(): string {
    return this.uidCreator === this.user!.uid ? this.uidUser : this.uidCreator;
  }

  private _idsGifcome: string[] = [];
  get idsGifcome(): string[] {
    return this._idsGifcome;
  }

  private _urlImage?: string;
  get urlImage(): string | undefined {
    return this._urlImage;
  }

  private _idsGifcomeTranslating: string[] = [];
  get idsGifcomeTranslating(): string[] {
    return this._idsGifcomeTranslating;
  }

  /*** Status ***/

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  get isLoadable(): boolean {
    if (this.gifcomeOldest === undefined) {
      return false;
    }

    return this.gifcomeOldest && this.gifcomesCurrentPre.length > 0 && this.gifcomesCurrentPre[0].id !== this.gifcomeOldest.id;
  }

  // get isSubscribed(): boolean {
  //   return this.utilPushNotification.isSubscribed;
  // }

  private _isPushAvailable: boolean = false;
  get isPushAvailable(): boolean {
    return this._isPushAvailable;
  }

  get hasFirstGifcome(): boolean {
    return true;
  }

  constructor(
    private route: ActivatedRoute,
    private push: SwPush,

    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private managerChat: ManagerChatService,
    private managerGifcome: ManagerGifcomeService,
    private mgrPushNotif: ManagerPushNotifService,
    private mgrBot: ManagerBotService,

    private stateProcessing: StateProcessingService,
    // private utilPushNotification: UtilPushNotificationService,
    private utilTimestamp: UtilTimestampService,
    private utilTranslattion: UtilTranslationService,
    private elemRef: ElementRef
  ) {
    const idChat = this.route.snapshot.paramMap.get('idChat');
    if (!idChat) {
      throw new Error('idChat is required');
    }

    this._idChat = idChat;
    this._timeCurrent = this.utilTimestamp.generateTimestamp();

    this.push.subscription.subscribe((subscription: PushSubscription | null) => {
      this._isPushAvailable = subscription !== null;
    });
  }

  private _subscsPushNotif: any[] = [];
  get subscsPushNotif(): any[] {
    return this._subscsPushNotif;
  }

  get isPushNotifSubscribed(): boolean {
    const subsc = this.subscsPushNotif.find(subsc => subsc.data.endpoint === this.mgrPushNotif.subscToThisApp?.endpoint);
    return subsc !== undefined;
  }

  async ngOnInit(): Promise<void> {
    // this.utilPushNotification.getSubscriptionPushNotification();
    this._subscsPushNotif = await this.mgrPushNotif.gets();

    // Get gifcome oldest
    const gifcomeOldest = await this.managerChat.getGifcomeOldest(this.idChat);
    this._gifcomeOldest = gifcomeOldest;

    // Get gifcomes pre-current
    const gifcomesCurrentPre = await this.managerChat.getGifcomesPrecurrent(this.idChat, this.timeCurrent);
    this._gifcomesCurrentPre = gifcomesCurrentPre.concat(this.gifcomesCurrentPre);


    // Get gifcomes post-current
    this._gifcomesCurrentPost$ = this.managerChat.getGifcomesPostcurrent(this.idChat, this.timeCurrent);
    const subscription = this._gifcomesCurrentPost$.subscribe((gifcomesCurrentPost: Gifcome[]) => {
      this._gifcomesCurrentPost = gifcomesCurrentPost;
    });
    this.subscriptions.push(subscription);

    // Set height chat-wrapper
    const chatWrapper = document.getElementById('chat-wrapper')!;
    const navbarMain = document.getElementById('navbar-main');
    const navbarChat = document.getElementById('navbar-chat');
    const heightWindow = window.innerHeight;
    const heightNavbarMain = navbarMain ? navbarMain.clientHeight : 0;
    const heightNavbarChat = navbarChat ? navbarChat.clientHeight : 0;
    chatWrapper.style.height = heightWindow - (heightNavbarMain + heightNavbarChat) + 'px';

    // Set background color
    document.body.style.backgroundColor = '#F5F9FF';

    // Get account target
    let uidCreator = this.idChat.split('-')[0];
    let uidUser = this.idChat.split('-')[1];

    const uidTarget = (uidCreator === this.user!.uid) ? uidUser : uidCreator;
    this.managerAccount.getAccount(uidTarget).then((accountTarget: Account | undefined) => {
      this._accountTarget = accountTarget;
    });

    // Scroll to bottom
    // const chatContent = document.getElementById('chat-content')!;
    // chatContent.scrollTop = chatContent.clientHeight;

    this.onScroll().then(() => {
      let count = 0;
      const interval = setInterval(() => {
        const chatContent = document.getElementById('chat-content')!;
        chatContent.scrollTop = chatContent.scrollHeight;

        count++;
        if (count === 10) clearInterval(interval);
      }, 0.1 * 1000);
    });

    const modal = this.elemRef.nativeElement.querySelector('#modalReportCreate')!;
    modal.addEventListener('hidden.bs.modal', () => {
      this.endModeReport();
    });

    this._isBot = await this.mgrBot.checkBotMode(this.uidCreator);
  }
  private _isBot: boolean = false;
  get isBot(): boolean {
    return this._isBot;
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    document.body.style.backgroundColor = 'transparent';

    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  /****************************
   * Events
   ***************************/

  @HostListener('window:resize')
  onResize(): void {
    const chatWrapper = document.getElementById('chat-wrapper')!;
    const navbarMain = document.getElementById('navbar-main');
    const navbarChat = document.getElementById('navbar-chat');
    const heightWindow = window.innerHeight;
    const heightNavbarMain = navbarMain ? navbarMain.clientHeight : 0;
    const heightNavbarChat = navbarChat ? navbarChat.clientHeight : 0;
    chatWrapper.style.height = heightWindow - (heightNavbarMain + heightNavbarChat) + 'px';

    this.onScroll();
  }

  /****************************
   * Methods
   ***************************/

  isMine(gifcome: Gifcome): boolean {
    return this.managerGifcome.isMine(gifcome);
  }

  isCreators(gifcome: Gifcome): boolean {
    return gifcome.uidFrom === this.uidCreator;
  }

  getPlanGifcome(idPlanGifcome: number): PlanGifcome {
    return this.managerGifcome.getPlanGifcome(idPlanGifcome);
  }

  async onScroll(): Promise<boolean> {
    const chatWrapper = document.getElementById('chat-wrapper')!;
    const chatContent = document.getElementById('chat-content')!;

    if (this.isLoadable) {
      if (chatContent.scrollTop < 10 && !this.isProcessing) {
        const pid = this.stateProcessing.start();
        const chatContentScrollHeightPrev = chatContent.scrollHeight;
        const gifcomesCurrentPre = await this.managerChat.getGifcomesPrecurrent(this.idChat, this.gifcomesCurrentPre[0].generatedAt);
        this._gifcomesCurrentPre = gifcomesCurrentPre.concat(this.gifcomesCurrentPre);

        const chatContentScrollHeightCurrent = chatContent.scrollHeight;
        this.stateProcessing.end(pid);

        const diffHeight = chatContentScrollHeightCurrent - chatContentScrollHeightPrev;
        chatContent.scrollTop = chatContent.scrollTop + diffHeight + 10;

        if (chatContent.scrollHeight <= chatContent.clientHeight) {
          await this.onScroll();
        }
      }
    }

    return true;
  }

  getLabelRead(gifcomeCurrent: Gifcome): string {
    if (!gifcomeCurrent.isRead) return '';

    let gifcomeNext: Gifcome | null = null;
    for (let gifcomes of this.gifcomess) {
      const filtered = this.filterMine(gifcomes);
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i].id === gifcomeCurrent.id) {
          if (i + 1 < filtered.length) {
            gifcomeNext = filtered[i + 1];
          }
          break;
        }
      }
    }

    if (gifcomeNext === null) {
      return '既読';
    }
    else {
      return gifcomeNext.isRead ? '' : '既読';
    }
  }

  filterMine(gifcomes: Gifcome[]): Gifcome[] {
    return gifcomes.filter(gifcome => gifcome.uidFrom === this.user?.uid);
  }

  showModalPreviewImage(urlImage: string | undefined): void {
    this._urlImage = urlImage;

    const modal = new bootstrap.Modal(document.getElementById('modalPreviewImage')!);
    modal.show();
  }

  translate(gifcome: Gifcome): void {
    this._idsGifcomeTranslating.push(gifcome.id);

    this.utilTranslattion.translateGifcome(gifcome).finally(() => {
      this._idsGifcomeTranslating = this.idsGifcomeTranslating.filter(id => id !== gifcome.id);
    });
  }

  checkTranslating(idGifcome: string): boolean {
    return this.idsGifcomeTranslating.includes(idGifcome);
  }

  checkJapanese(comment: string): boolean {
    return this.utilTranslattion.checkJapanese(comment);
  }

  //Showing date and time
  checkDateBorder(idGifcome: string): boolean {
    return this.managerGifcome.checkDateBorder(idGifcome, this.gifcomesCurrentPre.concat(this.gifcomesCurrentPost));
  }

  getLabelDate(idGifcome: string): string {
    return this.utilTimestamp.getLabelDate(idGifcome);
  }

  getLabelTime(generatedAt: string): string {
    return this.utilTimestamp.getLabelTime(generatedAt);
  }

  getFormatDate(generatedAt: string): string {
    return this.utilTimestamp.getFormatDate(generatedAt);
  }

  //Report
  private _isModeReport: boolean = false;
  get isModeReport(): boolean {
    return this._isModeReport;
  }
  startModeReport(): void {
    this._isModeReport = true;
  }
  endModeReport(): void {
    this._isModeReport = false;
    this._idsGifcome = [];
  }

  checkGifcome(event: any, idGifcome: string): void {
    if (event.target.checked) {
      this._idsGifcome.push(idGifcome);
    }
    else {
      this._idsGifcome = this.idsGifcome.filter(id => id !== idGifcome);
    }

    console.log(this.idsGifcome);
  }

  isExtension(): boolean {
    return (window.location.href.indexOf('extension') !== -1);
  }

  subscribePushNotification(): void {
    const pid = this.stateProcessing.start();

    this.mgrPushNotif.subscribe().then(async () => {
      this._subscsPushNotif = await this.mgrPushNotif.gets();
      this.stateProcessing.end(pid);
    });
  }

  unsubscribePushNotif(): void {
    this.mgrPushNotif.unsubscribe(this.subscsPushNotif);
  }

  checkImage(url: string): boolean {
    //check if the url includes the image extension
    return url.includes('.jpg') || url.includes('.jpeg') || url.includes('.png');
  }

}