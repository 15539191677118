import { Component } from '@angular/core';

@Component({
  selector: 'app-dashboard-agent',
  standalone: true,
  imports: [],
  templateUrl: './dashboard-agent.component.html',
  styleUrl: './dashboard-agent.component.scss'
})
export class DashboardAgentComponent {

}
