import { Component, Input } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-modal-preview-qr',
  standalone: true,
  templateUrl: './modal-preview-qr.component.html',
  styleUrl: './modal-preview-qr.component.scss',
  imports: [ButtonCloseModalComponent, QRCodeModule]
})
export class ModalPreviewQrComponent {

  @Input() url?: string;

}
