import { Component, OnInit } from '@angular/core';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-dashboard-admin',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './dashboard-admin.component.html',
  styleUrl: './dashboard-admin.component.scss'
})
export class DashboardAdminComponent implements OnInit {

  agentParents?: Account[];
  agentChilds?: Account[];
  creators?: Account[];

  get accountMine(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  constructor(
    private managerAccount: ManagerAccountService
  ) { }

  async ngOnInit() {
    this.agentParents = await this.managerAccount.getAgentParents();
    console.log(this.agentParents);
    this.agentChilds = await this.managerAccount.getAgentChilds();
    this.creators = await this.managerAccount.getCreators();
  }

}
