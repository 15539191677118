import { Component, OnDestroy, OnInit } from '@angular/core';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account, AccountRole } from '../../interfaces/account';
import { ManagerStripeConnectedService } from '../../services/managers/manager-stripe-connected/manager-stripe-connected.service';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { UtilUrlService } from '../../services/utils/util-url/util-url.service';
import { UtilClipboardService } from '../../services/utils/util-clipboard/util-clipboard.service';
import { RouterLink } from '@angular/router';
import { User } from '@angular/fire/auth';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { WidgetRevenueComponent } from "../../components/widget-revenue/widget-revenue.component";
import { ENVIRONMENT } from 'settings/environments/environment.development';
import { ManagerBotService } from '../../services/managers/manager-bot/manager-bot.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-revenue',
  standalone: true,
  templateUrl: './revenue.component.html',
  styleUrl: './revenue.component.scss',
  imports: [CurrencyPipe, DatePipe, RouterLink, WidgetRevenueComponent,
    FormsModule,
    ReactiveFormsModule]
})
export class RevenueComponent implements OnInit, OnDestroy {

  /******************************
   * Properties
   ******************************/

  get user(): User | null {
    return this.managerUser.user;
  }

  get account(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  get balance(): number {
    const balance = this.managerStripeConnected.balance;
    if (balance === 'LOADING') return 0;
    if (balance === undefined) return 0;

    return this.managerStripeConnected.balance as number;
  }

  private _stripeConnected?: any;
  get stripeConnected(): any | undefined {
    return this._stripeConnected;
  }

  get isStripeConnectedSubmitted(): boolean {
    return this.managerStripeConnected.checkSubmitted(this.stripeConnected);
  }

  get isStripeConnectedDisabled(): boolean {
    return this.managerStripeConnected.checkDisabled(this.stripeConnected);
  }

  private _whitelist: string[] = [];
  private get whitelist(): string[] {
    return this._whitelist;
  }

  get isBotAvailable(): boolean {
    if (ENVIRONMENT.MODE !== 'PRODUCTION') return true;

    const uid = this.managerUser.user?.uid;
    if (!uid) return false;

    return this.whitelist.includes(uid);
  }

  private _isBotMode: boolean = false;
  get isBotMode(): boolean {
    return this._isBotMode;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private managerStripeConnected: ManagerStripeConnectedService,
    private mgrBot: ManagerBotService,
    private utilUrl: UtilUrlService,
    private utilClipboard: UtilClipboardService
  ) { }

  ngOnInit(): void {
    this.loadWhitelist();
    this.loadBotMode();
    this.loadTimmer();
    this.getSettingsChara();

    const interval = setInterval(() => {
      if (this.account === undefined) return;
      clearInterval(interval);

      this.managerStripeConnected.getStripeConnected(this.account.idStripeConnected!).then((response: any) => {
        this._stripeConnected = response.accountStripeConnected;
      });
    }, 0.1 * 1000);
  }

  ngOnDestroy(): void {
    this.managerStripeConnected.resetBalance();
  }

  /******************************
   * Methods
   ******************************/

  redirectToSettings(): void {
    this.managerStripeConnected.redirectToSettings(this.account?.idStripeConnected!);
  }

  redirectToDashboard(): void {
    this.managerStripeConnected.redirectToDashboard(this.account?.idStripeConnected!);
  }

  getUrlInvitation(role: AccountRole): string {
    return this.utilUrl.getUrlInvitation(role);
  }

  copy(text: string): void {
    this.utilClipboard.copy(text);
  }

  async loadWhitelist(): Promise<void> {
    this._whitelist = await this.mgrBot.loadWhitelist();
  }

  async loadBotMode(): Promise<void> {
    this._isBotMode = await this.mgrBot.loadBotMode();
  }

  toggleBotMode(): void {
    this._isBotMode = !this._isBotMode;
    this._expiredAt = null;
    this.mgrBot.toggleBotMode(this.isBotMode);
  }

  settingsChara: string = '';

  save(): void {
    this.mgrBot.saveSettingsChara(this.settingsChara);
  }

  async getSettingsChara(): Promise<void> {
    this.settingsChara = await this.mgrBot.getSettingsChara();
  }

  setTimmer(n: number): void {
    this.mgrBot.setTimmer(n).then(() => {
      this.loadTimmer();
    });
  }

  private _expiredAt: string | null = null;
  get expiredAt(): string | null {
    return this._expiredAt;
  }
  async loadTimmer(): Promise<void> {
    this._expiredAt = await this.mgrBot.loadTimmer();
  }


}
