<section class="py-5">
    <div class="container">
        <div class="row gy-1">

            <h1>プライバシーポリシー</h1>

            <p>
                ＭＤＫ合同会社（以下「当社」といいます。）は、当社が提供する「ギフコメ」サービス（当社が管理するソフトウェア及びウェブサイトを通じてお客様に提供するサービスの一切を総称します。以下「本サービス」といいます。）の提供にあたり、以下のとおり個人情報保護方針（以下「本プライバシーポリシー」といいます。）を定め、個人情報保護の仕組みを構築し、全従業員に個人情報保護の重要性を認識させるとともにその取組みを徹底させることにより、個人情報の保護を推進します。
            </p>

            <h4>第1条（個人情報）</h4>
            <p>
                「個人情報」とは、個人情報の保護に関する法律（平成十五年法律第五十七号、以下「個人情報保護法」といいます。）の第２条１項で定義される「個人情報」を指し、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別できるもの又は個人識別符号が含まれるものを指します（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）。
            </p>

            <h4>第2条（個人情報の取得と利用）</h4>
            <p>
                当社は、以下の目的に必要な範囲で、お客様の個⼈情報を取得し、取得した情報を利用させていただきます。以下の⽬的の範囲を超えて個⼈情報を利⽤する場合には、事前に適切な⽅法でお客様からの同意を得るものとします。
            </p>

            <ul>
                <li>当社及び当社の関連会社の商品及びサービス（本サービス及びこれに付帯するサービスを含みます。以下「本サービス等」といいます。）を提供するため</li>
                <li>本サービス等（ポイントの発行等も含む。）の料金請求、課金計算のため</li>
                <li>本サービス等の内容を改良・改善し又は新サービスを開発するため（新機能及び内容の追加等を含みます。）</li>
                <li>本サービス等の新機能、更新情報、キャンペーン等及び当社が提供する他のサービスのご案内（電子メール、チラシ、その他のダイレクトメールの送付を含む。）のため></li>
                <li>メンテナンス、重要なお知らせなど必要に応じたご連絡のため</li>
                <li>本サービス等に関するお客様からのお問い合わせに回答するため（ご本人様確認を行うことを含みます。）</li>
                <li>本サービス等の利用状況をお客様にご報告するため</li>
                <li>本サービス等に関するアンケート・取材等のご協力依頼や各種イベントへのご参加をお願いし又はその結果などをご報告するため</li>
                <li>本サービス等の利用履歴等を調査・分析し、その結果を本サービス等の改良・開発や広告の配信に利用するため</li>
                <li>利用規約に違反した利用者や、不正・不当な目的で本サービス等を利用しようとする利用者の特定をし、ご利用をお断りするため</li>
                <li>その他前各号に定める利用目的に付随する目的を達成するため</li>
            </ul>

            <h4>第３条（個人情報の提供）</h4>
            <p>
                当社は、以下に定める場合には、個人情報を第三者に提供することができるものとします。
            </p>

            <ul>
                <li>お客様が、当社に対して支払うべき料金の決済を行うために、金融機関、クレジットカード会社、回収代行会社その他決済又はその代行を行う事業者に開示する場合</li>
                <li>当社が行う業務の全部又は一部を第三者に委託する場合</li>
                <li>当社が行う業務の全部又は一部を第三者に委託する場合</li>
                <li>当社の権利行使に必要な場合</li>
                <li>個人情報保護法その他の法令により認められた場合</li>
            </ul>

            <h4>第４条（個人情報の管理と保護）</h4>
            <p>
                個人情報の管理は、厳重に行うこととし、次に掲げる場合を除き、ご本人の同意がない限り、第三者に対しデータを開示・提供することはいたしません。
                また、安全性を考慮し、個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏えい等のリスクに対する予防並びに是正に関する対策を講じます。
            </p>

            <ul>
                <li>人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難である場合</li>
                <li>公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難である場合</li>
                <li>国の機関、地方公共団体又はその委託を受けた者若しくは弁護士会が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                </li>
                <li>業務を円滑に遂行するため、利用目的の達成に必要な範囲内において個人情報の取扱いの全部又は一部を委託する場合</li>
                <li>合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                <li>個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名又は名称について、あらかじめ本人に通知し又は本人が容易に知り得る状態に置いた場合
                </li>
                <li>その他法令で認められる場合</li>
            </ul>

            <h4>第５条(個人情報の取扱いの委託)</h4>
            <p>
                当社は、利用目的の達成に必要な範囲内において、個人情報の取扱いの全部又は一部を委託する場合がございます。この場合、当社は、委託先としての適格性を十分審査するとともに、契約にあたって守秘義務に関する事項等を定め、委託先に対する必要かつ適切な監督を行います。また、当社は、取得個人情報の全部又は一部を、個人情報保護法の定めに基づいて共同利用することがございます。
            </p>

            <h4>第６条（個人情報の開示）</h4>
            <p>
                当社は、お客様から個人情報の開示を求められたときは、本人確認の上、お客様に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部又は一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。
            </p>

            <ul>
                <li>お客様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>
                <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                <li>その他法令に違反することとなる場合</li>
            </ul>

            <h4>第７条（個人情報の訂正及び削除）</h4>
            <p>
                当社が保有する個人情報が誤った情報である場合には、お客様の請求により、当社が定める手続きに従い個人情報の訂正又は削除を行います。
                当社は、お客様から前項の請求を受けてその請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の訂正又は削除を行い、これをご本人に通知します。

            </p>

            <h4>第８条（個人情報の利用停止等</h4>
            <p>
                当社は、お客様から、個人情報が、利用目的の範囲を超えて取り扱われているという理由又は不正の手段により取得されたものであるという理由により、その利用の停止又は消去（以下「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行い、その結果に基づき、個人情報の利用停止等を行い、その旨お客様に通知します。
                ただし、個人情報の利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客様の権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じます。
            </p>

            <h4>第９条(プライバシーポリシーの変更手続)</h4>
            <p>
                当社は本ポリシーの内容を適宜見直し、その改善に努めます。本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、変更することができるものとします。変更後のプライバシーポリシーは、当社所定の方法により、利用者に通知し、又は当社ウェブサイトに掲載したときから効力を生じるものとします。
            </p>

            <h4>第10条（法令、規範の遵守）</h4>
            <p>
                当社は、保有する個人情報に関して適用される日本の法令、その他規範を遵守します。
            </p>

            <h4>第11条（苦情及び相談への対応）</h4>
            <p>
                当社は、個人情報の取扱いに関するお客様からの苦情、相談を受け付け、適切かつ迅速に対応いたします。また、お客様からの当該個人情報の開示、訂正、追加、削除、利用又は提供の拒否などのご要望に対しても、迅速かつ適切に対応いたします。
            </p>

            <h4>第12条（お問い合わせ窓口）</h4>
            <p>
                当社の個人情報の取扱いに関するお問い合せは下記までご連絡ください。
            </p>

            <ul>
                <li>合同会社MDK　お客様対応窓口</li>
                <li>合同会社MDK</li>
                <li>〒100-6213</li>
                <li>東京都千代田区丸の内１－１１－１</li>
                <li>パシフィックセンチュリープレイス丸の内8階</li>

                <li>Mail: websupport&#64;gifcome.com</li>

            </ul>
        </div>

    </div>
</section>