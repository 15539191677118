<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div class="row gy-1">
                    <div>
                        <h5 class="mb-0 d-flex align-items-center">
                            <!-- <i class="fa-solid fa-history fa-sm"></i> -->
                            ギフコメ
                        </h5>
                    </div>

                    <!-- <div>
                        <div class="callout-perl-1">
                            <small>
                                ギフコメの一覧です。ギフチャは<a routerLink="/chats">こちら</a>で確認できます。
                            </small>
                        </div>
                    </div> -->
                </div>
            </section>

            <section>
                <div class="row gy-1">

                    <section>
                        <div>
                            <ul class="nav nav-tabs">
                                <li class="nav-item">
                                    <a class="nav-link" [class.active]="isModeSent" role="button"
                                        (click)="isModeSent = true">贈った</a>
                                </li>
                                @if(accountMine?.role === 'CREATOR') {
                                <li class="nav-item">
                                    <a class="nav-link" [class.active]="!isModeSent" role="button"
                                        (click)="isModeSent = false">もらった</a>
                                </li>
                                }
                            </ul>
                        </div>
                    </section>

                    <section>
                        <div>

                            @if(isModeSent) {
                            <section>
                                <div class="row gy-50">
                                    @for(gifcome of gifcomeSents; track gifcome.id) {
                                    @if(checkDateBorder(gifcome.id, gifcomeSents!)) {
                                    <section class="py-1">
                                        <div class="d-flex justify-content-center">
                                            <section class="text-white bg-text-link px-1"
                                                style="border-radius: 50rem; padding-bottom: 2px;">
                                                <small>{{ getLabelDate(gifcome.generatedAt) |
                                                    date:
                                                    getFormatDate(getLabelDate(gifcome.generatedAt)) }}</small>
                                            </section>
                                        </div>
                                    </section>
                                    }

                                    <section>
                                        <div class="card card-body py-1 border-0 bg-perl-1">
                                            <div class="row gy-50">
                                                <div class="d-flex justify-content-between">
                                                    <a [routerLink]="['/', 'p', gifcome.uidTo]">{{ gifcome.nameTo }}</a>

                                                    <p>{{ convert(gifcome.generatedAt) | date: 'HH:mm' }}</p>
                                                </div>

                                                <div>
                                                    <img [src]="getPlanGifcome(gifcome.idPlanGifcome).urlIcon"
                                                        class="me-0"
                                                        style="width: 1.0rem; margin-bottom: 3px;">
                                                    {{ gifcome.comment }}
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    }
                                </div>
                            </section>
                            }
                            @else {
                            <section>
                                <div class="row gy-50">
                                    @for(gifcome of gifcomeRecieveds; track gifcome.id) {
                                    @if(checkDateBorder(gifcome.id, gifcomeRecieveds!)) {
                                    <section class="py-1">
                                        <div class="d-flex justify-content-center">
                                            <section class="text-white bg-text-link px-1"
                                                style="border-radius: 50rem; padding-bottom: 2px;">
                                                <small>{{ getLabelDate(gifcome.generatedAt) | date:
                                                    getFormatDate(getLabelDate(gifcome.generatedAt)) }}</small>
                                            </section>
                                        </div>
                                    </section>
                                    }

                                    <section>
                                        <div class="card card-body py-1 border-0 bg-perl-1">
                                            <div class="row gy-50">
                                                <div class="d-flex justify-content-between">
                                                    {{ gifcome.nameFrom }}

                                                    <p>{{ convert(gifcome.generatedAt) | date: 'HH:mm' }}</p>
                                                </div>

                                                <div>
                                                    {{ gifcome.comment }}

                                                    @if(!checkJapanese(gifcome.comment)) {
                                                    @if(gifcome.commentTranslated) {
                                                    <p>
                                                        <small>翻訳: {{ gifcome.commentTranslated }}</small>
                                                    </p>
                                                    }
                                                    @else {
                                                    <br>
                                                    <button class="btn btn-link btn-sm" style="padding: 0" type="button"
                                                        (click)="translate(gifcome)">
                                                        翻訳
                                                    </button>
                                                    }
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    }
                                </div>
                            </section>
                            }

                        </div>
                    </section>

                </div>
            </section>

        </div>
    </div>
</section>