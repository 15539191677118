import { Component } from '@angular/core';
import { ChatListComponent } from "../chat-list/chat-list.component";
import { FollowingListComponent } from "../following-list/following-list.component";
import { WidgetGifchaListUnreadComponent } from "../../components/widget-gifcha-list-unread/widget-gifcha-list-unread.component";
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-dashboard-user',
  standalone: true,
  templateUrl: './dashboard-user.component.html',
  styleUrl: './dashboard-user.component.scss',
  imports: [ChatListComponent, FollowingListComponent, WidgetGifchaListUnreadComponent
    , RouterLink
  ]
})
export class DashboardUserComponent {

}
