<div class="card card-body">
    <div class="d-flex justify-content-between">
        <section>
            <h5 class="mb-0">
                収益の合計額
            </h5>
        </section>

        <section>
            <div class="text-end">
                <div>
                    <div class="fs-1">
                        @if(balance !== undefined && balance > 0) {
                        <count-up [countUp]="balance" [options]="{duration:0.625}"></count-up>
                        }
                        @else {
                        <span>0</span>
                        }
                        <span class="fs-6 icon-suffix">円</span>
                    </div>
                </div>

                <div class="d-flex justify-content-end">
                    <button class="btn btn-link px-0 d-flex align-items-center gap-25" type="button"
                        (click)="redirectToDashboard()">
                        詳細を確認
                        <!-- <i class="fa-solid fa-external-link fa-xs"></i> -->
                    </button>
                </div>
            </div>
        </section>
    </div>
</div>