import { Component, Input } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { Account } from '../../interfaces/account';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DecimalPipe } from '@angular/common';
import { CatalogGifcomeComponent } from "../../components/catalog-gifcome/catalog-gifcome.component";

@Component({
    selector: 'app-modal-gifcome-create',
    standalone: true,
    templateUrl: './modal-gifcome-create.component.html',
    styleUrl: './modal-gifcome-create.component.scss',
    imports: [ButtonCloseModalComponent, FormsModule, ReactiveFormsModule, DecimalPipe, CatalogGifcomeComponent]
})
export class ModalGifcomeCreateComponent {

    @Input() accountTarget: Account | undefined;
    @Input() isGifcomePrivate: boolean = false;
    @Input() uidFor: string | undefined;

}
