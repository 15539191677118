<section class="py-3">
    <div class="container">
        <div class="content row gy-2">
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>ポイントの追加履歴</h5>
                            <p>
                                領収書は追加完了時に自動的に発行され、あなたのメールアドレス宛に送信されています。「{{ user?.email
                                }}」のGメールの受信箱を確認してください。「ギフコメの領収書」と検索すると便利です。
                            </p>
                        </div>

                        <div>
                            <div class="table table-sm">
                                <thead>
                                    <tr>
                                        <th scope="col">日時</th>
                                        <th scope="col">ポイント(pts)</th>
                                        <th scope="col">金額(円)</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    @for(charge of charges; track $index) {
                                    <tr>
                                        <td>
                                            {{ convert(charge.generatedAt) | date: 'yyyy/MM/dd HH:mm:ss' }}
                                        </td>

                                        <td class="text-end">
                                            {{ charge.point | number: '.0-0' }}
                                        </td>

                                        <td class="text-end">
                                            {{ charge.price | number: '.0-0'}}
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>