<div class="modal fade" id="modalPreviewImage" tabindex="-1" aria-labelledby="labelModalPreviewImage"
    aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header justify-content-end border-0 fixed-top">
                <!-- <div class="text-white">
                    プレビュー
                </div> -->

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body d-flex align-items-center justify-content-center" style="padding: 0">
                <img [src]="urlImage" alt="Image" style="max-height: 100%; max-width: 100%;">
            </div>
        </div>
    </div>
</div>