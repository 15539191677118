<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>アカウント情報</h5>
                            <p>
                                この情報は他のユーザーに公開されます。
                            </p>
                        </div>

                        <div>
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <th scope="row">アカウント種別</th>
                                        <td>{{ account?.role }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">名前</th>
                                        <td>{{ account?.name }}</td>
                                    </tr>

                                    @if(account?.role !== 'USER') {
                                    <tr>
                                        <th scope="row">アナウンス</th>
                                        <td>{{ account?.announce }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">紹介文</th>
                                        <td>{{ account?.description }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">活動サイト</th>
                                        <td>
                                            <a [href]="account?.url" target="_blank">{{ account?.url }}</a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">アイコン</th>
                                        <td>
                                            <img class="rounded-circle"
                                                [src]="account !== undefined && account.icon? account.icon: '/assets/images/icons/profile/default.png'"
                                                alt="アイコン"
                                                style="width: 2rem;">
                                        </td>
                                    </tr>
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <app-modal-account-update></app-modal-account-update>
                            <div class="d-flex">
                                <div>
                                    <button class="btn btn-main rounded-pill" type="button" data-bs-toggle="modal"
                                        data-bs-target="#modalAccountUpdate">
                                        <i class="fa-solid fa-pen-to-square"></i>
                                        アカウント情報を編集
                                    </button>
                                </div>

                                @if(account?.role === 'CREATOR') {
                                <div>
                                    <a class="btn btn-link" role="button" style="margin-left: 0.5rem"
                                        [routerLink]="['/', 'p', user?.uid]">
                                        公開ページを表示
                                    </a>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>ユーザー情報</h5>
                            <p>
                                この情報は他のユーザーには公開されません。
                            </p>
                        </div>

                        <div>
                            <table class="table table-sm">
                                <tbody>
                                    <tr>
                                        <th scope="row">ID</th>
                                        <td>{{ user?.uid }}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">メールアドレス</th>
                                        <td>{{ user?.email }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div>
                            <p>
                                この情報はログイン中のGoogleアカウントに紐づいているため、変更できません。
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            @if(account?.role !== 'USER') {
            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>申請内容</h5>
                            <p>
                                この情報は収益化のために入力が必須です。
                            </p>
                        </div>

                        <div>
                            <app-modal-registration-update
                                [registration]="registration"></app-modal-registration-update>
                            <button class="btn btn-main rounded-pill" type="button" data-bs-toggle="modal"
                                data-bs-target="#modalRegistrationUpdate">
                                <i class="fa-solid fa-pen-to-square"></i>
                                申請内容を編集
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            }

            <div>
                <div class="d-flex justify-content-end">
                    <button class="btn btn-link" type="button" (click)="getDeauthenticated()">
                        ログアウト
                    </button>
                </div>
            </div>

        </div>
    </div>
</section>