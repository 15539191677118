@if(accountCreator?.announce) {
<section class="py-50 bg-accent text-white" data-aos="fade-right" data-aos-delay="500">
    <div class="container">
        <div class="content">
            <div>
                <i class="fa-solid fa-bullhorn"></i>
                {{ accountCreator?.announce }}
            </div>
        </div>
    </div>
</section>
}

<section class="py-2 bg-perl-1">
    <div class="container">
        <div class="content row gy-2">

            <div class="d-flex align-items-center justify-content-between">
                <div class="w-100">
                    <div class="row gy-2">
                        <div>
                            <div class="row gy-1">
                                <section>
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h1 class="d-flex align-items-center" style="margin-bottom: 0;">{{
                                            accountCreator?.name }}</h1>

                                        <div>
                                            @if(isAuthenticated) {
                                            <div class="d-flex">
                                                @if(!isFollowing) {
                                                <div>
                                                    <button class="btn btn-main btn-sm" type="button"
                                                        (click)="follow()" [disabled]="isMe">
                                                        <i class="fa-solid fa-user-plus"></i>
                                                        フォロー
                                                    </button>
                                                </div>
                                                }
                                                @else {
                                                <div>
                                                    <button class="btn btn-outline-main btn-sm" type="button"
                                                        (click)="unfollow()" (mouseover)="isHoverButtonFollowing = true"
                                                        (mouseout)="isHoverButtonFollowing = false"
                                                        (click)="isHoverButtonFollowing = false" [disabled]="isMe">
                                                        @if(isHoverButtonFollowing) {
                                                        <i class="fa-solid fa-user-minus"></i>
                                                        フォロー解除
                                                        }
                                                        @else {
                                                        <i class="fa-solid fa-user-check"></i>
                                                        フォロー中
                                                        }
                                                    </button>
                                                </div>
                                                }

                                                <div>
                                                    <div class="dropdown">
                                                        <button class="btn btn-link btn-sm"
                                                            type="button"
                                                            data-bs-toggle="dropdown" aria-expanded="false"
                                                            style="margin-left: 0.5rem;">
                                                            <i class="fa-solid fa-ellipsis"></i>
                                                        </button>

                                                        <ul class="dropdown-menu dropdown-menu-end">
                                                            <li>
                                                                <button class="dropdown-item" type="button"
                                                                    [routerLink]="['/', 'chat', getIdChat()]"
                                                                    [disabled]="!isAuthenticated || isMe">
                                                                    <i class="fa-solid fa-comment-alt"></i>
                                                                    ギフチャに移動
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button class="dropdown-item" type="button"
                                                                    (click)="copyUrlCatalog()">
                                                                    <i class="fa-solid fa-link"></i>
                                                                    プロフィールのURLをコピー
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button class="dropdown-item" type="button"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalPreviewQr">
                                                                    <i class="fa-solid fa-qrcode"></i>
                                                                    プロフィールのQRコードを表示
                                                                </button>
                                                            </li>
                                                            <li>
                                                                <button class="dropdown-item" type="button"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#modalReportCreate">
                                                                    <i class="fa-solid fa-flag"></i>
                                                                    通報
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    @if(!(accountCreatorParent?.isCreatorAllowedToHideAgent &&
                                    accountCreator?.isAgentHidden)) {
                                    <p>
                                        <small>
                                            &#64;{{ accountCreatorParent?.name }}
                                        </small>
                                    </p>
                                    }
                                </section>

                                @if(accountCreator?.description !== undefined && accountCreator?.description !== '') {
                                <section>
                                    <p>{{ accountCreator?.description }}</p>
                                </section>
                                }

                                @if(isModeDevelopment || user?.uid === '3NzxjhfiMnSK01w4qg704UeSW3z1') {
                                <section>
                                    <div class="d-flex gap-50">
                                        <p>
                                            <small>{{ numFollowers | number: '0.0' }} フォロワー</small>
                                        </p>
                                        <p>
                                            <small>{{ numGifcomes | number: '0.0' }} ギフコメ</small>
                                        </p>
                                    </div>
                                </section>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                @if(accountCreator?.icon) {
                <div>
                    <!-- <img [src]="accountCreator?.icon" style="width: 7rem"> -->
                </div>
                }
            </div>

        </div>
    </div>
</section>

<section class="py-2">
    <div class="container">
        <div class="content row gy-2">

            <div>
                <app-catalog-gifcome [accountTarget]="accountCreator" [uidFor]="accountCreator?.id"
                    [isGifcomePrivate]="isGifcomePrivate"></app-catalog-gifcome>
            </div>

        </div>
    </div>
</section>

<app-modal-preview-qr [url]="getUrlCatalogNormal()"></app-modal-preview-qr>
<app-modal-report [uidTarget]="uid"></app-modal-report>