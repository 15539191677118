<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div class="row gy-1">
                    <div>
                        <h5 class="mb-0 d-flex align-items-center gap-25">
                            <!-- <i class=" fa-solid fa-comments fa-sm"></i> -->
                            ギフチャ
                        </h5>
                    </div>

                    <!-- <div>
                        <div class="callout-perl-1">
                            <small>
                                ギフチャの一覧です。ギフコメは<a routerLink="/gifcomes">こちら</a>で確認できます。
                            </small>
                        </div>
                    </div> -->
                </div>
            </section>

            <section>
                <div class="card border-0" style="border-radius: 0;">

                    <div class="list-group list-group-flush">

                        @for(chat of chats; track $index) {
                        <a class="list-group-item list-group-item-action" role="button"
                            [routerLink]="['/', 'chat', chat.id]">
                            <div class="d-flex align-items-center">
                                <div>
                                    <img [src]="getUrlIcon(chat)" style="width:2.0rem">

                                    <div class="position-absolute top-0" style="left: 2.0rem">
                                        <app-badge-number [number]="getLengthUnread(chat)"></app-badge-number>
                                    </div>
                                </div>

                                <div>
                                    <button class="btn btn-link" type="button">{{ getNameTarget(chat) }}</button>
                                </div>
                            </div>
                        </a>
                        }

                    </div>

                </div>
            </section>

        </div>
    </div>
</section>