import { Component } from '@angular/core';
import { ManagerGifcomeService } from '../../services/managers/manager-gifcome/manager-gifcome.service';
import { DatePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { PlanGifcome } from '../../interfaces/plan-gifcome';
import { Gifcome } from '../../interfaces/gifcome';
import { UtilTranslationService } from '../../services/utils/util-translation/util-translation.service';
import { Timestamp } from 'firebase/firestore';
import { UtilTimestampService } from '../../services/utils/util-timestamp/util-timestamp.service';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';

@Component({
  selector: 'app-gifcome-list',
  standalone: true,
  imports: [DatePipe, RouterLink],
  templateUrl: './gifcome-list.component.html',
  styleUrl: './gifcome-list.component.scss'
})
export class GifcomeListComponent {

  get accountMine(): Account | undefined {
    return this.mgrAccount.accountMine;
  }

  get gifcomeSents(): Gifcome[] | undefined {
    return this.managerGifcome.gifcomeSents;
  }

  get gifcomeRecieveds(): Gifcome[] | undefined {
    return this.managerGifcome.gifcomeReceiveds;
  }

  /*** Status ***/
  isModeSent: boolean = true;

  constructor(
    private mgrAccount: ManagerAccountService,
    private managerGifcome: ManagerGifcomeService,
    private utilTimestamp: UtilTimestampService,
    private utilTranslation: UtilTranslationService
  ) { }

  /******************************
   * Methods
   ******************************/

  getPlanGifcome(idPlanGifcome: number): PlanGifcome {
    return this.managerGifcome.getPlanGifcome(idPlanGifcome);
  }

  translate(gifcome: Gifcome): void {
    this.utilTranslation.translateGifcome(gifcome);
  }

  checkJapanese(comment: string): boolean {
    return this.utilTranslation.checkJapanese(comment);
  }

  convert(date: string | Timestamp): string {
    return this.utilTimestamp.convert(date);
  }

  //Showing date and time
  checkDateBorder(idGifcome: string, gifcomes: Gifcome[]): boolean {
    return this.managerGifcome.checkDateBorder(idGifcome, gifcomes);
  }

  getLabelDate(generatedAt: string): string {
    return this.utilTimestamp.getDate(generatedAt);
  }

  getLabelTime(generatedAt: string): string {
    return this.utilTimestamp.toTimestamp(generatedAt).slice(11, 16);
  }

  getFormatDate(generatedAt: string): string {
    return this.utilTimestamp.getFormatDate(generatedAt);
  }
}
