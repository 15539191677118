import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ManagerUserService } from '../../../services/managers/manager-user/manager-user.service';
import { User } from '@angular/fire/auth';
import { UtilUrlService } from '../../../services/utils/util-url/util-url.service';
import { UtilClipboardService } from '../../../services/utils/util-clipboard/util-clipboard.service';

@Component({
  selector: 'app-banner-catalog',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './banner-catalog.component.html',
  styleUrl: './banner-catalog.component.scss'
})
export class BannerCatalogComponent implements OnInit {

  get user(): User | null {
    return this.mgrUser.user;
  }

  private _urlCatalog: string | null = null;

  constructor(
    private mgrUser: ManagerUserService,

    private utilClipboard: UtilClipboardService,
    private utilUrl: UtilUrlService,
  ) { }

  async ngOnInit(): Promise<void> {
    this._urlCatalog = await this.utilUrl.getUrlCatalog(this.user!.uid);
    console.log('id', this._urlCatalog);
  }

  copyUrlCatalog(): void {
    this.utilClipboard.copy(this._urlCatalog!);
  }

  copyUrlId(): void {
    const id = this.toId(this._urlCatalog!);
    this.utilClipboard.copy(id);
  }

  toId(url: string): string {
    const id = url.split('/').pop()!;
    return 'gf' + id.replace('@', '') + 'cm';
  }

}
