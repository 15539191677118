import { Injectable } from '@angular/core';
import { DocumentReference, Firestore, collection, collectionData, doc, docData, query, setDoc, where } from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { StateProcessingService } from '../../states/state-processing/state-processing.service';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { Account } from '../../../interfaces/account';
import { v4 as uuidv4 } from 'uuid';

declare const bootstrap: any;

@Injectable({
  providedIn: 'root'
})
export class ManagerAccountService {

  /******************************
   * Properties
   ******************************/

  private _accountMine?: Account;
  get accountMine(): Account | undefined {
    if (this._accountMine$ === undefined && this.managerUser.user !== null) {
      const ref = doc(this.db, 'account', this.managerUser.user.uid) as DocumentReference<Account | undefined>;
      this._accountMine$ = docData(ref, { idField: 'id' }) as Observable<Account | undefined>;
      this._accountMine$.subscribe((account: Account | undefined) => {
        this._accountMine = account;
      });
    }

    return this._accountMine;
  }
  private _accountMine$?: Observable<Account | undefined>;

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private db: Firestore,

    private managerUser: ManagerUserService,
    private stateProcessing: StateProcessingService,
    private utilNotification: UtilNotificationService
  ) { }

  /******************************
   * Methods
   ******************************/

  updateAccount(account: Account): Promise<void> {
    const pid = this.stateProcessing.start();

    const ref = doc(this.db, 'account', this.managerUser.user!.uid);
    const promise = setDoc(ref, account, { merge: true });

    promise.then(() => {
      this.utilNotification.notify('アカウント情報を更新しました', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('アカウント情報の更新に失敗しました', 'ERROR');
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return promise;
  }

  transfer(uidCreator: string, uidParent: string): Promise<void> {
    const pid = this.stateProcessing.start();

    const ref = doc(this.db, 'account', uidCreator);
    const promise = setDoc(ref, { uidParent: uidParent }, { merge: true });

    promise.then(() => {
      this.utilNotification.notify('アカウント情報を更新しました', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('アカウント情報の更新に失敗しました', 'ERROR');
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return promise;
  }

  getAccount(uid: string): Promise<Account | undefined> {
    const ref = doc(this.db, 'account', uid);
    const data$ = docData(ref, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Account | undefined>;

    return data;
  }

  regenKeyAPi(): Promise<void> {
    const pid = this.stateProcessing.start();

    const ref = doc(this.db, 'account', this.managerUser.user!.uid);
    const promise = setDoc(ref, { keyApi: uuidv4() }, { merge: true });

    promise.then(() => {
      this.utilNotification.notify('APIキーをリセットしました', 'SUCCESS');
    }).catch(() => {
      this.utilNotification.notify('APIキーのリセットに失敗しました', 'ERROR');
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return promise;
  }

  onboardAccountName(): void {
    const interval = setInterval(() => {
      if (this.managerUser.user === null) return;
      clearInterval(interval);

      const ref = doc(this.db, 'account', this.managerUser.user!.uid) as DocumentReference<Account>;
      const data$ = docData(ref, { idField: 'id' }) as Observable<Account | undefined>;
      const subscription = data$.subscribe((account: Account | undefined) => {
        if (account === undefined) return;

        if (account.name === undefined || account.name === '') {
          this.showModalAccountCreate();
        }
        else {
          subscription.unsubscribe();
        }
      });
    }, 0.1 * 1000);
  }

  showModalAccountCreate(): void {
    const modal = document.getElementById('modalAccountCreate');
    if (modal === null) return;

    const option = {
      backdrop: 'static',
      keyboard: false
    };
    new bootstrap.Modal(modal, option).show();
  }

  getAgentParents(): Promise<Account[]> {
    const ref = collection(this.db, 'account');
    const q = query(ref, where('role', '==', 'AGENT_PARENT'));
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Account[]>;

    return data;
  }

  getAgentChilds(): Promise<Account[]> {
    const ref = collection(this.db, 'account');
    const q = query(ref, where('role', '==', 'AGENT_CHILD'));
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Account[]>;

    return data;
  }

  getCreators(): Promise<Account[]> {
    const ref = collection(this.db, 'account');
    const q = query(ref, where('role', '==', 'CREATOR'));
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Account[]>;

    return data;
  }

}
