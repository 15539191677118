import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-badge-number',
  standalone: true,
  imports: [],
  templateUrl: './badge-number.component.html',
  styleUrl: './badge-number.component.scss'
})
export class BadgeNumberComponent {

  @Input() number: number = 0;

}
