<div class="modal fade" id="modalGifcomeCreate" tabindex="-1" aria-labelledby="labelModalGifcomeCreate"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalGifcomeCreate">{{accountTarget?.name}}に贈る</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body">
                <app-catalog-gifcome [accountTarget]="accountTarget" [uidFor]="uidFor"
                    [isGifcomePrivate]="isGifcomePrivate"></app-catalog-gifcome>
            </div>
        </div>
    </div>
</div>