@if(chatsUnread?.length === 0) {
<p>0件</p>
}

<div class="list-group list-group-flush">

    @for(chat of chatsUnread; track $index) {
    <a class="list-group-item list-group-item-action" role="button" [routerLink]="['/', 'chat', chat.id]">
        <div class="d-flex align-items-center">
            <div>
                <img [src]="getUrlIcon(chat)" style="width:2.0rem">

                <div class="position-absolute top-0" style="left: 2.0rem">
                    <app-badge-number [number]="getLengthUnread(chat)"></app-badge-number>
                </div>
            </div>

            <div>
                <button class="btn btn-link" type="button">{{ getNameTarget(chat) }}</button>
            </div>
        </div>
    </a>
    }

</div>