<section class="py-3">
    <div class="container">
        <div class="row gy-2">

            <section>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <p>
                                ギフコメにログインするには、下記のボタンをクリックします。
                            </p>
                        </div>

                        <div>
                            <button class="btn btn-main rounded-pill" type="button" (click)="signIn()">
                                ログイン
                            </button>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>