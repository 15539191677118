<section class="py-3">
    <div class="container">
        <div class="content">
            <section>
                <h6>未読のギフチャ</h6>

                <div>
                    <app-widget-gifcha-list-unread></app-widget-gifcha-list-unread>
                </div>
                <div>
                    <a role="button" routerLink="/chats">すべて表示</a>
                </div>
            </section>
        </div>
    </div>
</section>

<div class="container">
    <hr>
</div>

<app-following-list></app-following-list>