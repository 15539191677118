<div class="modal fade" id="modalReportCreate" tabindex="-1" aria-labelledby="labelModalReportCreate"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalReportCreate">通報</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body">
                <form class="row gy-2" [formGroup]="formReport">
                    <!-- <p>
                        プロフィールに不適切な表現が含まれている場合、通報のご協力をお願いします。
                    </p> -->

                    <div class="col-12">
                        <label for="report" class="form-label">通報内容</label>
                        <textarea type="text" class="form-control" id="report" formControlName="report"></textarea>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button class="btn btn-main rounded-pill" type="button" data-bs-dismiss="modal"
                    [disabled]="formReport.invalid"
                    (click)="createReport()">送信</button>
            </div>
        </div>
    </div>
</div>