import { Component, OnInit } from '@angular/core';
import { ManagerPushNotifService } from '../../services/managers/manager-push-notif/manager-push-notif.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss'
})
export class SettingsComponent implements OnInit {

  private _subscsPushNotif: any[] = [];
  get subscsPushNotif(): any[] {
    return this._subscsPushNotif;
  }

  get isPushNotifSubscribed(): boolean {
    const subsc = this.subscsPushNotif.find(subsc => subsc.data.endpoint === this.mgrPushNotif.subscToThisApp?.endpoint);
    return subsc !== undefined;
  }

  constructor(
    private mgrPushNotif: ManagerPushNotifService,

    private stateProcessing: StateProcessingService
  ) { }

  async ngOnInit(): Promise<void> {
    this._subscsPushNotif = await this.mgrPushNotif.gets();
  }

  subscribePushNotification(): void {
    const pid = this.stateProcessing.start();

    this.mgrPushNotif.subscribe().then(async () => {
      this._subscsPushNotif = await this.mgrPushNotif.gets();
      this.stateProcessing.end(pid);
    });
  }

  unsubscribePushNotif(): void {
    this.mgrPushNotif.unsubscribe(this.subscsPushNotif);
  }

}
