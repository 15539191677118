import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';
import { ModalGifcomeCreateComponent } from "../../modals/modal-gifcome-create/modal-gifcome-create.component";
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { User } from '@angular/fire/auth';
import { ModalAccountUpdateComponent } from "../../modals/modal-account-update/modal-account-update.component";
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';
import { CatalogGifcomeComponent } from "../../components/catalog-gifcome/catalog-gifcome.component";
import { PageNotFoundComponent } from "../page-not-found/page-not-found.component";
import { UtilClipboardService } from '../../services/utils/util-clipboard/util-clipboard.service';
import { UtilUrlService } from '../../services/utils/util-url/util-url.service';
import { ManagerFollowingService } from '../../services/managers/manager-following/manager-following.service';
import { LinkFollowing } from '../../interfaces/link-following';
import { ModalPreviewQrComponent } from "../../modals/modal-preview-qr/modal-preview-qr.component";
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { SpinnerComponent } from "../../components/spinner/spinner.component";
import { ManagerGifcomeService } from '../../services/managers/manager-gifcome/manager-gifcome.service';
import { Gifcome } from '../../interfaces/gifcome';
import { DecimalPipe } from '@angular/common';
import { ENVIRONMENT } from 'settings/environments/environment.development';
import { ManagerStripeConnectedService } from '../../services/managers/manager-stripe-connected/manager-stripe-connected.service';
import { ModalReportComponent } from "../../modals/modal-report/modal-report.component";

@Component({
  selector: 'app-catalog',
  standalone: true,
  templateUrl: './catalog.component.html',
  styleUrl: './catalog.component.scss',
  imports: [
    ModalGifcomeCreateComponent,
    ModalAccountUpdateComponent,
    CatalogGifcomeComponent,
    PageNotFoundComponent,
    ModalPreviewQrComponent,
    SpinnerComponent,
    RouterLink,
    DecimalPipe,
    ModalReportComponent
  ]
})
export class CatalogComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  get user(): User | null {
    return this.managerUser.user;
  }

  private _uid: string;
  get uid(): string {
    return this._uid;
  }

  private _accountCreator: Account | undefined;
  get accountCreator(): Account | undefined {
    return this._accountCreator
  }

  private _accountCreatorParent: Account | undefined;
  get accountCreatorParent(): Account | undefined {
    return this._accountCreatorParent;
  }

  private _linkFollowings?: LinkFollowing[];
  get linkFollowings(): LinkFollowing[] | undefined {
    return this._linkFollowings;
  }

  private _linkFollowers?: LinkFollowing[];
  get linkFollowers(): LinkFollowing[] | undefined {
    return this._linkFollowers;
  }

  get numFollowers(): number {
    return (this.linkFollowers !== undefined) ? this.linkFollowers.length : 0;
  }

  private _gifcomes?: Gifcome[];
  get gifcomes(): Gifcome[] | undefined {
    return this._gifcomes;
  }

  get numGifcomes(): number {
    return (this.gifcomes !== undefined) ? this.gifcomes.length : 0;
  }

  get numGifcomesPrivates(): number {
    return (this.gifcomes !== undefined) ? this.gifcomes.filter(gifcome => gifcome.isGifcomePrivate).length : 0;
  }

  /*** Status ***/

  get isAuthenticated(): boolean {
    return this.managerAuth.isAuthenticated;
  }

  get isFollowing(): boolean {
    return this.managerFollowing.checkFollowing(this.uid, this.linkFollowings);
  }

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  get isModeDevelopment(): boolean {
    return ENVIRONMENT.MODE === 'DEVELOPMENT';
  }

  get isMe(): boolean {
    return this.accountCreator?.id === this.user?.uid;
  }

  isHoverButtonFollowing: boolean = false;
  isGifcomePrivate: boolean = false;

  private _accountStripeConnectedCreator?: any;
  get accountStripeConnectedCreator(): any | undefined {
    return this._accountStripeConnectedCreator;
  }

  get isStripeConnectedSubmitted(): boolean {
    const result = this.managerStripeConnected.checkSubmitted(this.accountStripeConnectedCreator);
    return result;
  }

  get isStripeConnectedDisabled(): boolean {
    const result = this.managerStripeConnected.checkDisabled(this.accountStripeConnectedCreator);
    return result;
  }


  private _urlCatalog: string | null = null;

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private managerAuth: ManagerAuthService,
    private managerFollowing: ManagerFollowingService,
    private managerGifcome: ManagerGifcomeService,
    private managerStripeConnected: ManagerStripeConnectedService,
    private stateProcessing: StateProcessingService,
    private utilClipboard: UtilClipboardService,
    private utilUrl: UtilUrlService
  ) {
    const uid = this.route.snapshot.paramMap.get('uid');
    if (uid === null) {
      throw new Error('uid is required');
    }
    this._uid = uid;

    const pvt = this.route.snapshot.queryParamMap.get('pvt');
    if (pvt) {
      this.isGifcomePrivate = (pvt === 'true');
    }
  }

  async ngOnInit() {
    this._accountCreator = await this.managerAccount.getAccount(this.uid);
    if (!this.accountCreator) return;
    const response = await this.managerStripeConnected.getStripeConnected(this.accountCreator.idStripeConnected!);
    this._accountStripeConnectedCreator = response.accountStripeConnected;
    this._accountCreatorParent = await this.managerAccount.getAccount(this.accountCreator.uidParent!);

    //check availability
    if (this.accountCreator.role === 'CREATOR' && this.isStripeConnectedSubmitted && !this.isStripeConnectedDisabled) {
    }
    else {
      alert('現在このユーザーは準備中のためトップページに移動します');
      this.router.navigate(['/']);
    }


    this._linkFollowers = await this.managerFollowing.getLinkFollowers(this.uid);
    const intv = setInterval(async () => {
      if (this.managerUser.user === null) return;
      clearInterval(intv);

      this._linkFollowings = await this.managerFollowing.getLinkFollowings(this.managerUser.user.uid);
    }, 0.1 * 1000);

    //クリエイター宛のギフコメだけ取得ができない。
    this.managerGifcome.getGifcomes(this.uid).subscribe((gifcomes) => {
      this._gifcomes = gifcomes.filter(gifcome => gifcome.point > 0);
      //console.log('gifcomes', gifcomes);
      // console.log('start');
      for (let gifcome of gifcomes) {
        // console.log('gifcome', gifcome.uidTo);
      }
      // console.log('end');
    });

    this._urlCatalog = await this.utilUrl.getUrlCatalog(this.uid);
  }

  copyUrlCatalog(): void {
    this.utilClipboard.copy(this._urlCatalog!);
  }

  getUrlCatalogNormal(): string {
    return this.utilUrl.getUrlCatalogNormal(this.uid);
  }

  follow(): void {
    this.managerFollowing.follow(this.uid, this.linkFollowings, this.linkFollowers);
  }

  unfollow(): void {
    this.managerFollowing.unfollow(this.uid, this.linkFollowings!, this.linkFollowers!);
  }

  getIdChat(): string {
    return this.uid + '-' + this.user!.uid;
  }

  signOut(): void {
    this.managerAuth.signOut();
  }

}
