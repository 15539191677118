import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ManagerChatService } from '../../services/managers/manager-chat/manager-chat.service';
import { Gifcome } from '../../interfaces/gifcome';
import { Account } from '../../interfaces/account';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { BadgeNumberComponent } from "../../components/badge-number/badge-number.component";


@Component({
  selector: 'app-chat-list',
  standalone: true,
  templateUrl: './chat-list.component.html',
  styleUrl: './chat-list.component.scss',
  imports: [RouterLink, BadgeNumberComponent]
})
export class ChatListComponent {

  get idsChat(): string[] | undefined {
    return this.managerChat.idsChat;
  }

  get chats(): any[] | undefined {
    return this.managerChat.chats;
  }

  get unreads(): Gifcome[] | undefined {
    return this.managerChat.unreads;
  }

  private uids: string[] = [];

  private _accounts: Account[] = [];
  get accounts(): Account[] {
    return this._accounts;
  }

  constructor(
    private managerAccount: ManagerAccountService,
    private managerChat: ManagerChatService,
    private stateProcessing: StateProcessingService
  ) { }

  getUidTarget(chat: any): string {
    return this.managerChat.getUidTarget(chat);
  }

  getNameTarget(chat: any): string {
    const account = this.getAccount(this.getUidTarget(chat));
    if (account === undefined) return '';

    return account.name === undefined ? '' : account.name;
  }

  getUrlIcon(chat: any): string {
    const account = this.getAccount(this.getUidTarget(chat));
    if (account === undefined) return '/assets/images/icons/profile/default.png';

    return account.icon === undefined || account.icon === '' ? '/assets/images/icons/profile/default.png' : account.icon;
  }

  getLengthUnread(chat: any): number {
    if (this.unreads === undefined) return 0; //OK?

    return this.unreads.filter(unread => unread.idChat === chat.id).length;
  }

  getAccount(uid: string): Account | undefined {
    const account = this.accounts.find(account => account.id === uid);
    if (account === undefined) {
      if (this.uids.indexOf(uid) === -1) {
        this.uids.push(uid);

        this.managerAccount.getAccount(uid).then(account => {
          if (account === undefined) return;

          this._accounts.push(account);
        });
      }
    }
    return account;
  }

}
