<section class="py-5">
    <div class="container">
        <div class="content row gy-3">

            <section>
                <div class="card">
                    <div class="card-body">
                        <div class="row gy-2">
                            <section>
                                <h5>プッシュ通知</h5>
                                <p>
                                    通知はアプリ/ブラウザ単位で設定されます。(アカウント単位ではありません)
                                </p>
                            </section>

                            @if(!isPushNotifSubscribed) {
                            <section>
                                <div>
                                    <button class="btn btn-main rounded-pill" type="button"
                                        (click)="subscribePushNotification()">
                                        このアプリ/ブラウザで通知をオン
                                    </button>
                                </div>
                            </section>
                            }

                            @if(isPushNotifSubscribed) {
                            <section>
                                <div>
                                    <button class="btn btn-main rounded-pill" type="button"
                                        (click)="unsubscribePushNotif()">
                                        このアプリ/ブラウザで通知を解除
                                    </button>
                                </div>
                            </section>
                            }

                        </div>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>