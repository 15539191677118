import { Injectable } from '@angular/core';
import { signInWithPopup, signOut, Auth, GoogleAuthProvider, UserCredential, authState, signInWithRedirect } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { StateProcessingService } from '../../states/state-processing/state-processing.service';
import { first, firstValueFrom } from 'rxjs';
import { ManagerAccountService } from '../manager-account/manager-account.service';
import { ManagerUserService } from '../manager-user/manager-user.service';
import { ManagerPushNotifService } from '../manager-push-notif/manager-push-notif.service';


@Injectable({
  providedIn: 'root'
})
export class ManagerAuthService {

  /******************************
   * Properties
   ******************************/

  get isAuthenticated(): boolean {
    return this.auth.currentUser !== null;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private router: Router,

    private auth: Auth,

    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private mgrPushNotif: ManagerPushNotifService,


    private stateProcessing: StateProcessingService,
    private utilNotification: UtilNotificationService
  ) {
    const pid = this.stateProcessing.start();

    const state$ = authState(this.auth);
    const state = firstValueFrom(state$);
    state.finally(() => {
      this.stateProcessing.end(pid);
    });
  }

  /******************************
   * Methods
   ******************************/
  redirectToHome(): void {

  }


  async signIn(url?: string): Promise<UserCredential> {
    return this.signInWithRedirect(url);
  }

  // async signInWithPopup(url?: string): Promise<UserCredential> {
  //   const response = signInWithPopup(this.auth, new GoogleAuthProvider());

  //   response.then(async (credential: UserCredential | null) => {
  //     this.utilNotification.notify('ログインしました', 'SUCCESS');

  //     if (url !== undefined) {
  //       this.router.navigateByUrl(url);
  //     }

  //     window.location.reload();
  //   }).catch(() => {
  //     this.utilNotification.notify('ログインに失敗しました', 'ERROR');
  //   });

  //   return response;
  // }

  async signInWithRedirect(url?: string): Promise<UserCredential> {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      // プッシュ通知を使うアプリではアカウント選択はNG
      // 通知の購読がアプリに紐づいているため、切り替えるとアカウントがごちゃごちゃになる
      // もしやりたい場合は、ログアウト時に通知の購読を削除
      prompt: 'select_account'
    });
    const response = signInWithRedirect(this.auth, provider);

    response.then(async (credential: UserCredential | null) => {
      this.utilNotification.notify('ログインしました', 'SUCCESS');

      // doesn't work. because of the redirect
      // if (url !== undefined) {
      //   this.router.navigateByUrl(url);
      // }
    }).catch(() => {
      this.utilNotification.notify('ログインに失敗しました', 'ERROR');
    });

    return response;
  }

  async signOut(): Promise<void> {
    const subscs = await this.mgrPushNotif.gets();

    const response = signOut(this.auth);
    response.then(async () => {
      await this.mgrPushNotif.unsubscribe(subscs);

      const url = this.isExtension() ? '/login' : '/';

      this.router.navigate([url]).then(() => {
        window.location.reload();
      });
    });

    return response;
  }

  isExtension(): boolean {
    return window.location.href.includes('extension');
  }

}
