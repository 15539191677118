import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { and, collection, collectionData, doc, or, orderBy, query, setDoc, where, Firestore, getDocsFromServer, getDocs, collectionChanges } from '@angular/fire/firestore';
import { getDownloadURL, ref, uploadBytesResumable, Storage } from '@angular/fire/storage';
import { firstValueFrom, map, Observable } from 'rxjs';
import { ManagerAccountService } from '../manager-account/manager-account.service';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { StateProcessingService } from '../../states/state-processing/state-processing.service';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { UtilTimestampService } from '../../utils/util-timestamp/util-timestamp.service';
import { Gifcome } from '../../../interfaces/gifcome';
import { PlanGifcome } from '../../../interfaces/plan-gifcome';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ManagerGifcomeService {

  /******************************
   * Properties
   ******************************/

  readonly planGifcomes: PlanGifcome[] = [
    {
      id: 0,
      name: 'りんご',
      label: '🍎',
      urlIcon: '/assets/images/icons/gifcome/apple.png',
      point: 50,
      capacity: 5 * 2,
      isAttachable: false
    } as PlanGifcome,
    {
      id: 1,
      name: 'キッス',
      label: '💋',
      urlIcon: '/assets/images/icons/gifcome/kiss.png',
      point: 100,
      capacity: 8 * 2,
      isAttachable: false
    } as PlanGifcome,
    {
      id: 2,
      name: 'ケーキ',
      label: '🎂',
      urlIcon: '/assets/images/icons/gifcome/cake.png',
      point: 500,
      capacity: 20 * 2,
      isAttachable: false
    } as PlanGifcome,
    {
      id: 3,
      name: '花火',
      label: '🎆',
      urlIcon: '/assets/images/icons/gifcome/firework.png',
      point: 1000,
      capacity: 40 * 2,
      isAttachable: true
    } as PlanGifcome,
    {
      id: 4,
      name: '花束',
      label: '💐',
      urlIcon: '/assets/images/icons/gifcome/bouquet.png',
      point: 2000,
      capacity: 60 * 2,
      isAttachable: true
    } as PlanGifcome,
    {
      id: 5,
      name: 'シャンパン',
      label: '🍾',
      urlIcon: '/assets/images/icons/gifcome/champagne.png',
      point: 10000,
      capacity: 140 * 2,
      isAttachable: true
    } as PlanGifcome,
    {
      id: 6, //無料返信用のデータ
      name: 'プレゼント',
      label: '💌',
      urlIcon: '/assets/images/icons/gifcome/present.svg',
      point: 0,
      capacity: 140 * 2,
      isAttachable: true
    } as PlanGifcome,
    {
      id: 7,
      name: 'タワー',
      label: '🗼',
      urlIcon: '/assets/images/icons/gifcome/tower.png',
      point: 30000,
      capacity: 170 * 2,
      isAttachable: true
    } as PlanGifcome,
    {
      id: 8,
      name: 'お城',
      label: '🏰',
      urlIcon: '/assets/images/icons/gifcome/castle.png',
      point: 50000,
      capacity: 220 * 2,
      isAttachable: true
    } as PlanGifcome
  ];

  private _gifcomesSent?: Gifcome[];
  get gifcomeSents(): Gifcome[] | undefined {
    if (this._gifcomesSent$ === undefined && this.managerUser.user !== null) {
      const ref = collection(this.db, 'gifcome');
      const q = query(ref,
        where('uidFrom', '==', this.managerUser.user.uid),
        where('isGifcomePrivate', '==', false),
        orderBy('generatedAt', 'desc')
      );
      this._gifcomesSent$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;
      this._gifcomesSent$.subscribe((gifcomes: Gifcome[]) => {
        this._gifcomesSent = gifcomes;
      });
    }

    return this._gifcomesSent;
  }
  private _gifcomesSent$?: Observable<Gifcome[]>;

  private _gifcomesReceived?: Gifcome[];
  get gifcomeReceiveds(): Gifcome[] | undefined {
    if (this._gifcomesReceived$ === undefined && this.managerUser.user !== null) {
      const ref = collection(this.db, 'gifcome');
      const q = query(ref,
        where('uidTo', '==', this.managerUser.user?.uid),
        where('isGifcomePrivate', '==', false),
        orderBy('generatedAt', 'desc')
      );
      this._gifcomesReceived$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;
      this._gifcomesReceived$.subscribe((gifcomes: Gifcome[]) => {
        this._gifcomesReceived = gifcomes;
      });
    }

    return this._gifcomesReceived;
  }
  private _gifcomesReceived$?: Observable<Gifcome[]>;

  private _gifcomesPrivate?: Gifcome[];
  get gifcomesPrivate(): Gifcome[] | undefined {
    if (this._gifcomesPrivate$ === undefined && this.managerUser.user !== null) {
      const ref = collection(this.db, 'gifcome');
      const q = query(ref,
        and(
          where('isGifcomePrivate', '==', true),
          or(
            where('uidFrom', '==', this.managerUser.user.uid),
            where('uidTo', '==', this.managerUser.user.uid),
          )
        ),
        orderBy('generatedAt', 'desc')
      );
      this._gifcomesPrivate$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;
      this._gifcomesPrivate$.subscribe((gifcomes: Gifcome[]) => {
        this._gifcomesPrivate = gifcomes;
      });
    }

    return this._gifcomesPrivate;
  }
  private _gifcomesPrivate$?: Observable<Gifcome[]>;

  /******************************
   * Lifecycle hooks
   ******************************/

  constructor(
    private http: HttpClient,

    private db: Firestore,
    private storage: Storage,

    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private utilNotification: UtilNotificationService,
    private utilTimestamp: UtilTimestampService,
    private stateProcessing: StateProcessingService
  ) { }

  /******************************
   * Methods
   ******************************/

  createGifcome(
    nameFrom: string,
    nameTo: string,

    uidFrom: string,
    uidTo: string,
    uidFor: string,
    uidUser: string,
    uidCreator: string,

    isGifcomePrivate: boolean,
    idPlanGifcome: number,
    point: number,
    comment: string,
    urlAttachment: string | undefined,

    idStripeConnectedAdmin: string,
    idStripeConnectedAgentParent: string,
    idStripeConnectedAgentChild: string | undefined,
    idStripeConnectedCreator: string,

    isIndividualAgentParent: boolean,
    isIndividualAgentChild: boolean | undefined,
    isIndividualCreator: boolean
  ): Promise<Object> {
    const pid = this.stateProcessing.start();

    const urlFunction = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/createGifcome';
    const params = {
      uidFrom: uidFrom,
      uidTo: uidTo,
      uidFor: uidFor,
      nameFrom: nameFrom,
      nameTo: nameTo,
      roleFrom: this.managerAccount.accountMine!.role,

      idChat: uidCreator + '-' + uidUser,

      isGifcomePrivate: isGifcomePrivate,
      idPlanGifcome: idPlanGifcome,
      point: point,
      comment: comment,

      idStripeConnectedAdmin: idStripeConnectedAdmin,
      idStripeConnectedAgentParent: idStripeConnectedAgentParent,
      idStripeConnectedCreator: idStripeConnectedCreator,

      isIndividualAgentParent: isIndividualAgentParent,
      isIndividualCreator: isIndividualCreator,

      isModeAffiliate: (idStripeConnectedAgentChild !== undefined)
    } as Gifcome;
    if (urlAttachment !== undefined) {
      params.urlAttachment = urlAttachment;
    }
    if (idStripeConnectedAgentChild !== undefined) {
      params.idStripeConnectedAgentChild = idStripeConnectedAgentChild;
      params.isIndividualAgentChild = isIndividualAgentChild;
    }
    console.log(params);

    const response$ = this.http.post(urlFunction, JSON.stringify(params));
    const response = firstValueFrom(response$);

    response.then(() => {
      const label = isGifcomePrivate ? 'ギフチャ' : 'ギフコメ';
      this.utilNotification.notify(label + 'を贈りました。', 'SUCCESS');
    }).catch((error: any) => {
      this.utilNotification.notify('ギフコメの送信に失敗しました。\n\n' + error.message, 'ERROR');
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return response;
  }

  updateGifcome(idGifcome: string, gifcome: Gifcome): Promise<void> {
    const ref = doc(this.db, 'gifcome', idGifcome);
    const promise = setDoc(ref, gifcome, { merge: true }) as Promise<void>;

    return promise;
  }

  getGifcomes(uid: string): Observable<Gifcome[]> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('uidTo', '==', uid)
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;

    return data$;
  }

  getPlanGifcome(idPlanGifcome: number): PlanGifcome {
    return this.planGifcomes[idPlanGifcome];
  }

  async uploadImage(file: File): Promise<string> {
    const pid = this.stateProcessing.start();

    const timestamp = this.utilTimestamp.generateTimestamp();
    const uid = this.managerUser.user!.uid;
    const extension = file.name.replace(/^.*\./, '');
    const storageRef = ref(this.storage, `images/${timestamp}-${uid}.${extension}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed', (snapshot: any) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log(`Upload is ${progress}% done`);
    }, (error: any) => {
      console.log(error);
    }, () => { });
    await uploadTask;

    const response = getDownloadURL(uploadTask.snapshot.ref);
    response.finally(() => {
      this.stateProcessing.end(pid);
    });

    return response;
  }

  isMine(gifcome: Gifcome): boolean {
    return gifcome.uidFrom === this.managerUser.user!.uid;
  }

  getGifcomesLastMonth(): Observable<Gifcome[]> {
    const today = new Date();
    let n = 0; //-1:今月、0:先月、1:先々月
    if (ENVIRONMENT.MODE === 'PRODUCTION') {
      n = 0;
    }
    const endAt = new Date(today.getFullYear(), today.getMonth() - n, 1);
    const startAt = new Date(endAt.getFullYear(), endAt.getMonth() - 1, 1);

    // console.log('startAt', startAt, this.utilTimestamp.toTimestamp(startAt.toISOString()));
    // console.log('endAt', endAt, this.utilTimestamp.toTimestamp(endAt.toISOString()));

    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      or(
        and(
          where('generatedAt', '>=', this.utilTimestamp.toTimestamp(startAt.toISOString())),
          where('generatedAt', '<', this.utilTimestamp.toTimestamp(endAt.toISOString()))
        ),
        and(
          where('generatedAt', '>=', startAt),
          where('generatedAt', '<', endAt)
        )
      )
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;
    return data$;
  }

  async getGifcomesLastMonth1(): Promise<Gifcome[]> {
    const today = new Date();
    let n = 0; //-1:今月、0:先月、1:先々月
    if (ENVIRONMENT.MODE === 'PRODUCTION') {
      n = 0;
    }
    const endAt = new Date(today.getFullYear(), today.getMonth() - n, 1);
    const startAt = new Date(endAt.getFullYear(), endAt.getMonth() - 1, 1);

    console.log('startAt', startAt, this.utilTimestamp.toTimestamp(startAt.toISOString()));
    console.log('endAt', endAt, this.utilTimestamp.toTimestamp(endAt.toISOString()));

    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('generatedAt', '>=', this.utilTimestamp.toTimestamp(startAt.toISOString())),
      where('generatedAt', '<', this.utilTimestamp.toTimestamp(endAt.toISOString()))
    );
    // const data$ = collectionData(q, { idField: 'id' });
    // const data = firstValueFrom(data$) as Promise<Gifcome[]>;
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Gifcome[];

    return data;
  }

  async getGifcomesLastMonth2(): Promise<Gifcome[]> {
    const today = new Date();
    let n = 0; //-1:今月、0:先月、1:先々月
    if (ENVIRONMENT.MODE === 'PRODUCTION') {
      n = 0;
    }
    const endAt = new Date(today.getFullYear(), today.getMonth() - n, 1);
    const startAt = new Date(endAt.getFullYear(), endAt.getMonth() - 1, 1);

    console.log('startAt', startAt, this.utilTimestamp.toTimestamp(startAt.toISOString()));
    console.log('endAt', endAt, this.utilTimestamp.toTimestamp(endAt.toISOString()));

    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('generatedAt', '>=', startAt),
      where('generatedAt', '<', endAt)
    );
    //const data$ = collectionData(q, { idField: 'id' });
    //const data = firstValueFrom(data$) as Promise<Gifcome[]>;
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Gifcome[];


    return data;
  }

  checkDateBorder(idGifcome: string, gifcomes: Gifcome[]): boolean {
    const index = gifcomes.findIndex((gifcome: Gifcome) => gifcome.id === idGifcome);
    if (index === 0) return true;

    const gifcome = gifcomes[index];
    const gifcomePrev = gifcomes[index - 1];

    return this.utilTimestamp.getDate(gifcome.generatedAt) !== this.utilTimestamp.getDate(gifcomePrev.generatedAt);
  }

  async getGifcomesRecievedLatestPrecurrent(now: string): Promise<Gifcome[]> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('uidTo', '==', this.managerUser.user!.uid),
      where('isGifcomePrivate', '==', false),
      where('generatedAt', '<=', now),
      where('generatedAt', '>=', this.toOneHourAgo(now))
      //where('generatedAt', '>=', this.utilTimestamp.toTimestamp('2023-04-01T00:00:00.000Z'))
    );
    const snapshot = await getDocs(q);
    const data = snapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id
    })) as Gifcome[];

    return data;
  }

  toOneHourAgo(now: string): string {
    const date = new Date(now);
    date.setHours(date.getHours() - 1);
    return this.utilTimestamp.toTimestamp(date.toISOString());
  }

  getGifcomesRecievedLatestPostcurrent(now: string): Observable<Gifcome[]> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('uidTo', '==', this.managerUser.user!.uid),
      where('isGifcomePrivate', '==', false),
      where('generatedAt', '>', now)
    );
    const data$ = collectionChanges(q).pipe(
      map(changes => changes.filter(change => change.type === 'added').map(change => ({
        id: change.doc.id,
        ...change.doc.data()
      })))
    ) as Observable<Gifcome[]>;

    return data$;
  }

}