<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div class="row gy-1">
                    <section>
                        <app-widget-revenue [balance]="balance"></app-widget-revenue>
                    </section>

                    <section>
                        <app-banner-catalog></app-banner-catalog>
                    </section>
                </div>
            </section>

            <section>
                <h6>未読のギフチャ</h6>

                <div>
                    <app-widget-gifcha-list-unread></app-widget-gifcha-list-unread>
                </div>

                <div>
                    <a role="button" routerLink="/chats">すべて表示</a>
                </div>
            </section>

            <section>
                <h6>直近もらったギフコメ</h6>

                <div>
                    <app-widget-gifcome-list-latest></app-widget-gifcome-list-latest>
                </div>

                <div>
                    <a role="button" routerLink="/gifcomes">すべて表示</a>
                </div>
            </section>

        </div>
    </div>
</section>