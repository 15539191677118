import { Injectable } from '@angular/core';

declare const bootstrap: any;
type MessageType = 'SUCCESS' | 'ERROR';

@Injectable({
  providedIn: 'root'
})
export class UtilNotificationService {

  private _configToast: any = {};
  get configToast(): any {
    return this._configToast;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor() { }

  /******************************
   * Methods
   ******************************/

  notify(text: string, messageType: MessageType): void {
    const toast = bootstrap.Toast.getOrCreateInstance(document.getElementById('toast'));
    toast.hide();

    this._configToast = {
      text: text,
      color: messageType.toLowerCase(),
      icon: messageType === 'SUCCESS' ? 'fa-solid fa-check' : 'fa-solid fa-triangle-exclamation',
    }

    toast._config.delay = text.length * 100 + 500;
    toast.show();
  }

}
