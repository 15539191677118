import { Component, ElementRef } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { CONSTANT } from '../../../../../../settings/constant';
import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { ManagerPointService } from '../../services/managers/manager-point/manager-point.service';
import { ManagerStripeCustomerService } from '../../services/managers/manager-stripe-customer/manager-stripe-customer.service';
import { ManagerUserService } from '../../services/managers/manager-user/manager-user.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { SpinnerComponent } from "../../components/spinner/spinner.component";

declare const bootstrap: any;

@Component({
  selector: 'app-modal-point-purchase',
  standalone: true,
  templateUrl: './modal-point-purchase.component.html',
  styleUrl: './modal-point-purchase.component.scss',
  imports: [ButtonCloseModalComponent, DecimalPipe, CurrencyPipe, SpinnerComponent]
})
export class ModalPointPurchaseComponent {

  /******************************
   * Properties
   ******************************/

  indexPlanPoint: number = -1;

  readonly plansPoint: any[] = [
    {
      price: 550,
      point: 500
    },
    {
      price: 1100,
      point: 1000
    },
    {
      price: 2200,
      point: 2000
    },
    {
      price: 11000,
      point: 10000
    },
    {
      price: 33000,
      point: 30000
    },
    {
      price: 55000,
      point: 50000
    }
  ];

  get rateTaxConsumption(): number {
    return CONSTANT.RATE_TAX_CONSUMPTION;
  }

  private _paymentMethods?: any[];
  get paymentMethods(): any[] | undefined {
    return this._paymentMethods;
  }

  /*** Status ***/

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private elementRef: ElementRef,
    private managerUser: ManagerUserService,
    private managerPoint: ManagerPointService,
    private managerStripeCustomer: ManagerStripeCustomerService,
    private stateProcessing: StateProcessingService
  ) { }

  reset(): void {
    this.indexPlanPoint = -1;
  }

  ngOnInit(): void {
    const modal = this.elementRef.nativeElement.querySelector('#modalPointPurchase');
    if (modal === null) return;

    const onFocus = () => {
      this.managerPoint.getPaymentMethods().then((res) => {
        this._paymentMethods = res.paymentMethods;
      });
    };
    modal.addEventListener('show.bs.modal', () => {
      onFocus();

      addEventListener("focus", onFocus);
    });
    modal.addEventListener('hidden.bs.modal', () => {
      this.reset();

      removeEventListener("focus", onFocus);
    });
  }

  /******************************
   * Methods
   ******************************/

  purchasePoint(): void {
    this.managerPoint.purchasePoint(this.plansPoint[this.indexPlanPoint].price, this.plansPoint[this.indexPlanPoint].point).then(() => {
      const modal = bootstrap.Modal.getInstance(this.elementRef.nativeElement.querySelector('#modalPointPurchase'));
      modal.hide();
    });
  }

  redirectToPortalAccountStripeCustomer(): void {
    this.managerStripeCustomer.redirectToStripeCustomerPortal(this.managerUser.user!.email!);
  }

}
