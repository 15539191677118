<section class="py-5">
    <div class="container">
        <div class="content row gy-2">
            <h1>配信者にギフコメしよう！</h1>

            <div>
                <button class="btn btn-main btn-lg" type="button" (click)="signIn()">
                    はじめる
                </button>
            </div>

            <img src="/assets/images/dust/top.svg" style="max-width: 600px">
        </div>
    </div>
</section>

<section class="callout-perl-1">
    <div class="container">
        <div class="content row gy-1">
            <section>
                <h6>配信関係の方はこちら</h6>
            </section>

            <section>
                <div class="d-flex gap-25">
                    <a class="btn btn-link" routerLink="/onboarding"
                        [queryParams]="{src: 'wOGMirZWTmQVNUDX38elVgVKZCA3', tgt: 'CREATOR'}">
                        配信者になる
                    </a>

                    <a class="btn btn-link" routerLink="/onboarding"
                        [queryParams]="{src: 'hnUebPOBAjNQYd51IBRyQ9loKU73', tgt: 'AGENT_PARENT'}">
                        エージェントになる
                    </a>
                </div>
            </section>
        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 text-center">
                <img src="/assets/images/dust/gift.svg" />
            </div>
            <div class="col-12 col-md-6">
                <h3 class="mb-4">ギフコメ®︎って何？</h3>
                <p>ギフコメ®︎は、配信者に贈れる、イラスト付きのメッセージです。</p>
                <p>贈ったギフコメは、配信者がライブ中であれば、配信画面にリアルタイムで表示<sup>*1</sup><sup>*2</sup>されます。とても目立つので、チャット欄よりも、反応してもらえる可能性がアップします。
                </p>
                <p>
                    <sup>*1 配信ツールがギフコメAPIに接続している場合</sup><br>
                    <sup>*2 非公開でも贈れます</sup>
                </p>
                <p>贈ったギフコメは、あとからでも確認できます。</p>
            </div>
        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 text-center order-md-1">
                <img src="/assets/images/dust/message_public.svg" />
            </div>
            <div class="col-12 col-md-6">
                <h3 class="mb-4">メッセージの贈り方</h3>
                <p>メッセージを贈るには、事前にギフコメポイントを購入します。カード情報は、世界中で利用されているStripeに保管されるので、非常に安全です。</p>
                <p>ギフコメポイントを購入したら、配信者のプロフィールにアクセスし、メッセージを入力します。プロフィールのURLは、配信者から配信中にアナウンスがあるはずです。</p>
                <p>送信ボタンを押すと、メッセージが贈られ、ギフコメポイントが消費されます。消費量は、イラストの種類と文字数によって自動計算され、換算すると、1文字あたり10円から71.4円です。</p>
            </div>
        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 text-center">
                <img src="/assets/images/dust/message_private.svg" />
            </div>
            <div class="col-12 col-md-6">
                <h3 class="mb-4">秘密メッセージ</h3>
                <p>メッセージがライブ配信画面に表示されると、お祝いやお礼など、他人に読まれて恥ずかしいときもあるはず。そんなときは、非公開オプションをオンにしてメッセージ贈ることで、他者に絶対に公開されることなく、メッセージを届けられます。
                </p>
                <p>このオプションを使った場合、ギフコメ®︎ポイントの消費量は、通常の1.5倍になります。</p>
            </div>
        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6 text-center order-md-1">
                <img src="/assets/images/dust/api.svg" />
            </div>
            <div class="col-12 col-md-6">
                <h3 class="mb-4">配信者やプロダクションの方へ</h3>
                <p>ギフコメ®︎は、OBSプラグインを配布しています。このプラグインを導入し、OBS経由でライブ配信をすると、すぐにギフコメ経由のメッセージを、リアルタイムで配信画面に表示できます。</p>
                <p>そのため、あなたが、YouTubeやTikTok、Instagramなど、複数の配信プラットフォームを使っていても、ユーザーは、ギフコメ®︎にさえ登録していれば、いつでもメッセージを贈れます。</p>
                <p>また、ギフコメ®︎は、APIを公開しています。APIを使ってプラグインをカスタマイズすることで、メッセージが届いたときの演出をカスタマイズできます。詳しくは、お問い合わせください。</p>
            </div>
        </div>
    </div>
</section>

<section class="mb-5 text-center px-2">
    <div class="container py-5 bg-perl-2" style="border-radius: 1.0rem;">
        <h2 class="mb-5">配信者とファンとが<br>もっと密になる。</h2>

        <button class="btn btn-main btn-lg" type="button"
            (click)="signIn()">はじめる</button>
    </div>
</section>

<footer class="navbar navbar-light py-5 bg-main text-white">
    <div class="container-fluid justify-content-end">
        <!-- <a class="navbar-brand d-flex align-items-center" routerLink="/">
            <img class="me-1" height="34" src="/assets/images/icon_gifcome_fixed_white.svg" style="margin-bottom: 4px;">
            <img class="mb-1" height="28" src="/assets/images/logo_gifcome.svg">
        </a> -->

        <div>
            <a class="btn-link text-white" data-bs-toggle="modal" data-bs-target="#modal-terms">利用規約</a>/
            <a class="btn-link text-white" routerLink="terms-pp" role="button">プライバシー</a>/
            <a class="btn-link text-white" data-bs-toggle="modal" data-bs-target="#modal-tokusho">特商法</a>/
            <a class="btn-link text-white" href="https://note.com/gifcome" target="_blank">使い方</a>/
            <a class="btn-link text-white" data-bs-toggle="modal" data-bs-target="#modal-contact">お問い合わせ</a>
        </div>
    </div>
</footer>

<!-- Tokusho Modal -->
<div class="modal" id="modal-tokusho" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">特定商取引法に基く表記</h5>

                <app-button-close-modal></app-button-close-modal>
            </div>
            <div class="modal-body row gy-2">
                <div class="col-12">
                    <table class="table">
                        <tbody>
                            <tr>
                                <th>会社名</th>
                                <td>合同会社MDK</td>
                            </tr>
                            <tr>
                                <th>代表者</th>
                                <td>河合大介</td>
                            </tr>
                            <tr>
                                <th>本社</th>
                                <td>〒100-6213<br>
                                    東京都千代田区丸の内１－１１－１<br>
                                    パシフィックセンチュリープレイス丸の内8階</td>
                            </tr>
                            <tr>
                                <th>連絡先</th>
                                <td>info&#64;gifcome.com</td>
                            </tr>
                            <tr>
                                <th>支払い方法</th>
                                <td>クレジットカード</td>
                            </tr>
                            <tr>
                                <th>返金について</th>
                                <td>サービスの性質上、購入されたポイントの返金やキャンセルは一切お受け付けできません。</td>
                            </tr>
                            <tr>
                                <th>その他</th>
                                <td>電気通信事業 A-04-20528<br>登録商標 第6704736号, 第6740364号</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- Terms Modal -->
<div class="modal" id="modal-terms" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">利用規約</h5>

                <app-button-close-modal></app-button-close-modal>
            </div>
            <div class="modal-body">

                <h4>利用規約</h4>
                <p>本利用規約は、MDK合同会社(以下「当社」といいます。)が提供する「ギフコメ®︎」サービス(当社が管理するソフトウェア及びウェブサイトを通じてお客様に提供するサービスの一切を総称します。以下
                    「本サービス」といいます。)をご利用になるすべての方に共通して適用されます。</p>

                <h5>1．当社のサービスの利用と利用規約への同意</h5>
                <p>本利用規約にご同意いただくことによって、本サービスをご利用いただくことができます。なお、本利用規約にご同意いただく手続に代えて、実際にご利用いただくことで本利用規約にご同意いただいたものとみなします。
                </p>

                <h5>2．サービス内容の保証および変更</h5>
                <p>当社は、提供する本サービスの内容について、瑕疵やバグがないことを保証しておりません。また、当社は、お客様に予め通知することなくサービスの内容や仕様を変更したり、提供を停止したり中止したりすることができるものとします。
                </p>

                <h5>3. サービスの利用制限</h5>
                <p>当社は、本サービスのご利用を、「ギフコメ®︎」アカウント（当社が提供する本サービスのアカウントをいいます。以下「アカウント」といいます。）を登録された方に限定したり、一定の年齢以上の方に限定したり、当社が定める本人確認などの手続を経て一定の要件を満たしたお客様のみに限定したりするなど、ご利用に際して条件を付すことができるものとします。
                </p>
                <p>また、当社は、反社会的勢力の構成員（過去に構成員であった方を含みます。）及びその関係者の方や第三者に迷惑をかけるお客様に対してはご利用をお断りしています。</p>

                <h5>4. アカウントの登録及び登録情報等</h5>
                <p>ア．本サービスの利用を希望するお客様は、本利用規約の内容に同意した上で、当社指定の方法によりアカウントの登録（利用登録）を行うものとします。</p>
                <p>イ．アカウントを登録していただく場合、（1）真実かつ正確な情報を登録していただくこと、（2）登録内容が最新となるようお客様ご自身で適宜修正していただくことがお客様の義務となります。アカウントに真実かつ正確な情報を登録しないこと、情報の追加・更新を行わないことによりお客様が不利益を被ったとしても、当社は⼀切の責任を負いません。
                </p>
                <p>ウ．お客様が、次の各号のいずれかに該当すると当社が判断した場合、当社は、お客様のアカウントの登録（利⽤登録）を承諾しない場合があります。</p>
                <ul>
                    <li>本利用規約に違反してアカウントの登録（利⽤登録）を行った場合</li>
                    <li>過去に本利用規約⼜はその他特約等に違反したことを理由として、本サービスの利⽤の停⽌、投稿内容の削除、利⽤登録の抹消等の措置を受けた場合</li>
                    <li>前各号に定める他、当社が不適切と判断した場合</li>
                </ul>
                <p>エ．お客様がアカウント削除を希望する場合には、当社所定の⽅法により、⾃⼰の責任においてアカウントの削除を行うものとします。その場合、お客様は、アカウントの削除時に本サービス利⽤に関する⼀切の権利を失うものとし、また、当社に対する⼀切の請求権を放棄したものとみなされます。
                </p>
                <p>オ．お客様が死亡した場合は、その時点でアカウントを削除します。当社に対する一切の権利は相続・遺贈の対象となりません。</p>

                <h5>5. アカウント及びパスワード等に関するお客様の責任</h5>
                <p>お客様を特定する当社のウェブサイト又はアプリケーション上で公表している認証方法（メールアドレスとパスワードの組み合わせや携帯電話事業者から送信される携帯電話番号ごとに一意に付与される符号の、登録情報との一致確認による認証を含みますが、これらに限りません。）によりログインされた場合には、当社は、当該お客様ご自身によるご利用であるとみなします。サービスのご利用や商品の購入などによって料金や代金（当社のサービスのご利用にかかる代金、利用料、会費その他名目は問いません。また当社が第三者から回収を受託したお客様の債務を含みます。以下「代金」といいます。）が発生した場合には、当該お客様に請求いたします。
                </p>

                <h5>6. サービス利用にあたっての順守事項</h5>
                <p>本サービスのご利用に際しては、ギフコメ®︎に投稿するコメントが原則として不特定多数の者に開示される性質のものであることを認識し、自己の責任において投稿を行なうものとします。</p>
                <p>当社は、利用者が自己の個人情報等をギフコメ®︎に投稿したコメントの中で開示した場合の結果につき、一切責任を負いません。また、以下に定める行為（それらを誘発する行為や準備行為も含みます。）を禁止します。
                </p>
                <ul>
                    <li>日本国又はご利用の際にお客様が所在する国・地域の法令に違反する行為</li>
                    <li>次に掲げる行為等、法令・社会規範・公序良俗に反するもの、当社または第三者の権利を侵害するもの、第三者の迷惑となるようなものその他不適切なものを、投稿、掲載、開示、提供又は送信（以下これらを総称して「投稿など」といいます。）したりする行為
                    </li>
                    <li>虚偽の情報を故意に投稿などする行為</li>
                    <li>同一内容のコメントを意図的に多数投稿などする行為</li>
                    <li>当社のサービス、および同サービスの機能、同サービスにおいて提供される情報の正確性に関するお問合せやクレームを投稿などする行為</li>
                    <li>本人・第三者の名称・メールアドレス・住所・電話番号・人物が認識できる状態の写真等個人を特定しうる情報を含むもの（投稿者名や投稿内容において使用される場合を含むが、これらに限られません）を投稿などする行為
                    </li>
                    <li>広告・宣伝・営業活動、公職選挙運動、特定の思想・宗教への勧誘、またはそれらに類する内容を含むものを投稿などする行為</li>
                    <li>児童や青少年に対し著しく粗暴性、残虐性または犯罪を誘発助長し、その健全な育成を阻害する内容を含むものを投稿などする行為</li>
                    <li>猥褻な情報、青少年に有害な情報および異性交際に関する情報を投稿などする行為</li>
                    <li>第三者を威圧・脅迫する旨が看取されるものを投稿などする行為</li>
                    <li>差別的表現を含むものを投稿などする行為</li>
                    <li>当社のサービスにおけるテーマや目的に反するまたはそのおそれのある内容を含むものを投稿などする行為</li>
                    <li>意味不明な内容やグロテスクな内容を含むものを投稿などする行為</li>
                    <li>「さくら」的な行為等、故意に商品やサービスを優良と誤認させるような内容を投稿などする行為</li>
                    <li>競合する商品やサービスの信頼を低下させる目的で中傷する内容を投稿などする行為</li>
                    <li>ほかのお客様の使用するソフトウェア、ハードウエアなどの機能を破壊したり、妨害したりするようなプログラムなどの投稿などする行為</li>
                    <li>その他、当社のサービスに不適切な内容、表現であると当社が判断したものを投稿などする行為</li>
                    <li>当社のサーバー又はネットワークの機能を破壊したり、妨害したりする行為</li>
                    <li>ハッキング行為など、当社のサーバー等のコンピューターに不正にアクセスする行為又は、スパムメール、チェーンレター、ジャンクメール等を送信する行為</li>
                    <li>逆アセンブル、逆コンパイル、リバースエンジニアリング行為などソースコード、構造、アイデア等を解析する行為</li>
                    <li>クローリング、スクレイピング行為など、ほかのお客様の個人情報やパーソナルデータ（当社所定のプライバシーポリシーにて定義されます）などの当社のウェブサイトの情報を無断で収集したり蓄積したりする行為又は情報を収集するために当社のウェブサイトを巡回する行為
                    </li>
                    <li>サービスを、マネー・ローンダリング、換金目的など、提供の趣旨に照らして本来のサービス提供の目的とは異なる目的で利用する行為</li>
                    <li>ほかのお客様のアカウントを使用してサービスを利用する行為</li>
                    <li>手段のいかんを問わず第三者からパスワードを入手したり、第三者にパスワードを開示したり提供したりする行為</li>
                    <li>当社のサービスに関連して、反社会的勢力に直接・間接に利益を提供する行為</li>
                    <li>詐欺目的の行為</li>
                    <li>当社の承認した以外の方法により当社のサービスを利用する行為</li>
                    <li>当社のサービスを無断で改変する行為</li>
                    <li>当社または第三者を差別または誹謗中傷する行為、名誉、プライバシー等を傷つける行為、もしくはそれらのおそれのある行為</li>
                    <li>当社のサービス、当社の配信する広告、または当社のサイトおよび当社のアプリ上で提供されているサービス、広告を妨害する行為</li>
                    <li>当社または第三者の肖像権、著作権、商標権その他の知的財産権を侵害し、または侵害するおそれのある行為</li>
                    <li>犯罪的行為に結びつく行為</li>
                    <li>当社、当社の関連会社、または当社の提携先の信用を毀損させるような行為</li>
                    <li>その他、法令、公序良俗または本利用規約に違反する行為または違反のおそれのある行為</li>
                </ul>

                <h5>７．通信の秘密</h5>
                <p>ア．当社は、電気通信事業法その他関係法令に基づき、利用者が非公開に設定しているコメント（以下「秘密メッセージ」といいます。）について、利用者の通信の秘密を守ります。</p>
                <p>イ．前項の規定にかかわらず、当社は、当社が次の各号に該当すると判断する場合、秘密メッセージの利用者への事前告知なしに、投稿したコメントの内容その他利用者の通信の秘密にかかわる情報を閲覧、提供、利用または非公開もしくは削除できるものとし、当社はこれについて一切の責任を負いません。
                </p>
                <ul>
                    <li>刑事訴訟法または犯罪捜査のための通信傍受に関する法律の定めに基づく強制力のある処分または裁判所の命令もしくは令状による場合</li>
                    <li>法令に基づく強制力のある処分が行われた場合</li>
                    <li>特定電気通信役務提供者の損害賠償責任の制限及び発信者情報の開示に関する法律に従って送信防止措置または発信者情報の開示等に応じる必要があると当社が判断した場合</li>
                    <li>他人の生命、身体、財産または名誉、プライバシーの保護のために必要があると当社が判断した場合
                    <li>
                    <li>前各号に掲げるほか、違法性阻却事由を満たすと当社が合理的に判断した場合</li>
                    <li>利用者の同意がある場合</li>
                </ul>

                <h5>８. 当社のサービスなどの再利用の禁止</h5>
                <p>お客様が、当社の本サービスやそれらを構成するデータを、本サービスの提供目的を超えて利用した場合、当社は、それらの行為を差し止める権利ならびにそれらの行為によってお客様が得た利益相当額を請求する権利を有します。
                </p>

                <h5>９. 当社に対する補償</h5>
                <p>お客様の行為が原因で生じたクレームなどに関連して当社に費用が発生した場合または当社が賠償金などの支払を行った場合、お客様は、当社が支払った費用や賠償金など（当社が支払った弁護士費用を含みます。）を負担するものとします。
                </p>

                <h5>10. お客様のデータおよびコンテンツの取扱い</h5>
                <p>お客様が当社の管理するサーバーに保存しているデータについて、当社ではバックアップの義務を負わないものとし、お客様ご自身においてバックアップを行っていただくものとします。</p>
                <p>なお、当社のサービスの保守や改良などの必要が生じた場合には、当社は、お客様が当社の管理するサーバーに保存しているデータを、サービスの保守や改良などに必要な範囲で複製することができるものとします。</p>
                <p>お客様が当社のサービスにおいて投稿などしたコンテンツについては、お客様に著作権が帰属するものとし、お客様は、当該コンテンツがお客様独自の創作物であり、この中に第三者の著作権、その他の権利が関わらないことを保証します。ただし、例外的に必要があって、当該コンテンツ中に第三者の著作物等が含まれる場合、お客様の責任と負担において必要とされる権利処理をされることを前提かつ保証事項とさせていただきます。お客様が投稿などをしたコンテンツについて、お客様は当社に対して、日本の国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含みます）する権利を期限の定めなく許諾（サブライセンス権を含みます）したものとみなします。なお、お客様は、著作者人格権を行使しないものとします。
                </p>

                <h5>11. 広告掲載について</h5>
                <p>当社は、提供するサービスやソフトウェアに当社または当社に掲載依頼をした第三者の広告を掲載することができるものとします。</p>

                <h5>12. 投稿などの削除、サービスの利用停止、アカウントの削除について</h5>
                <p>当社は、提供するサービスを適正に運営するために、次の各号のいずれかに該当する場合には、あらかじめ通知することなく、投稿されたコメントを削除したり、当社のサービスの全部または一部の利用をお断りしたり、お客様のアカウントを削除したりするといった措置を講じることができるものとします。
                </p>
                <p>また、当社は、お客様が以下の各号のいずれにも該当しないことを確認するために、当社が必要と判断する本人確認を行うことができ、かかる本人確認が完了するまでサービスの全部または一部へのアクセスの拒否、利用停止等の措置をすることができます。なお、お客様のアカウントを削除した場合、同アカウントに記録されたポイントの残高、利用履歴、その他一切のお客様の権利及び情報は全て消滅するものとします。
                </p>
                <ul>
                    <li>お客様が本利用規約その他お客様に適用される規約に定められている事項に違反した場合、もしくはそのおそれがあると当社が判断した場合</li>
                    <li>ポイント（当社が提供する、お支払い方法を登録および管理することができるサービスをいいます。以下同じ。）の代金決済手段として指定されたクレジットカードや銀行口座の利用が停止された場合</li>
                    <li>お客様が破産若しくは民事再生の手続の申立てを受け又はお客様自らがそれらの申立てを行うなど、お客様の信用不安が発生したと当社が判断した場合</li>
                    <li>アカウントが反社会的勢力またはその構成員や関係者によって登録又は使用された場合、若しくはそのおそれがあると当社が判断した場合</li>
                    <li>お客様のポイントの残高が最後に変動した日から1年が経過した場合（ただし、本事由をもってお客様のアカウントを削除する場合は、あらかじめ当該お客様への通知を行うものとします。）</li>
                    <li>不正な目的で同一のお客様が二つ以上のアカウントを利用した場合</li>
                    <li>その他、お客様との信頼関係が失われた場合など、当社とお客様との契約関係の維持が困難であると当社が判断した場合</li>
                </ul>

                <h5>13. 免責事項</h5>
                <p>ア．当社は、次の各号について、一切保証しないものとします。当社のサービスの利用に際しては、お客様自身が、当社のサービスにおいて提供される情報やサービスの有用性等を判断し、お客様自身の責任でご利用下さい。
                </p>
                <ul>
                    <li>当社のサービスにおいて提供される全ての情報（当社のサービスとして、および当社のサービス上に表示される、第三者が管理・運営するリンクサイト内に含まれる一切の情報等を含み、以下同様とします）に関する、有用性、適合性、完全性、正確性、安全性、合法性、最新性等
                    </li>
                    <li>お客様が当社の本サービス上において提供されるサービスを利用して第三者とやりとり又は交渉若しくは契約の申込又は締結等を行う場合において、当該行為に関する一切</li>
                    <li>当社の本サービス及び当社の本サービスを通じて入手できる役務、情報等がお客様の希望又は期待を満たす適切なものであること</li>
                    <li>当社の本サービスの提供に不具合、エラー又は障害が生じないこと</li>
                    <li>当社の本サービスに関連して送信される電子メール、ウェブコンテンツ等にコンピュータ・ウィルス等の有害なものが含まれていないこと</li>
                </ul>

                <p>イ．当社は、お客様の当社のサービスの利用（これらに伴う当社または第三者による情報提供行為等を含みますが、これに限りません。）又は利用不能により生じる一切の損害（本項各号に定める損害や、前項によりお客様又は第三者に生じた損害、精神的苦痛、またはその他の金銭的損失を含む一切の不利益を含みますが、これらに限りません）につき、当社に故意または重過失がない限り、当社は一切その責任を負わないものとします。
                </p>
                <ul>
                    <li>当社のサービスを介して行う、第三者が提供するコンテンツのダウンロード及び第三者が管理・運営するリンクサイトへのアクセス等の行為により、お客様に生じた損害</li>
                    <li>当社が相当の安全策を講じたにもかかわらず、当社のサービスの無断改変、当社のサービスに関するデータへの不正アクセス、コンピュータ・ウィルスの混入等の不正行為が行われ、若しくは、当社のサービスの不具合又は当社のサービスを利用して作動する機器の不具合が生じ、これに起因してお客様に生じた損害
                    </li>
                    <li>天災、火災、停電等不可抗力、システムの保守、通信回線やコンピューターの障害等による、当社のサーバーやシステム、当社のサービスの中断、遅延、中止、データ消失等により生じた損害</li>
                    <li>サービスの利用にあたって、お客様と第三者との間で生じたトラブルにより生じた損害</li>
                </ul>

                <h5>14. 個別サービスの利用条件（特約）などについて</h5>
                <p>特定の当社のサービスについては、本利用規約のほかに、サービス固有の利用条件（特約）が設けられている場合があります。本利用客と個別サービスの利用条件を含むサービス固有の利用条件が異なっている場合には、特段の定めがない限り、当該サービスに関してはそのサービス固有の利用条件が本利用規約に優先して適用されるものとします。
                </p>

                <h5>15. 利用規約の変更等について</h5>
                <p>当社が必要と判断した場合には、お客様に予め通知することなくいつでも本利用規約を変更することができるものとします。</p>
                <p>また、当社は、本利用規約の最新版を、当社ウェブサイトに掲載し、お客様が参照できるようにいたします。お客様は、当社ウェブサイトに掲載された利用規約を参照することにより改訂内容をご確認いただくこととし、本利用規約の改訂内容を確認しないことにより何らかの損害が生じても、当社は一切の責任を負わないものとします。
                </p>

                <h5>16. 通知または連絡</h5>
                <p>お客様が当社への連絡を希望される場合には、当社が設けた問い合わせページ、当社が指定するメールアドレスあてのメールによって行っていただくものとします。</p>
                <address>E-mail：websupport&#64;gifcome.com</address>

                <h5>17. 権利義務などの譲渡の禁止</h5>
                <p>お客様は、本利用規約に基づくすべての契約について、その契約上の地位およびこれにより生じる権利義務の全部又は一部を、当社の書面による事前の承諾なく第三者に譲渡することはできません。</p>

                <h5>18. 準拠法、裁判管轄</h5>
                <p>本利用規約の成立、効力発生、解釈にあたっては日本法を準拠法とします。</p>
                <p>また、当社のサービス（掲載内容や広告などを含む）、ソフトウェア及びアプリケーションに起因または関連して当社とお客様との間で生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                </p>

                <h5>19. 利用規約の適用制限について</h5>
                <p>本利用規約の規定がお客様との本利用規約に基づく契約に適用される関連法令に反するとされる場合、当該規定は、その限りにおいて、当該お客様との契約には適用されないものとします。ただし、この場合でも、本利用規約のほかの規定の効力には影響しないものとします。
                </p>

                <h5>20. 退会について</h5>
                <p>退会をご希望のお客様は、お客様のアカウントから当社所定の<a routerLink="/leave" data-bs-dismiss="modal">退会手続</a>をお願いします。</p>

                <hr>

                <h4>ポイントプログラム特約</h4>
                <p>ポイントプログラム特約（以下「本特約」といいます。）は、当社がサービス利用者及びクリエイターに提供するポイントプログラム（以下「本プログラム」といいます。）の内容について定めるものです。なお、サービス利用者及びクリエイターは、本特約に加えて、当社が定める利用規約その他の特約類等を全て遵守するものとします。
                </p>

                <h5>1. ポイントの発行（購入）</h5>
                <ul>
                    <li>サービス利用者は、本サービスの様々な場面で利用することができるポイントを当社所定の方法をもって購入することができます。</li>
                    <li>サービス利用者が購入したポイントは、アカウントにポイント残高として記録されて発行されます。</li>
                    <li>ポイントには、利息はつきません。</li>
                    <li>当サイトで購入したポイントの有効期限は購入の日から 60 日間です (App Store、 Google Play ストアで購入したポイントは除きます。) 。</li>
                    <li>一度購入されたポイントは、いかなる理由があったとしても返金できません。</li>
                </ul>

                <h5>2. ポイントの利用</h5>
                <ul>
                    <li>サービス利用者は、ポイントを当社所定の方法によりクリエイターにギフコメ®︎を贈る際など本サービスの様々な場面で利用することができます。</li>
                    <li>当サイトで購入したポイントと App Store、Google Play ストアで購入したポイントとで、利用できる本サービスの種類や内容に違いはありません。</li>
                    <li>サービス利用者は、本サービスの利用時に、当サイトで購入したポイントと AppStore、Google Play
                        ストアで購入したポイントの両方を保有する場合、当サイトで購入したポイントに優先して App Store、Google Play</li>
                    <li>ストアで購入したポイントから使用するものとします。</li>
                </ul>

                <h5>3. クリエイターによる入金</h5>
                <ul>
                    <li>クリエイターは、サービス利用者が贈ったギフコメ®︎の種類、数量、文字数等を考慮して当社が計算した入金可能額の範囲でその全部又は一部（最小単位は１万円）を、当社所定の手続によりアカウントから銀行口座等に入金することができます。
                    </li>
                    <li>クリエイターが入金操作をした場合、当社は、クリエイターがあらかじめ指定した銀行口座等に対し、当社指定のサイクルで振込を行います。次条で定める入金手数料及びこれに対する消費税相当額を差し引いた残額が当該銀行口座等に日本円で振り込まれます。
                    </li>
                </ul>

                <h5>4. 入金手数料</h5>
                <p>クリエイターがポイントを自ら指定した銀行口座等へ入金する際の手数料は、別途当社が運営するウェブサイト内の手数料に関する場所に掲示するとおりとします。なお、ポイントプログラムの利用に伴い、税金や付帯費用が発生する場合には、クリエイターがこれらを負担するものとします。そのほか、入金に際して金融機関所定の費用などが発生しますので、ご留意ください。
                </p>

                <h5>5. ポイントプログラムに係る禁止事項</h5>
                <p>サービス利用者は、以下に記載する行為を行ってはなりません。</p>
                <ul>
                    <li>預金目的でアカウントまたはポイントを保有または利用する行為。</li>
                    <li>マネー・ローンダリング目的でアカウントを保有し、またはポイントをマネー・ローンダリングに利用する行為。</li>
                    <li>不正な方法によりポイントを取得し、または不正な方法で取得されたポイントであることを知って利用する行為。</li>
                    <li>アカウントまたはポイントを偽造もしくは変造し、または偽造もしくは変造されたポイントであることを知って利用する行為。</li>
                    <li>詐欺等の犯罪に結びつく行為。</li>
                    <li>法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。</li>
                    <li>公の秩序または善良の風俗を害するおそれのある行為。</li>
                    <li>当社または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。</li>
                    <li>過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為。
                    </li>
                    <li>当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。</li>
                    <li>同一または類似のメッセージを不特定多数の利用者に送信する行為（当社の認めたものを除きます。）、その他当社がスパムと判断する行為。</li>
                    <li>ポイントを当社所定の方法以外の方法で、現金、財物その他の経済上の利益と交換する行為。</li>
                    <li>本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。</li>
                    <li>反社会的勢力に対する利益供与その他の協力行為。</li>
                    <li>宗教活動または宗教団体への勧誘行為。</li>
                    <li>他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為</li>
                    <li>同一または類似の行為を繰り返す等通常の利用の範囲を超えた利用行為。</li>
                    <li>上記のいずれかに該当する行為を援助または助長する行為。</li>
                    <li>その他、当社が不適当と判断した行為。</li>
                </ul>

                <h5>6. 本特約の変更</h5>
                <p>当社は、本特約をいつでも変更、追加、削除することができるものとします。この場合、当社が合理的と判断する方法によりサービス利用者及びクリエイターに周知します。本特約に変更がなされたことが前項に基づき周知された後に、サービス利用者及びクリエイターが本サービスの利用を継続した場合は、本規約の変更を承諾したものとみなします。
                </p>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal">閉じる</button>
            </div>
        </div>
    </div>
</div>

<!-- Contact Modal -->
<div class="modal" id="modal-contact" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">お問い合わせ</h5>

                <app-button-close-modal></app-button-close-modal>
            </div>
            <div class="modal-body row gy-2">
                <div class="col-12">
                    <h5>ギフコメ®︎のAPI利用を希望する方へ</h5>


                    <p>ギフコメ®︎はAPIを公開しています。APIを利用してギフコメ®︎を対応させたツールなどの開発を行っていただくことが可能です。</p>
                    <p>APIのご利用希望の方は下記内容をご記入の上<a href="mailto:mdkpc7@gmail.com">mdkpc7&#64;gmail.com</a>までお問い合わせください。</p>

                    <hr>

                    <p>To: 合同会社MDK</p><br>

                    <p>【問い合わせ内容】</p>
                    <p>企業名：<br>
                        ご担当者様のお名前：<br>
                        メールアドレス：<br>
                        電話番号：<br>
                        APIの利用目的：<br>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- <section class="py-5 bg-perl-1">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <h1>ギフコメしよう！</h1>
                <p>ギフチャを贈ると返信があるかも？</p>
            </section>

            <section>
                <button class="btn btn-main btn-lg rounded-pill" type="button"
                    (click)="getStarted()">
                    ギフコメを贈る
                </button>
            </section>

        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="content row gy-2 align-items-center">

            <div>
                <div class="card card-body">
                    <h3>おかげさまで累計ギフコメ25万個を突破！</h3>
                    <p>
                        さらに使いやすくリニューアルしました♪
                    </p>
                </div>
            </div>

        </div>
    </div>
</section> -->

<!--
<section class="py-5">
    <div class="container">
        <div class="content row gy-2 align-items-center">

            <section class="col-12 col-md-3">
                <div class="d-flex">
                    <img src="/assets/images/logo_text.png">
                </div>
            </section>

            <section class="col-12 col-md-9">
                <div class="row gy-1">
                    <section>
                        <h2>ギフコメってなに？</h2>
                    </section>

                    <section>
                        <p>
                            ギフコメは配信者に贈れるイラスト付きのメッセージです。
                        </p>
                    </section>

                    <section>
                        <p>
                            贈ったギフコメはライブ中であれば、配信画面にリアルタイムで表示されます。とても目立つのでチャット欄よりも反応されやすいかも？
                        </p>
                    </section>

                    <section>
                        <p>
                            贈ったギフコメはあとから見返せます。
                        </p>
                    </section>
                </div>
            </section>

        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="content row gy-2 align-items-center">

            <section class="col-12 col-md-3 order-md-2">
                <div class="d-flex">
                    <img src="/assets/images/logo_text.png">
                </div>
            </section>

            <section class="col-12 col-md-9 order-md-1">
                <div class="row gy-1">
                    <section>
                        <h2>ギフコメの贈り方</h2>
                    </section>

                    <section>
                        <p>
                            ギフコメを贈るには先にポイントを追加します。カード設定は、世界中で利用されているStripeというサービスで保管されるので、非常に安全です。
                        </p>
                    </section>

                    <section>
                        <p>
                            ポイントを追加したら、配信者のプロフィールに移動し、イラストを選んでメッセージを書いて送信ボタンを押します。これでギフコメが配信者に贈られます。プロフィールのアドレスは配信者に聞いてみてください。
                        </p>
                    </section>

                    <section>
                        <p>
                            ギフコメを贈るとポイントが消費されます。消費量はイラストのランクとメッセージの文字数によって自動計算されます。
                        </p>
                    </section>
                </div>
            </section>

        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="content row gy-2 align-items-center">

            <section class="col-12 col-md-3">
                <div class="d-flex">
                    <img src="/assets/images/logo_text.png">
                </div>
            </section>

            <section class="col-12 col-md-9">
                <div class="row gy-1">
                    <section>
                        <h2>秘密ギフコメの魅力</h2>
                    </section>

                    <section>
                        <p>
                            お祝いやお礼のメッセージなど、ギフコメが配信画面に表示されると恥ずかしいときもあるはず。そんなときは秘密ギフコメを贈りましょう。
                        </p>
                    </section>

                    <section>
                        <p>
                            「秘密ギフコメにする」をオンにして贈ったギフコメは配信者とあなたにしか表示されなくなります。秘密ギフコメはチャット形式で見返せてとても便利。もしかすると配信者からの返信があるかも？
                        </p>
                    </section>

                    <section>
                        <p>
                            秘密ギフコメはポイントの消費量が通常の1.5倍になります。
                        </p>
                    </section>
                </div>
            </section>

        </div>
    </div>
</section>

<section class="py-5">
    <div class="container">
        <div class="content row gy-2 align-items-center">

            <section class="col-12 col-md-3 order-md-2">
                <div class="d-flex">
                    <img src="/assets/images/logo_text.png">
                </div>
            </section>

            <section class="col-12 col-md-9 order-md-1">
                <div class="row gy-1">
                    <section>
                        <h2>配信関係の方へ</h2>
                    </section>

                    <section>
                        <p>
                            ギフコメをOBSでかんたんに表示できるようにするツールを無料で公開しています。このツールを使うとギフコメ経由のコメントをリアルタイムで配信画面に表示できます。
                        </p>
                    </section>

                    <section>
                        <p>
                            YouTubeやTikTok、Instagramなど、いくつかのプラットフォームで配信をしていても、ファンはギフコメにだけ登録していれば、どこでも同じ手順でギフコメを贈れて便利です。またギフコメは配信中でなくても受け取れます。
                        </p>
                    </section>

                    <section>
                        <p>
                            またギフコメはAPIを公開しています。APIを使って貴社のサービスとギフコメとを連携することが可能です。詳しくはお問い合わせください。
                        </p>
                    </section>
                </div>
            </section>

        </div>
    </div>
</section>

<section class="py-5 bg-perl-1">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <h1>配信者とファンとの<br>あたらしいコミュニケーション</h1>
            </section>

            <section>
                <button class=" btn btn-main btn-lg rounded-pill" type="button"
                    (click)="getStarted()">
                    ギフコメを贈る
                </button>
            </section>

        </div>
    </div>
</section>

<footer>

</footer>
-->