@if(!isStripeConnectedSubmitted) {
<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div class="card card-body">
                    <div class="row gy-1">
                        <section>
                            <h3>Stripeに接続</h3>
                            <p>収益化を開始するにはStripeに接続します。</p>
                        </section>

                        <section>
                            <button class="btn btn-link" type="button"
                                (click)="redirectToSettings()">
                                Stripeに接続
                                <i class="fa-solid fa-external-link icon-suffix"></i>
                            </button>
                        </section>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>
}
@else if(isStripeConnectedDisabled) {
<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <div class="card card-body">
                    <div class="row gy-1">
                        <section>
                            <h3>身分証の提出</h3>
                            <p>収益化を開始するにはStripeで身分証を提出します。</p>
                        </section>

                        <section>
                            <button class="btn btn-link" type="button"
                                (click)="redirectToDashboard()">
                                Stripeで身分証を提出
                                <i class="fa-solid fa-external-link icon-suffix"></i>
                            </button>
                        </section>

                        <section>
                            <p>
                                提出後、「確認待ち」となりましたら、このページに戻ってきてください。
                            </p>
                        </section>
                    </div>
                </div>
            </section>

        </div>
    </div>
</section>
}
@else {
<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <section>
                <app-widget-revenue [balance]="balance"></app-widget-revenue>
            </section>

            <!-- <section>
                <div class="card card-body bg-perl-1 py-3">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex align-items-center">
                            <div>
                                <i class="fs-1">{{ balance | currency: 'JPY' }}</i>
                            </div>
                        </div>

                        <div>
                            <button class="btn btn-main rounded-pill" type="button"
                                (click)="redirectToDashboard()">
                                収益を確認
                                <i class="fa-solid fa-external-link icon-suffix"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </section> -->

            @if(account?.role === 'ADMIN') {
            <section>
                <div class="card card-body border-0 bg-perl-1">
                    <div class="row gy-2">
                        <div>
                            <h5>エージェントを招待</h5>
                            <p>あなたが招待したエージェントに売上が出ると、その売上の一部があなたに分配されます。</p>
                        </div>

                        <div>
                            <div class="form-floating">
                                <input type="text" class="form-control border-0" id="floatingInput"
                                    [value]="getUrlInvitation('AGENT_PARENT')" disabled>
                                <label for="floatingInput">招待リンク</label>
                                <button class="btn btn-link btn-sm btn-floating" type="button"
                                    (click)="copy(getUrlInvitation('AGENT_PARENT'))">
                                    <i class="fa-solid fa-copy"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            @if(account?.role === 'AGENT_PARENT' || account?.role === 'AGENT_CHILD') {
            <section>
                <div class="card card-body border-0 bg-perl-1">
                    <div class="row gy-2">
                        <div>
                            <h5>クリエイターを招待</h5>
                            <p>あなたが招待したクリエイターに売上が出ると、その売上の一部があなたに分配されます。</p>
                        </div>

                        <div>
                            <div class="form-floating">
                                <input type="text" class="form-control border-0" id="floatingInput"
                                    [value]="getUrlInvitation('CREATOR')" disabled>
                                <label for="floatingInput">招待リンク</label>
                                <button class="btn btn-link btn-sm btn-floating" type="button"
                                    (click)="copy(getUrlInvitation('CREATOR'))">
                                    <i class="fa-solid fa-copy"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div class="card card-body border-0 bg-perl-1">
                    <div class="row gy-2">
                        <div>
                            <h5>エージェントを招待</h5>
                            <p>あなたが招待したエージェントに売上が出ると、その売上の一部があなたに分配されます。</p>
                        </div>

                        <div>
                            <div class="form-floating">
                                <input type="text" class="form-control border-0" id="floatingInput"
                                    [value]="getUrlInvitation('AGENT_CHILD')" disabled>
                                <label for="floatingInput">招待リンク</label>
                                <button class="btn btn-link btn-sm btn-floating" type="button"
                                    (click)="copy(getUrlInvitation('AGENT_CHILD'))">
                                    <i class="fa-solid fa-copy"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            }

            @if(account?.role === 'CREATOR') {
            <section>
                <div class="card card-body border-0 bg-perl-1">
                    <div class="row gy-2">
                        <div>
                            <h5>ギフコメプロフィールを共有</h5>
                            <p>あなたのギフコメプロフィール経由でギフコメが贈られると、あなたの売上になります。</p>
                        </div>

                        <div>
                            <a class="btn btn-link" role="button" [routerLink]="['/', 'p', user?.uid]">
                                プロフィールを開く
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            }

            @if(account?.role === 'CREATOR' && isBotAvailable) {
            <section>
                <div class="card card-body">
                    <div class="row gy-2">
                        <section>
                            <h5>✨ 自動化</h5>
                        </section>

                        <section>
                            <div>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" role="switch"
                                        id="flexSwitchCheckDefault" [checked]="isBotMode" (change)="toggleBotMode()">
                                    <label class="form-check-label" for="flexSwitchCheckDefault">AIモード</label>
                                </div>
                            </div>
                        </section>

                        @if(isBotMode) {
                        <section>
                            <div class="row gy-1">
                                <section>
                                    <div>
                                        <label for="uidParent" class="form-label">タイマー</label>
                                        <div class="row align-items-center">
                                            <div class="col-3">
                                                <input type="number" class="form-control" #numTimmer>
                                            </div>
                                            時間後にAIモードを自動でオフ
                                        </div>
                                    </div>
                                </section>

                                <section>
                                    <div class="d-flex align-items-center justify-content-start">
                                        <div>
                                            <button class="btn btn-main" type="button"
                                                (click)="setTimmer(+numTimmer.value)">
                                                保存
                                            </button>
                                        </div>

                                        @if(expiredAt) {
                                        <div class="ps-50">
                                            現在{{ expiredAt | date: 'MM月dd日 HH時mm分' }}にオフ予定
                                        </div>
                                        }
                                    </div>
                                </section>

                                @if(expiredAt) {
                                <section>
                                    <small>
                                        タイマーを削除するには、一度AIモードをオフにします。
                                    </small>
                                </section>
                                }
                            </div>
                        </section>
                        }

                        <!-- 返信頻度の設定もいれたい -->

                        @if(isBotMode) {
                        <section>
                            <div class="row gy-1">
                                <section>
                                    <div>
                                        <label for="exampleFormControlTextarea1" class="form-label">キャラクターの設定</label>
                                        <textarea class="form-control" rows="7" [(ngModel)]="settingsChara"></textarea>
                                    </div>
                                </section>

                                <section>
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-main" type="button" (click)="save()">
                                            保存
                                        </button>
                                    </div>
                                </section>
                            </div>
                        </section>
                        }
                    </div>
                </div>
            </section>
            }

        </div>
    </div>
</section>
}