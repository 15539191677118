import { inject } from '@angular/core';
import { Auth, User, authState, onAuthStateChanged } from '@angular/fire/auth';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account } from '../../interfaces/account';

export const homeGuard: CanActivateFn = (route, state) => {
  const auth = inject(Auth);
  const managerAccount = inject(ManagerAccountService);
  const router = inject(Router);

  const authState$ = authState(auth).pipe(map((user: User | null) => {
    if (user === null) {
      if (window.location.href.indexOf('gifcome.com') >= 0) {
        window.location.href = 'https://gifcome.jp';
      }
      return false;
    }

    managerAccount.getAccount(user.uid).then((account: Account | undefined) => {
      if (account === undefined) return true;

      switch (account.role) {
        case 'ADMIN':
          router.navigate(['/', 'revenue']);
          break;
        case 'AGENT_PARENT':
          router.navigate(['/', 'revenue']);
          break;
        case 'AGENT_CHILD':
          router.navigate(['/', 'revenue']);
          break;
        case 'CREATOR':
          router.navigate(['/', 'dashboard-creator']);
          break;
        case 'USER':
          router.navigate(['/', 'dashboard-user']);
          break;
      }

      return false;
    });

    return false;
  }));

  return authState$;
};
