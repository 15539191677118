import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class ManagerStripeCustomerService {

  getUrlStripeCustomerPortal(email: string): string {
    return `https://billing.stripe.com/p/login/${ENVIRONMENT.PARAMS.STRIPE.CLIENT_ID}?prefilled_email=${email}`;
  }

  redirectToStripeCustomerPortal(email: string): void {
    const url = this.getUrlStripeCustomerPortal(email);
    window.open(url, '_blank');
  }

}
