<div class="card card-body border-0 bg-perl-2">
    <div class="row gy-1">
        <section>
            <div class="d-flex align-items-center">
                <img src="/assets/images/icons/banner/happiness.svg" class="icon-prefix"
                    style="height: 3.0rem">

                <small>
                    <a [routerLink]="['/', 'p', user?.uid]">あなたのプロフィール</a>を共有してギフコメ･ギフチャをもらいましょう！
                </small>
            </div>
        </section>

        <section>
            <div class="d-flex gap-1">
                <button class="btn btn-main btn-sm rounded-pill" type="button" (click)="copyUrlCatalog()">
                    URLをコピー
                </button>

                <a [routerLink]="['/', 'p', user?.uid]" class="btn btn-outline-main btn-sm rounded-pill" type="button">
                    プロフィールを表示
                </a>
            </div>
        </section>

        <section>
            <div class="d-flex gap-1">
                <button class="btn btn-sub btn-sm rounded-pill" type="button" (click)="copyUrlId()">
                    拡張機能用のIDをコピー
                </button>
            </div>
        </section>
    </div>
</div>