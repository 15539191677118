import { Injectable } from '@angular/core';
import { Dictionary } from '../../../classes/dictionary/dictionary';
import { v4 as uuid4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class StateProcessingService {

  /********************************
   * Properties
   *******************************/

  private dictProcess: Dictionary<Process> = new Dictionary<Process>();

  /*** Status ***/

  get isProcessing(): boolean {
    return this.dictProcess.keys().length > 0;
  }

  /********************************
   * Lifecyle hooks
   *******************************/

  constructor() { }

  /********************************
   * Methods
   *******************************/

  start(): string {
    const pid = uuid4();
    this.dictProcess.set(pid, new Process(pid));

    return pid;
  }


  end(pid: string): void {
    if (this.dictProcess.has(pid)) {
      this.dictProcess.delete(pid);
    }
  }

}

class Process {
  private _pid: string;
  get pid(): string {
    return this._pid;
  }

  constructor(pid: string) {
    this._pid = pid;
  }
}
