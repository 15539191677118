import { Component } from '@angular/core';

@Component({
  selector: 'app-button-close-modal',
  standalone: true,
  imports: [],
  templateUrl: './button-close-modal.component.html',
  styleUrl: './button-close-modal.component.scss'
})
export class ButtonCloseModalComponent {

}
