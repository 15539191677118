import { Component } from '@angular/core';
import { Account } from '../../interfaces/account';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { UtilClipboardService } from '../../services/utils/util-clipboard/util-clipboard.service';


@Component({
  selector: 'app-api',
  standalone: true,
  imports: [],
  templateUrl: './api.component.html',
  styleUrl: './api.component.scss'
})
export class ApiComponent {

  /******************************
   * Properties
   ******************************/

  get account(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  /******************************
   * Lifecycle hooks
   ******************************/

  constructor(
    private managerAccount: ManagerAccountService,
    private utilClipboard: UtilClipboardService
  ) { }

  /******************************
   * Methods
   ******************************/

  copy(text: string): void {
    this.utilClipboard.copy(text);
  }

  resetKeyApi(): void {
    this.managerAccount.regenKeyAPi();
  }

}
