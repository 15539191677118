import { CurrencyPipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ManagerStripeConnectedService } from '../../services/managers/manager-stripe-connected/manager-stripe-connected.service';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { CountUpModule } from 'ngx-countup';

@Component({
  selector: 'app-widget-revenue',
  standalone: true,
  imports: [
    CurrencyPipe,
    CountUpModule
  ],
  templateUrl: './widget-revenue.component.html',
  styleUrl: './widget-revenue.component.scss'
})
export class WidgetRevenueComponent {

  @Input() balance?: number;

  constructor(
    private managerAccount: ManagerAccountService,
    private managerStripeConnected: ManagerStripeConnectedService
  ) { }

  redirectToDashboard(): void {
    this.managerStripeConnected.redirectToDashboard(this.managerAccount.accountMine!.idStripeConnected!);
  }

}
