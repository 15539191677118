@if(gifcomesRecievedLatest.length === 0) {

<div>
    <p>0件</p>
</div>

}
@else {

<div class="row gy-50">
    @for(gifcome of gifcomesRecievedLatest; track $index) {
    <section>
        <div class="card card-body py-1 border-0 bg-perl-1">
            <div class="row gy-50">
                <div class="d-flex justify-content-between">
                    {{ gifcome.nameFrom }}

                    <!-- <p>{{ convert(gifcome.generatedAt) | date: 'HH:mm' }}</p> -->
                </div>

                <div>
                    {{ gifcome.comment }}

                    <!-- @if(!checkJapanese(gifcome.comment)) {
                    @if(gifcome.commentTranslated) {
                    <p>
                        <small>翻訳: {{ gifcome.commentTranslated }}</small>
                    </p>
                    }
                    @else {
                    <br>
                    <button class="btn btn-link btn-sm" style="padding: 0" type="button"
                        (click)="translate(gifcome)">
                        翻訳
                    </button>
                    }
                    } -->
                </div>
            </div>
        </div>
    </section>
    }
</div>

}