import { Injectable } from '@angular/core';
import { Firestore, addDoc, collection, collectionData, doc, docData, query, setDoc, where } from '@angular/fire/firestore';
import { ManagerUserService } from '../manager-user/manager-user.service';
import { UtilTimestampService } from '../../utils/util-timestamp/util-timestamp.service';
import { DocumentReference } from '@google-cloud/firestore';
import { UtilNotificationService } from '../../utils/util-notification/util-notification.service';
import { Observable, firstValueFrom } from 'rxjs';
import { ENVIRONMENT } from 'settings/environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { Gifcome } from '../../../interfaces/gifcome';

@Injectable({
  providedIn: 'root'
})
export class ManagerReportService {

  constructor(
    private http: HttpClient,
    private db: Firestore,

    private managerUser: ManagerUserService,

    private utilTimestamp: UtilTimestampService,
    private utilNotification: UtilNotificationService
  ) { }

  getReports$(): Observable<any[]> {
    const ref = collection(this.db, 'report');
    const q = query(ref, where('isOpen', '==', true));
    const data$ = collectionData(q, { idField: 'id' }) as Observable<any[]>;

    return data$;
  }

  createReport(uidTarget: string, text: string, idsGifcome?: string[]): Promise<any> {
    const report = {
      generatedAt: this.utilTimestamp.generateTimestamp(),
      generatedBy: this.managerUser.user!.uid,
      uidTarget: uidTarget,
      text: text,
      isOpen: true
    } as any;
    if (idsGifcome !== undefined && idsGifcome.length > 0) {
      report.idsGifcome = idsGifcome;
    }

    const ref = collection(this.db, 'report');
    const response = addDoc(ref, report);

    return response.then(() => {
      this.utilNotification.notify('レポートが送信されました。', 'SUCCESS');
    });
  }

  closeReport(id: string): Promise<void> {
    const ref = doc(this.db, 'report', id);
    const docRef = setDoc(ref, { isOpen: false }, { merge: true });

    return docRef.then(() => {
      this.utilNotification.notify('通報をクローズしました。', 'SUCCESS');
    });
  }

  getEmail(uid: string): Promise<any> {
    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/getEmail';
    const params = { uid: uid };
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$) as Promise<any>;

    return res;
  }

  getGifcome(idGifcome: string): Observable<Gifcome> {
    const ref = doc(this.db, 'gifcome', idGifcome);
    const data$ = docData(ref) as Observable<Gifcome>;

    return data$;
  }
}
