import { Injectable } from '@angular/core';
import { firstValueFrom, map } from 'rxjs';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';
import { Registration } from '../../../interfaces/registration';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { HttpClient } from '@angular/common/http';
import { ManagerAccountService } from '../../managers/manager-account/manager-account.service';
import { StateProcessingService } from '../../states/state-processing/state-processing.service';
import { Account } from '../../../interfaces/account';
import { CONSTANT } from 'settings/constant';

@Injectable({
  providedIn: 'root'
})
export class ManagerStripeConnectedService {

  /******************************
   * Properties
   ******************************/

  private _accountStripeConnected?: any;
  get accountStripeConnected(): any {
    if (!this._accountStripeConnected && this._accountStripeConnected !== 'LOADING' && this.managerAccount.accountMine?.idStripeConnected) {
      this._accountStripeConnected = 'LOADING';
      this.getStripeConnected(this.managerAccount.accountMine.idStripeConnected);
      console.log('accountStripeConnected', this._accountStripeConnected);
    }

    return this._accountStripeConnected;
  }

  private _balance?: any | string;
  get balance(): number | string | undefined {
    if (!this._balance && this._balance !== 'LOADING' && this.managerAccount.accountMine) {
      this._balance = 'LOADING';
      this.readBalanceStripeConnected();
    }

    if (!this._balance) return 0;
    const available = this._balance.available ? this._balance.available[0].amount : 0;
    const pending = this._balance.pending ? this._balance.pending[0].amount : 0;
    return available + pending;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private http: HttpClient,

    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private stateProcessing: StateProcessingService
  ) { }

  /******************************
   * Methods
   ******************************/

  createStripeConnected(registration: Registration, account: Account): Promise<any> {
    const pid = this.stateProcessing.start();

    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/createAccountStripeConnected';
    const params = Object.assign({}, registration) as any;
    params.building = params.building ? params.building : '-';

    params.uid = this.managerUser.user!.uid;
    params.email = this.managerUser.user!.email;
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$);
    res.then(() => {
      this.managerAccount.updateAccount(account).finally(() => {
        this.stateProcessing.end(pid);
      });
    });

    return res;
  }

  getStripeConnected(idStripeConnected: string): Promise<any> {
    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/readAccountStripeConnected';
    const params = {
      idStripeConnected: idStripeConnected
    };
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$);
    res.then((res: any) => {
      // console.log(res.accountStripeConnected);
      this._accountStripeConnected = res.accountStripeConnected;
    }).catch(() => { });

    return res;
  }

  readBalanceStripeConnected(): Promise<any> {
    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/readBalanceStripeConnected';
    const params = {
      idStripeConnected: this.managerAccount.accountMine!.idStripeConnected
    };
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$);
    res.then((res: any) => {
      this._balance = res.balance;
    }).catch(() => { });

    return res;
  }

  getBalanceStripeConnected(): Promise<number> {
    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/readBalanceStripeConnected';
    const params = {
      idStripeConnected: this.managerAccount.accountMine!.idStripeConnected
    };
    const response$ = this.http.post(url, JSON.stringify(params));
    const response = firstValueFrom(response$.pipe(
      map((res: any) => this.toBalance(res.balance))
    )) as Promise<number>;

    return response;
  }

  toBalance(balance: any): number {
    if (!balance) return 0;

    const available = balance.available ? balance.available[0].amount : 0;
    const pending = balance.pending ? balance.pending[0].amount : 0;

    return available + pending;
  }

  resetBalance(): void {
    this._balance = undefined;
  }

  redirectToSettings(idStripeConnected: string): Promise<any> {
    const pid = this.stateProcessing.start();

    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/getUrlSettingsAccountStripeConnected';
    const params = {
      idStripeConnected: idStripeConnected
    };
    console.log(params);
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$);
    res.then((res: any) => {
      //window.open(res.url, '_blank');
      location.href = res.url;
    }).catch((error: any) => {
      console.log('error', error);
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return res;
  }

  // Generate OAuth url, and redirect to Stripe Express
  redirectToDashboard(idStripeConnected: string): Promise<any> {
    const pid = this.stateProcessing.start();

    if (CONSTANT.STRIPE.ACCOUNTS_STANDARD.indexOf(idStripeConnected) >= 0) {
      location.href = 'https://dashboard.stripe.com/account';
      this.stateProcessing.end(pid);
      return Promise.resolve();
    }

    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/getUrlDashboardAccountStripeExpress';
    const params = {
      idStripeConnected: idStripeConnected
    };
    console.log(params);
    const res$ = this.http.post(url, JSON.stringify(params));
    const res = firstValueFrom(res$);
    res.then((res: any) => {
      //window.open(res.url, '_blank');
      location.href = res.url;
    }).catch(() => {
      location.href = 'https://dashboard.stripe.com/dashboard';
    }).finally(() => {
      this.stateProcessing.end(pid);
    });

    return res;
  }

  checkSubmitted(stripeConnected: any | undefined): boolean {
    if (stripeConnected === undefined || stripeConnected.requirements === undefined) return false;

    return stripeConnected.details_submitted;
  }

  checkDisabled(stripeConnected: any | undefined): boolean {
    if (stripeConnected === undefined || stripeConnected.requirements === undefined) return true;

    return stripeConnected.requirements.disabled_reason !== null;
  }

}