<div class="modal fade" id="modalPreviewQr" tabindex="-1" aria-labelledby="labelModalPreviewQr"
    aria-hidden="true">
    <div class="modal-dialog modal-fullscreen">
        <div class="modal-content">
            <div class="modal-header justify-content-end border-0 fixed-top">
                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body d-flex align-items-center justify-content-center" style="padding: 0">
                @if(url !== undefined) {
                <qrcode [qrdata]="url" [width]="256"></qrcode>
                }
            </div>
        </div>
    </div>
</div>