<div class="modal fade" id="modalAccountUpdate" tabindex="-1" aria-labelledby="labelModalAccountUpdate"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalAccountUpdate">アカウント情報の編集</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body">
                <form class="row gy-1" [formGroup]="formAccount">
                    <div class="col-12">
                        <label for="name" class="form-label">名前</label>
                        <input type="text" class="form-control" id="name" formControlName="name">
                    </div>

                    @if(account?.role !== 'USER') {
                    <div class="col-12">
                        <label for="announce" class="form-label">アナウンス</label>
                        <input type="text" class="form-control" id="announce" formControlName="announce">
                    </div>

                    <div class="col-12">
                        <label for="description" class="form-label">紹介文</label>
                        <textarea class="form-control" id="description" formControlName="description"></textarea>
                    </div>

                    <div class="col-12">
                        <label for="url" class="form-label">活動サイト</label>
                        <input type="url" class="form-control" id="url" formControlName="url" placeholder="https://...">
                    </div>

                    <div class="col-12">
                        <label for="icon" class="form-label">アイコン</label>
                        <select class="form-select" formControlName="icon">
                            @for(icon of icons; track $index) {
                            <option [value]="icon.src">{{ icon.label }}</option>
                            }
                        </select>
                    </div>
                    }

                    @if(account?.role === 'AGENT_PARENT' || account?.role === 'AGENT_CHILD') {
                    <div class="col-12">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch"
                                id="input-isCreatorAllowedToHideAgent"
                                formControlName="isCreatorAllowedToHideAgent">
                            <label class="form-check-label"
                                for="input-isCreatorAllowedToHideAgent">クリエイターにエージェント名の非表示を許可する</label>
                        </div>
                    </div>
                    }

                    @if(account?.role === 'CREATOR') {
                    <div class="col-12">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" id="input-isAgentHidden"
                                formControlName="isAgentHidden">
                            <label class="form-check-label"
                                for="input-isAgentHidden">エージェント名を非表示にする(エージェントが許可している場合)</label>
                        </div>
                    </div>
                    }
                </form>
            </div>

            <div class="modal-footer">
                <button class="btn btn-main rounded-pill" type="button" data-bs-dismiss="modal"
                    [disabled]="formAccount.invalid"
                    (click)="updateAccount()">保存</button>
            </div>
        </div>
    </div>
</div>