import { Injectable } from '@angular/core';
import { Auth, User, authState } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ManagerUserService {

  get user(): User | null {
    return this.auth.currentUser;
  }

  constructor(private auth: Auth) {
    const state$ = authState(this.auth);
    // state$.subscribe(user => {
    //   console.log('ManagerUserService.constructor()', user);
    // });
  }

}