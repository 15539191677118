<div class="modal fade" id="modalAccountCreate" tabindex="-1" aria-labelledby="labelModalAccountCreate"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalAccountCreate">ようこそギフコメへ！</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body">
                <form class="row gy-2" [formGroup]="formAccount">
                    <p>
                        まずは名前を設定しましょう！
                    </p>

                    <div class="col-12">
                        <label for="name" class="form-label">名前</label>
                        <input type="text" class="form-control" id="name" formControlName="name">
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button class="btn btn-main rounded-pill" type="button" data-bs-dismiss="modal"
                    [disabled]="formAccount.invalid"
                    (click)="createAccount()">保存</button>
            </div>
        </div>
    </div>
</div>