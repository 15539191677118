<div class="modal fade" id="modalRegistrationUpdate" tabindex="-1" aria-labelledby="labelModalRegistrationUpdate"
    aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header justify-content-between">
                <h1 class="modal-title fs-5" id="labelModalRegistrationUpdate">登録情報の編集</h1>

                <app-button-close-modal></app-button-close-modal>
            </div>

            <div class="modal-body">
                <form class="row gy-1" [formGroup]="formRegistration">
                    <div>
                        <label class="form-label" for="businessType">事業形態</label>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="businessType" value="company"
                                id="radioRegistrationBusinessTypeCompany" formControlName="businessType">
                            <label class="form-check-label" for="radioRegistrationBusinessTypeCompany">
                                法人
                            </label>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="businessType" value="individual"
                                id="radioRegistrationBusinessTypeIndividual" formControlName="businessType">
                            <label class="form-check-label" for="radioRegistrationBusinessTypeIndividual">
                                個人
                            </label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="nameCompany" formControlName="nameCompany"
                                [class]="getClassListValidation('nameCompany')">
                            <label for="nameCompany">商号または屋号</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="nameCompanyKana"
                                formControlName="nameCompanyKana" [class]="getClassListValidation('nameCompanyKana')">
                            <label for="nameCompanyKana">商号または屋号(ひらがな表記)</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="nameCompanyRome"
                                formControlName="nameCompanyRome" [class]="getClassListValidation('nameCompanyRome')">
                            <label for="nameCompanyRome">商号または屋号(ローマ字表記)</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="name" formControlName="name"
                                [class]="getClassListValidation('name')">
                            <label for="name">申請者の氏名</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="nameKana" formControlName="nameKana"
                                [class]="getClassListValidation('nameKana')">
                            <label for="nameKana">申請者の氏名(ひらがな表記)</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="text" class="form-control" id="nameRome" formControlName="nameRome"
                                [class]="getClassListValidation('nameRome')">
                            <label for="nameRome">申請者の氏名(ローマ字表記)</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="tel" class="form-control" id="tel" formControlName="tel"
                                [class]="getClassListValidation('tel')">
                            <label for="tel">電話番号</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="zip" class="form-control" id="zip" formControlName="zip"
                                [class]="getClassListValidation('zip')">
                            <label for="zip">郵便番号</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="address" class="form-control" id="state" formControlName="state"
                                [class]="getClassListValidation('state')">
                            <label for="state">都道府県</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="address" class="form-control" id="city" formControlName="city"
                                [class]="getClassListValidation('city')">
                            <label for="city">市区町村</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">
                            <input type="address" class="form-control" id="street" formControlName="street"
                                [class]="getClassListValidation('street')">
                            <label for="street">丁目・番地・号</label>
                        </div>
                    </div>

                    <div>
                        <div class="form-floating">

                            <input type="address" class="form-control" id="building" formControlName="building"
                                [class]="getClassListValidation('building')">
                            <label for="building">建物名・部屋番号</label>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer">
                <button class="btn btn-main rounded-pill" type="button" data-bs-dismiss="modal"
                    [disabled]="formRegistration.invalid"
                    (click)="updateRegistration()">保存</button>
            </div>
        </div>
    </div>
</div>