import { Injectable } from '@angular/core';
import { collection, collectionData, endBefore, limit, limitToLast, orderBy, query, startAt, where, CollectionReference, Firestore, getDocs } from '@angular/fire/firestore';
import { firstValueFrom, Observable } from 'rxjs';
import { ManagerGifcomeService } from '../../managers/manager-gifcome/manager-gifcome.service';
import { ManagerUserService } from '../../managers/manager-user/manager-user.service';
import { Gifcome } from '../../../interfaces/gifcome';
import { ManagerAccountService } from '../manager-account/manager-account.service';
import { Chat } from '../../../interfaces/chat';
import { getDoc } from '@firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ManagerChatService {

  /******************************
   * Properties
   ******************************/

  get idsChat(): string[] | undefined {
    const gifcomesPrivate = this.managerGifcome.gifcomesPrivate;
    if (gifcomesPrivate === undefined) return undefined;

    const idsChat = gifcomesPrivate.map(
      (gifcome: Gifcome) => gifcome.idChat
    ) as string[];

    return Array.from(new Set(idsChat));
  }

  get chats(): any[] | undefined {
    const idsChat = this.idsChat;
    const gifcomesPrivate = this.managerGifcome.gifcomesPrivate;
    if (gifcomesPrivate === undefined) return undefined;
    if (idsChat === undefined) return undefined;

    const chats = idsChat.map(idChat => {
      const gifcomes = gifcomesPrivate.filter(gifcome => gifcome.idChat === idChat);
      const chat = {
        id: idChat,
        gifcomes: gifcomes
      };

      return chat;
    });

    return chats;
  }

  get unreads(): Gifcome[] | undefined {
    if (!this.managerUser.user) return [];

    const gifcomesPrivate = this.managerGifcome.gifcomesPrivate;
    if (gifcomesPrivate === undefined) return undefined;

    const unreads = gifcomesPrivate.filter((gifcome: Gifcome) => {
      return gifcome.uidTo === this.managerUser.user?.uid && !gifcome.isRead;
    });

    return unreads;
  }

  constructor(
    private db: Firestore,

    private managerUser: ManagerUserService,
    private managerAccount: ManagerAccountService,
    private managerGifcome: ManagerGifcomeService
  ) { }

  getUidTarget(chat: any): string {
    const gifcome = chat.gifcomes[0];
    return (gifcome.uidTo === this.managerUser.user?.uid) ? gifcome.uidFrom : gifcome.uidTo;
  }

  getNameTarget(chat: any): string {
    const gifcome = chat.gifcomes[0];
    return (gifcome.uidTo === this.managerUser.user?.uid) ? gifcome.nameFrom : gifcome.nameTo;
  }

  async getUrlIcon(chat: any): Promise<string> {
    const uid = this.getUidTarget(chat);
    const account = await this.managerAccount.getAccount(uid);

    return account?.icon || '';
  }

  async getGifcomeOldest(idChat: string): Promise<Gifcome> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('isGifcomePrivate', '==', true),
      where('idChat', '==', idChat),
      orderBy('generatedAt', 'asc'),
      limit(1)
    );
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Gifcome[]>;

    return data.then((gifcomes: Gifcome[]) => gifcomes[0]);
  }

  async getGifcomeLatest(idChat: string): Promise<Gifcome> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('isGifcomePrivate', '==', true),
      where('idChat', '==', idChat),
      orderBy('generatedAt', 'desc'),
      limit(1)
    );
    const data$ = collectionData(q, { idField: 'id' });
    const data = firstValueFrom(data$) as Promise<Gifcome[]>;

    return data.then((gifcomes: Gifcome[]) => gifcomes[0]);
  }

  getGifcomesPrecurrent(idChat: string, generatedAt: string): Promise<Gifcome[]> {
    const ref = collection(this.db, 'gifcome') as CollectionReference<Gifcome>;
    const q = query(ref,
      where('isGifcomePrivate', '==', true),
      where('idChat', '==', idChat),
      orderBy('generatedAt', 'asc'),
      endBefore(generatedAt),
      limitToLast(10)
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;
    const data = firstValueFrom(data$) as Promise<Gifcome[]>;

    data.then((gifcomes: Gifcome[]) => {
      for (let gifcome of gifcomes) {
        if (!this.managerGifcome.isMine(gifcome) && !gifcome.isRead) {
          this.managerGifcome.updateGifcome(gifcome.id, { isRead: true } as Gifcome);
        }
      }
    });

    return data;
  }

  getGifcomesPostcurrent(idChat: string, generatedAt: string): Observable<Gifcome[]> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('isGifcomePrivate', '==', true),
      where('idChat', '==', idChat),
      orderBy('generatedAt', 'asc'),
      startAt(generatedAt)
    );
    const data$ = collectionData(q, { idField: 'id' }) as Observable<Gifcome[]>;//本当はonSnapshotで書きたい。今は全データを取得しちゃってる。

    return data$;
  }


  async getChatsUnread(): Promise<Chat[]> {
    const ref = collection(this.db, 'gifcome');
    const q = query(ref,
      where('isGifcomePrivate', '==', true),
      where('uidTo', '==', this.managerUser.user?.uid),
      where('isRead', '==', false)
    );
    const snapshot = await getDocs(q);
    const gifcomes = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Gifcome[];

    const idsChat = gifcomes.map(gifcome => gifcome.idChat);
    const idsChatUnique = Array.from(new Set(idsChat));

    const chats = idsChatUnique.map(idChat => {
      const gifcomesChat = gifcomes.filter(gifcome => gifcome.idChat === idChat);
      return {
        id: idChat,
        gifcomes: gifcomesChat
      } as Chat;
    });

    return chats;
  }

}
