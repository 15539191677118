import { Component, OnInit } from '@angular/core';
import { WidgetRevenueComponent } from "../../components/widget-revenue/widget-revenue.component";
import { ManagerStripeConnectedService } from '../../services/managers/manager-stripe-connected/manager-stripe-connected.service';
import { Account } from '../../interfaces/account';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { WidgetGifcomeListLatestComponent } from "../../components/widget-gifcome-list-latest/widget-gifcome-list-latest.component";
import { WidgetGifchaListUnreadComponent } from "../../components/widget-gifcha-list-unread/widget-gifcha-list-unread.component";
import { RouterLink } from '@angular/router';
import { BannerCatalogComponent } from "../../components/banners/banner-catalog/banner-catalog.component";

@Component({
  selector: 'app-dashboard-creator',
  standalone: true,
  templateUrl: './dashboard-creator.component.html',
  styleUrl: './dashboard-creator.component.scss',
  imports: [
    RouterLink,
    WidgetRevenueComponent,
    WidgetGifcomeListLatestComponent,
    WidgetGifchaListUnreadComponent,
    BannerCatalogComponent
  ]
})
export class DashboardCreatorComponent implements OnInit {

  get myAccount(): Account | undefined {
    return this.mgrAccount.accountMine;
  }

  private _balance?: number;
  get balance(): number | undefined {
    return this._balance;
  }

  constructor(
    private mgrAccount: ManagerAccountService,
    private mgrSripeConnected: ManagerStripeConnectedService
  ) { }

  ngOnInit(): void {
    const interval = setInterval(async () => {
      if (this.myAccount === undefined) return;
      clearInterval(interval);

      this._balance = await this.mgrSripeConnected.getBalanceStripeConnected();
    }, 0.1 * 1000);
  }

}
