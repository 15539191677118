import { AfterViewInit, Component, Input } from '@angular/core';
import { UtilTimestampService } from '../../services/utils/util-timestamp/util-timestamp.service';

declare const bootstrap: any;

@Component({
  selector: 'app-tooltip-question',
  standalone: true,
  imports: [],
  templateUrl: './tooltip-question.component.html',
  styleUrl: './tooltip-question.component.scss'
})
export class TooltipQuestionComponent implements AfterViewInit {

  @Input() text: string = '';
  tooltipId: string = '';

  constructor(
    private utilTimestamp: UtilTimestampService
  ) { }

  ngOnInit(): void {
    this.tooltipId = this.utilTimestamp.generateTimestamp();
  }
  ngAfterViewInit(): void {
    const tooltip = document.getElementById(this.tooltipId);
    new bootstrap.Tooltip(tooltip);
  }

}
