import { Component, ElementRef, OnInit } from '@angular/core';
import { ButtonCloseModalComponent } from "../../components/button-close-modal/button-close-modal.component";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';

@Component({
  selector: 'app-modal-account-create',
  standalone: true,
  templateUrl: './modal-account-create.component.html',
  styleUrl: './modal-account-create.component.scss',
  imports: [
    ButtonCloseModalComponent,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ModalAccountCreateComponent implements OnInit {

  /******************************
   * Properties
   ******************************/

  /*** Forms ***/

  private _formAccount: FormGroup
  get formAccount(): FormGroup {
    return this._formAccount;
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private fb: FormBuilder,
    private elementRef: ElementRef,
    private managerAccount: ManagerAccountService
  ) {
    this._formAccount = this.fb.group({
      name: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    const modal = this.elementRef.nativeElement.querySelector('#modalAccountCreate');
    if (modal === null) return;

    modal.addEventListener('hidden.bs.modal', () => {
      this.formAccount.reset();
    });
  }

  /******************************
   * Methods
   ******************************/

  createAccount(): void {
    this.managerAccount.updateAccount(this.formAccount.value);
  }

}
