import { Routes } from '@angular/router';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { CatalogComponent } from './pages/catalog/catalog.component';
import { FollowingListComponent } from './pages/following-list/following-list.component';
import { ChatListComponent } from './pages/chat-list/chat-list.component';
import { ChatComponent } from './pages/chat/chat.component';
import { GifcomeListComponent } from './pages/gifcome-list/gifcome-list.component';
import { WalletComponent } from './pages/wallet/wallet.component';
import { ChargeListComponent } from './pages/charge-list/charge-list.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RevenueComponent } from './pages/revenue/revenue.component';
import { ApiComponent } from './pages/api/api.component';
import { DashboardAdminComponent } from './pages/dashboard-admin/dashboard-admin.component';
import { GainRoleAdminComponent } from './pages/gain-role-admin/gain-role-admin.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { authGuard } from './guards/auth/auth.guard';
import { homeGuard } from './guards/home/home.guard';
import { DashboardAgentComponent } from './pages/dashboard-agent/dashboard-agent.component';
import { DashboardCreatorComponent } from './pages/dashboard-creator/dashboard-creator.component';
import { DashboardUserComponent } from './pages/dashboard-user/dashboard-user.component';
import { TermsPpComponent } from './pages/terms-pp/terms-pp.component';
import { LoginComponent } from './pages/login/login.component';
import { loginGuard } from './guards/login/login.guard';
import { SettingsComponent } from './pages/settings/settings.component';

export const routes: Routes = [
    { path: '', component: GetStartedComponent, canActivate: [homeGuard] }, //ログインしてたらダッシュボード、してなかったらLP(.jp)にリダイレクト
    { path: 'login', component: LoginComponent, canActivate: [loginGuard] },
    { path: 'catalog/:uid', redirectTo: 'p/:uid', pathMatch: 'full' },
    { path: 'p/:uid', component: CatalogComponent },
    { path: 'terms-pp', component: TermsPpComponent },

    { path: 'followings', component: FollowingListComponent, canActivate: [authGuard] },
    { path: 'chats', component: ChatListComponent, canActivate: [authGuard] },
    { path: 'chat/:idChat', component: ChatComponent, canActivate: [authGuard] },
    { path: 'gifcomes', component: GifcomeListComponent, canActivate: [authGuard] },
    { path: 'wallet', component: WalletComponent, canActivate: [authGuard] },
    { path: 'charges', component: ChargeListComponent, canActivate: [authGuard] },
    { path: 'profile', component: ProfileComponent, canActivate: [authGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [authGuard] },

    { path: 'revenue', component: RevenueComponent, canActivate: [authGuard] },
    { path: 'api', component: ApiComponent, canActivate: [authGuard] },

    { path: 'dashboard-admin', component: DashboardAdminComponent, canActivate: [authGuard] },
    { path: 'dashboard-agent', component: DashboardAgentComponent, canActivate: [authGuard] },
    { path: 'dashboard-creator', component: DashboardCreatorComponent, canActivate: [authGuard] },
    { path: 'dashboard-user', component: DashboardUserComponent, canActivate: [authGuard] },

    { path: 'onboarding', component: GainRoleAdminComponent },

    { path: '**', component: PageNotFoundComponent }
];
