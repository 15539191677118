import { Component } from '@angular/core';
import { ModalRegistrationUpdateComponent } from "../../modals/modal-registration-update/modal-registration-update.component";
import { ManagerRegistrationService } from '../../services/managers/manager-registration/manager-registration.service';
import { Registration } from '../../interfaces/registration';
import { ManagerAccountService } from '../../services/managers/manager-account/manager-account.service';
import { Account, AccountRole } from '../../interfaces/account';
import { ManagerStripeConnectedService } from '../../services/managers/manager-stripe-connected/manager-stripe-connected.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ManagerAuthService } from '../../services/managers/manager-auth/manager-auth.service';
import { StateProcessingService } from '../../services/states/state-processing/state-processing.service';
import { SpinnerComponent } from "../../components/spinner/spinner.component";

@Component({
  selector: 'app-gain-role-admin',
  standalone: true,
  templateUrl: './gain-role-admin.component.html',
  styleUrl: './gain-role-admin.component.scss',
  imports: [ModalRegistrationUpdateComponent, RouterLink, SpinnerComponent]
})
export class GainRoleAdminComponent {

  /******************************
   * Properties
   ******************************/

  private _uid?: string;
  get uid(): string | undefined {
    return this._uid;
  }

  private _role?: AccountRole;
  get role(): AccountRole | undefined {
    return this._role;
  }

  /*** Data ***/

  get account(): Account | undefined {
    return this.managerAccount.accountMine;
  }

  private _accountInviter?: Account;
  get accountInviter(): Account | undefined {
    return this._accountInviter;
  }

  get accountStripeConnected(): any {
    return this.managerStripeConnected.accountStripeConnected;
  }

  get registration(): Registration | undefined {
    return this.modelRegistration.registration;
  }

  /*** Status ***/

  get isAuthenticated(): boolean {
    return this.managerAuth.isAuthenticated;
  }

  get isProcessing(): boolean {
    return this.stateProcessing.isProcessing;
  }

  get isStripeConnectedSubmitted(): boolean {
    return this.managerStripeConnected.checkSubmitted(this.accountStripeConnected);
  }

  /******************************
   * Lifecyle hooks
   ******************************/

  constructor(
    private route: ActivatedRoute,
    private managerAuth: ManagerAuthService,
    private managerAccount: ManagerAccountService,
    private modelRegistration: ManagerRegistrationService,
    private managerStripeConnected: ManagerStripeConnectedService,
    private stateProcessing: StateProcessingService
  ) { }

  ngOnInit(): void {
    const uid = this.route.snapshot.queryParamMap.get('src');
    let role = this.route.snapshot.queryParamMap.get('tgt') as AccountRole;
    if (!uid || !role) return;

    this._uid = uid;
    this._role = role;

    this.managerAccount.getAccount(uid).then((account: Account | undefined) => {
      this._accountInviter = account;
    });
  }

  /******************************
   * Methods
   ******************************/

  createAccountStripeConnected(): void {
    this.managerStripeConnected.createStripeConnected(this.registration!, {
      role: this.role,

      uidParent: this.uid
    } as Account);
  }

  redirectToSettingsAccountStripeConnected(): void {
    this.managerStripeConnected.redirectToSettings(this.account!.idStripeConnected!);
  }

  signIn(): void {
    this.managerAuth.signIn();
  }

}
