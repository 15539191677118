import { Injectable } from '@angular/core';
import { ENVIRONMENT } from '../../../../../../../settings/environments/environment.development';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Gifcome } from '../../../interfaces/gifcome';

@Injectable({
  providedIn: 'root'
})
export class UtilTranslationService {

  constructor(
    private http: HttpClient
  ) { }

  async translateGifcome(gifcome: Gifcome): Promise<any> {
    const url = ENVIRONMENT.PARAMS.FUNCTIONS.URL_BASE + '/translateText';
    const data = {
      text: gifcome.comment
    };

    const response$ = await this.http.post(url, JSON.stringify(data));
    const response = firstValueFrom(response$);
    response.then((res: any) => {
      gifcome.commentTranslated = res.translatedText;
    });

    return response;
  }

  checkJapanese(text: string): boolean {
    const japaneseRegex = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/g;


    const matches = text.match(japaneseRegex);
    const countJapanese = matches ? matches.length : 0;

    return (countJapanese / text.length) > 0.7;
  }
}
