<section class="py-3">
    <div class="container">
        <div class="content row gy-2">

            <div>
                <div class="row gy-2">
                    <div>
                        <h3>APIとは</h3>
                        <p>APIはギフコメと外部サイトとを連携する仕組みです。ギフコメを導入している外部サイトにAPIキーを設定すると、あなたのギフコメプロフィールやリンクを外部サイトに自動で掲載できます。
                        </p>
                    </div>

                    <div>
                        <div class="d-flex justify-content-center px-2">
                            <img src="/assets/images/guides/guide-api.png">
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>APIキー</h5>
                            <p>このAPIキーはあなた専用です。他人に知られないよう注意してください。</p>
                        </div>

                        @if(account) {
                        <div>
                            <div class="form-floating">
                                <input type="text" class="form-control border-0" id="floatingInput"
                                    [value]="account.keyApi" disabled>
                                <label for="floatingInput">APIキー</label>
                                <button class="btn btn-link btn-sm btn-floating" type="button"
                                    (click)="copy(account.keyApi)">
                                    <i class="fa-solid fa-copy"></i>
                                </button>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div>
                <div class="card card-body">
                    <div class="row gy-2">
                        <div>
                            <h5>APIキーの再発行</h5>
                            <p>もしもAPIキーが悪用されていると感じたら、APIキーをリセットするために下記のボタンをクリックします。その後、外部サイトに再度APIキーを設定します。</p>
                        </div>

                        <div>
                            <button class="btn btn-alert rounded-pill" type="button" (click)="resetKeyApi()">
                                <i class="fa-solid fa-arrows-rotate"></i>
                                APIキーをリセット
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>